import numeral from 'numeral';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';

const HeaderCell = ({ children }) => (
  <th className="mb-5 font-light text-left text-slate-900">{children}</th>
);

const ValueCell = ({ children }) => (
  <td className="font-bold text-slate-700">{children}</td>
);

const TableRow = ({ children }) => <tr className="h-16 shadow">{children}</tr>;

type Props = {
  totalDrugsCostsYear0: number;
  totalOMACostYear0: number;
  totalMEPOCostYear0: number;
  totalOMACostYear1: number;
  totalMEPOCostYear1: number;
  budgetAmountResult: number;
  totalDrugsCostsYear1: number;
  unexpectedEventCostsYear0: number;
  unexpectedEventCostsYear1: number;
  complianceYear0: number;
  complianceDelta: number;
  complianceYear1: number;
  numberOfPatientsTreatedYear0: number;
  numberOfPatientsTreatedYear1: number;
  percentageOfPatientsTreatedWithBioYear0: number;
  percentageOfPatientsTreatedWithBioYear1: number;
};

const NextYearDebriefTable = ({
  totalDrugsCostsYear0,
  totalOMACostYear0,
  totalMEPOCostYear0,
  totalOMACostYear1,
  totalMEPOCostYear1,
  budgetAmountResult,
  totalDrugsCostsYear1,
  unexpectedEventCostsYear0,
  unexpectedEventCostsYear1,
  complianceYear0,
  complianceDelta,
  complianceYear1,
  numberOfPatientsTreatedYear0,
  numberOfPatientsTreatedYear1,
  percentageOfPatientsTreatedWithBioYear0,
  percentageOfPatientsTreatedWithBioYear1,
}: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <motion.table
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="w-full p-8 overflow-hidden text-lg bg-white border-8 border-white rounded-lg ring-2 ring-slate-200"
    >
      <thead>
        <tr
          style={{
            boxShadow: `0px 2px 0px 0px rgba(0, 0, 0, 1)`,
            height: '64px',
          }}
        >
          <th />
          <HeaderCell>Risorse dell'anno precedente</HeaderCell>
          <HeaderCell>Variazione decisa dal team</HeaderCell>
          <HeaderCell>Risorse per l'anno prossimo</HeaderCell>
        </tr>
      </thead>

      <tbody>
        <TableRow>
          <td>{f({ id: 'vars:spesa-totale-farmaci.label' })}</td>
          <ValueCell>{numeral(totalDrugsCostsYear0).format('$ 0,0')}</ValueCell>
          <ValueCell>{numeral(budgetAmountResult).format('$ 0,0')} </ValueCell>
          <ValueCell>{numeral(totalDrugsCostsYear1).format('$ 0,0')}</ValueCell>
        </TableRow>

        <TableRow>
          <td className="indent-8 text-sm">
            {f({ id: 'vars:di-cui-oma.label' })}
          </td>
          <ValueCell>{numeral(totalOMACostYear0).format('$ 0,0')}</ValueCell>
          <ValueCell>
            {numeral(totalOMACostYear1 - totalOMACostYear0).format('$ 0,0')}{' '}
          </ValueCell>
          <ValueCell>{numeral(totalOMACostYear1).format('$ 0,0')}</ValueCell>
        </TableRow>

        <TableRow>
          <td className="indent-8 text-sm">
            {f({ id: 'vars:di-cui-mepo.label' })}
          </td>
          <ValueCell>{numeral(totalMEPOCostYear0).format('$ 0,0')}</ValueCell>
          <ValueCell>
            {numeral(totalMEPOCostYear1 - totalMEPOCostYear0).format('$ 0,0')}{' '}
          </ValueCell>
          <ValueCell>{numeral(totalMEPOCostYear1).format('$ 0,0')}</ValueCell>
        </TableRow>

        <TableRow>
          <td>{f({ id: 'vars:spesa-totale-eventi-avversi.label' })}</td>
          <ValueCell>
            {numeral(unexpectedEventCostsYear0).format('$ 0,0')}
          </ValueCell>
          <ValueCell>
            {numeral(
              unexpectedEventCostsYear1 - unexpectedEventCostsYear0
            ).format('$ 0,0')}
          </ValueCell>
          <ValueCell>
            {numeral(unexpectedEventCostsYear1).format('$ 0,0')}
          </ValueCell>
        </TableRow>

        <TableRow>
          <td>{f({ id: 'vars:compliance-media.label' })}</td>
          <ValueCell>{numeral(complianceYear0).format('0.00 %')}</ValueCell>
          <ValueCell>{numeral(complianceDelta).format('0.00 %')} </ValueCell>
          <ValueCell>{numeral(complianceYear1).format('0.00 %')}</ValueCell>
        </TableRow>

        <TableRow>
          <td>{f({ id: 'vars:treated-patients.label' })}</td>
          <ValueCell>
            {numeral(numberOfPatientsTreatedYear0).format('0,0')}
          </ValueCell>
          <ValueCell>
            {numeral(
              numberOfPatientsTreatedYear1 - numberOfPatientsTreatedYear0
            ).format('0,0')}
          </ValueCell>
          <ValueCell>
            {numeral(numberOfPatientsTreatedYear1).format('0,0')}
          </ValueCell>
        </TableRow>

        <tr style={{ height: '64px' }}>
          <td>{f({ id: 'vars:treated-patients-biological.label' })}</td>
          <ValueCell>
            {numeral(percentageOfPatientsTreatedWithBioYear0).format('0.00 %')}
          </ValueCell>
          <ValueCell>
            {numeral(
              percentageOfPatientsTreatedWithBioYear1 -
                percentageOfPatientsTreatedWithBioYear0
            ).format('0.00 %')}
          </ValueCell>
          <ValueCell>
            {numeral(percentageOfPatientsTreatedWithBioYear1).format('0.00 %')}
          </ValueCell>
        </tr>
      </tbody>
    </motion.table>
  );
};

export default NextYearDebriefTable;
