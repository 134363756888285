import numeral from 'numeral';
import { IReportData } from '../pdf-report/interfaces';
import PlayerRole from '../types/PlayerRole.type';
import DebriefingService from './DebriefingService';

export default class ReportingService {
  debriefing: DebriefingService;

  constructor(dependencies: { debriefing: DebriefingService }) {
    this.debriefing = dependencies.debriefing;
  }

  public preparePayload(teamId: string) {
    // TODO check each property
    const data: IReportData = {
      gameID: teamId,
      teamName: teamId,
      date: this.debriefing.teamStartedAt(teamId),
      chatLink: `${process.env.GAME_URL}/init?demoId=${this.debriefing.sessionId}::${teamId}:DS.T1&rp=conversation-report`,
      players: this.debriefing.teamPlayers(teamId).map(role => ({
        role,
        avatar: this.debriefing.playerAvatar(teamId, role),
      })),
      feedbackID: this.debriefing.teamFeedback(teamId),
      budgetAmountVotes: this.debriefing
        .teamBudgetAmountVotes(teamId)
        .map(vote => ({
          role: vote.voter,
          value: vote.decision,
        })),
      budgetAmount: this.debriefing.teamBudgetAmountResult(teamId).toFixed(0),
      budgetAllocationVotes: this.debriefing
        .teamBudgetAllocationVotes(teamId)
        .map(vote => ({
          role: vote.voter,
          value: this.debriefing.interpretPlayerBudgetAllocationChoice(
            teamId,
            vote.voter as PlayerRole
          ),
        })),
      budgetAllocation:
        this.debriefing.interpretTeamBudgetAllocationChoice(teamId),
      nextYearData: [
        [
          'Spesa totale per farmaci',
          numeral(this.debriefing.totalDrugsCostsYear0).format('$ 0,0'),
          numeral(this.debriefing.teamBudgetAmountResult(teamId)).format(
            '$ 0,0'
          ),
          numeral(this.debriefing.teamTotalDrugsCostsYear1(teamId)).format(
            '$ 0,0'
          ),
        ],
        [
          'Spesa totale per eventi avversi',
          numeral(this.debriefing.unexpectedEventCostsYear0).format('$ 0,0'),
          numeral(
            this.debriefing.teamUnexpectedEventCostsYear1(teamId) -
              this.debriefing.unexpectedEventCostsYear0
          ).format('$ 0,0'),
          numeral(this.debriefing.teamUnexpectedEventCostsYear1(teamId)).format(
            '$ 0,0'
          ),
        ],
        [
          'Compliance media',
          numeral(this.debriefing.complianceYear0).format('0.00 %'),
          numeral(this.debriefing.teamComplianceDelta(teamId)).format('0.00 %'),
          numeral(this.debriefing.teamComplianceYear1(teamId)).format('0.00 %'),
        ],
        [
          'Pazienti trattati',
          numeral(this.debriefing.numberOfPatientsTreatedYear0).format('0,0'),
          numeral(
            this.debriefing.teamNumberOfPatientsTreatedYear1(teamId) -
              this.debriefing.numberOfPatientsTreatedYear0
          ).format('0,0'),
          numeral(
            this.debriefing.teamNumberOfPatientsTreatedYear1(teamId)
          ).format('0,0'),
        ],
        [
          '% Pazienti trattati con farmaci biologici',
          numeral(
            this.debriefing.percentageOfPatientsTreatedWithBioYear0
          ).format('0.00 %'),
          numeral(
            this.debriefing.teamPercentageOfPatientsTreatedWithBioYear1(
              teamId
            ) - this.debriefing.percentageOfPatientsTreatedWithBioYear0
          ).format('0.00 %'),
          numeral(
            this.debriefing.teamPercentageOfPatientsTreatedWithBioYear1(teamId)
          ).format('0.00 %'),
        ],
      ],
      extraordinaryEventData: [
        [
          'diagnosis',
          this.debriefing.teamDiagnosisDecision(teamId),
          this.debriefing.teamDiagnosisMotivation(teamId),
        ],
        [
          'distribution',
          this.debriefing.teamDirectDistributionDecision(teamId),
          this.debriefing.teamDirectDistributionMotivation(teamId),
        ],
        [
          'tender',
          this.debriefing.teamDesertTenderCostsDecision(teamId),
          this.debriefing.teamDesertTenderCostsMotivation(teamId),
        ],
      ],
      kpis: Object.entries({
        ReduceTotalDrugsCosts: {
          correct:
            this.debriefing.hasTeamAchievedReduceTotalDrugsCostsGoal(teamId),
          value: numeral(
            this.debriefing.teamTotalDrugsCostsDelta(teamId)
          ).format('$ 0,0'),
        },
        ReduceUnexpectedEventsRelatedCosts: {
          correct:
            this.debriefing.hasTeamAchievedReduceUnexpectedEventsRelatedCostsGoal(
              teamId
            ),
          value: numeral(
            this.debriefing.teamUnexpectedEventCostsDelta(teamId)
          ).format('$ 0,0'),
        },
        IncreaseTotalCompliance: {
          correct:
            this.debriefing.hasTeamAchievedIncreaseTotalComplianceGoal(teamId),
          value: numeral(this.debriefing.teamComplianceDelta(teamId)).format(
            '0.00 %'
          ),
        },
      }).map(([kpiId, kpiPayload]) => ({
        id: kpiId,
        ...kpiPayload,
      })),
    };

    return data;
  }
}

// const data = {
//     gameID: team.state.gameID,
//     teamName: team.state.teamName,
//     date: team.state.date,
//     chatLink: `${window._env_.HOST_NAME}/games/${team.state.chatRoomID}/conversation-report`,
//     players: team.state.players.toJSON(),
//     feedbackID: team.state.feedbackID,
//     budgetAmountVotes: team.state.budgetAmountVotes.map(vote => ({
//       role: vote.role,
//       value: vote.choiceID,
//     })),
//     budgetAmount: team.state.budgetAmount,
//     budgetAllocationVotes: team.state.budgetAllocationVotes.map(vote => ({
//       role: vote.role,
//       value: interpretAllocationChoice(
//         team.state.yearComparison.budgetAmountResult,
//         vote.choiceID
//       ),
//     })),
//     budgetAllocation: interpretAllocationChoice(
//       team.state.yearComparison.budgetAmountResult,
//       team.state.budgetAllocation
//     ),
//     nextYearData: [
//       [
//         'Spesa totale per farmaci',
//         numeral(team.state.yearComparison.totalDrugsCostsYear0).format('$ 0,0'),
//         numeral(team.state.yearComparison.budgetAmountResult).format('$ 0,0'),
//         numeral(team.state.yearComparison.totalDrugsCostsYear1).format('$ 0,0'),
//       ],
//       [
//         'Spesa totale per eventi avversi',
//         numeral(team.state.yearComparison.unexpectedEventCostsYear0).format(
//           '$ 0,0'
//         ),
//         '',
//         numeral(team.state.yearComparison.unexpectedEventCostsYear1).format(
//           '$ 0,0'
//         ),
//       ],
//       [
//         'Compliance media',
//         numeral(team.state.yearComparison.complianceYear0).format('0.00 %'),
//         numeral(team.state.yearComparison.complianceDelta).format('0.00 %'),
//         numeral(team.state.yearComparison.complianceYear1).format('0.00 %'),
//       ],
//       [
//         'Pazienti trattati',
//         numeral(team.state.yearComparison.numberOfPatientsTreatedYear0).format(
//           '0,0'
//         ),
//         '',
//         numeral(team.state.yearComparison.numberOfPatientsTreatedYear1).format(
//           '0,0'
//         ),
//       ],
//       [
//         '% Pazienti trattati con farmaci biologici',
//         numeral(
//           team.state.yearComparison.percentageOfPatientsTreatedWithBioYear0
//         ).format('0.00 %'),
//         '',
//         numeral(
//           team.state.yearComparison.percentageOfPatientsTreatedWithBioYear1
//         ).format('0.00 %'),
//       ],
//     ],
//     extraordinaryEventData: [
//       [
//         'diagnosis',
//         team.state.diagnosisDecision,
//         team.state.diagnosisMotivation,
//       ],
//       [
//         'distribution',
//         team.state.directDistributionDecision,
//         team.state.directDistributionMotivation,
//       ],
//       [
//         'tender',
//         team.state.desertTendersCostsDecision,
//         team.state.desertTendersCostsMotivation,
//       ],
//     ],
//     kpis: team.state.teamAchievements.map(achievement => {
//       switch (achievement.id) {
//         case 'ReduceTotalDrugsCosts':
//           return {
//             id: achievement.id,
//             value: numeral(
//               team.state.yearComparison.totalDrugsCostsDelta
//             ).format('$ 0,0'),
//             correct: achievement.achieved,
//           };
//         case 'ReduceUnexpectedEventsRelatedCosts':
//           return {
//             id: achievement.id,
//             value: numeral(
//               team.state.yearComparison.unexpectedEventCostsDelta
//             ).format('$ 0,0'),
//             correct: achievement.achieved,
//           };
//         case 'IncreaseTotalCompliance':
//           return {
//             id: achievement.id,
//             value: numeral(team.state.yearComparison.complianceDelta).format(
//               '0.00 %'
//             ),
//             correct: achievement.achieved,
//           };
//         default:
//           return {};
//       }
//     }),
//   }
