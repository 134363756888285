import LOCATIONS from '../../data/locations.data';
import { useMonitoring } from '../../stores/game/factories/monitoring';
import PhaseOverviewTeamLabels from './PhaseOverviewTeamLabels';
import PhaseRooms from './PhaseRooms';

interface IProps {
  phase: keyof typeof LOCATIONS;
  firstPopulatedRoom?: string;
}

const PhaseOverview = ({ phase, firstPopulatedRoom }: IProps) => {
  const { viewModel } = useMonitoring(({ viewModel }) => ({
    viewModel,
  }));

  if (!viewModel?.games)
    return (
      <p className="py-20 text-4xl text-center animate-pulse text-slate-400">
        Loading...
      </p>
    );

  if (!viewModel.games.some(({ isStarted }) => isStarted === true))
    return (
      <p className="py-20 text-4xl text-center animate-pulse text-slate-400">
        Sessione non ancora avviata.
      </p>
    );

  return (
    <div id="phase-overview-main" className="w-full h-full p-8">
      <div
        id="phase-overview-scrollable"
        className="relative grid w-full h-full pt-10 pl-2"
        style={{
          gridTemplateColumns: '140px 1fr',
        }}
      >
        <PhaseOverviewTeamLabels
          teams={viewModel.games
            .filter(Boolean)
            .map(({ id }) => id)
            .filter(Boolean)}
        />

        <PhaseRooms phase={phase} firstPopulatedRoom={firstPopulatedRoom} />
      </div>
    </div>
  );
};

export default PhaseOverview;
