"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports["default"] = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _isNumber = _interopRequireDefault(require("../utils/isNumber"));

/**
 * Generate glyphs indices from string indices
 *
 * ex. resolve([[0, 1, 2, 4]]) => [0, 1, 2, 2, 3]
 *
 * @param  {String}  string
 * @param  {Array}  string indices
 * @return {Array} glyph indices
 */
var resolve = function resolve(string, stringIndices) {
  if (stringIndices === void 0) {
    stringIndices = [];
  }

  var counter = 0;
  var glyphIndices = [];

  for (var i = 0; i < stringIndices.length; i++) {
    var current = stringIndices[i];
    var prevValue = stringIndices[i - 1];
    var stringIndex = (0, _isNumber["default"])(current) ? current : prevValue + 1 || 0;
    var nextValue = stringIndices[i + 1] || stringIndex + 1;
    var diff = Math.abs(nextValue - stringIndex);
    glyphIndices.push.apply(glyphIndices, R.repeat(counter, diff));
    counter += 1;
  } // Append ending ligature glyph indices


  if (string.length !== glyphIndices.length) {
    var _diff = Math.max(0, string.length - glyphIndices.length);

    var lastValue = R.last(glyphIndices);
    glyphIndices.push.apply(glyphIndices, R.repeat(lastValue, _diff));
  }

  return glyphIndices;
};

var _default = resolve;
exports["default"] = _default;