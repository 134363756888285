"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports["default"] = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _unicodeProperties = _interopRequireDefault(require("@react-pdf/unicode-properties"));

var _empty = _interopRequireDefault(require("../../attributedString/empty"));

var ignoredScripts = ['Common', 'Inherited', 'Unknown'];
/**
 * Resolves unicode script in runs, grouping equal runs together
 *
 * @param  {Object}  layout options
 * @param  {Object}  attributed string
 * @return {Object} attributed string
 */

var scriptItemizer = function scriptItemizer(options, attributedString) {
  var string = attributedString.string;
  var lastScript = 'Unknown';
  var lastIndex = 0;
  var index = 0;
  var res = [];
  if (!string) return (0, _empty["default"])();

  for (var _iterator = string, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var _char = _ref;

    var codePoint = _char.codePointAt();

    var script = _unicodeProperties["default"].getScript(codePoint);

    if (script !== lastScript && !ignoredScripts.includes(script)) {
      if (lastScript !== 'Unknown') {
        res.push({
          start: lastIndex,
          end: index,
          attributes: {
            script: lastScript
          }
        });
      }

      lastIndex = index;
      lastScript = script;
    }

    index += _char.length;
  }

  if (lastIndex < string.length) {
    res.push({
      start: lastIndex,
      end: string.length,
      attributes: {
        script: lastScript
      }
    });
  }

  return {
    string: string,
    runs: res
  };
};

var _default = R.curryN(2, scriptItemizer);

exports["default"] = _default;