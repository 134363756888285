import clsx from 'clsx';

const ShadowedCard = (props: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={clsx('rounded-md bg-white p-6 shadow-xl', props.className)}>
    {props.children}
  </div>
);

export default ShadowedCard;
