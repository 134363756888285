import PlayerRole from '../types/PlayerRole.type';

const ROLES: Record<PlayerRole, string> = Object.freeze({
  DS: 'DirettoreSanitario',
  DF: 'DirettoreFarmaciaOspedaliera',
  DM: 'DirettoreDipartimentoMedicinaInterna',
  DP: 'DirettoreUOCPneumologia',
  PE: 'ProvveditoreEconomo',
  CA: 'ClinicoReferenteFederAsma',
});

export default ROLES;
