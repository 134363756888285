import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import PlayerRole from '../../types/PlayerRole.type';
import DebriefingBaseSelect from '../DebriefingBaseSelect';
import DebriefingNoResultsPlaceholder from '../DebriefingNoResultsPlaceholder';
import BudgetAllocationVotesTable from '../plots/BudgetAllocationVotesTable';

type Entry = {
  teamID: string;
  DS: string;
  DF: string;
  DM: string;
  DP: string;
  PE: string;
  CA: string;
  teamVote: string;
};

const BudgetAllocationSection = () => {
  const { formatMessage: f } = useIntl();
  const [entries, setEntries] = useState<Entry[]>([]);
  const [phase1VoteFilter, setPhase1VoteFilter] = useState<string>();

  const viewModel = useDebriefing(({ viewModel }) => viewModel);

  useEffect(() => {
    if (!viewModel?.teams) return;

    setEntries(
      viewModel.teams
        .filter(teamId =>
          !phase1VoteFilter
            ? teamId
            : phase1VoteFilter === 'Aumento delle risorse'
            ? viewModel.teamBudgetAmountResult(teamId) > 0
            : phase1VoteFilter === 'Riduzione delle risorse'
            ? viewModel.teamBudgetAmountResult(teamId) < 0
            : viewModel.teamBudgetAmountResult(teamId) === 0
        )
        .map(teamId => ({
          teamID: teamId,
          ...viewModel.state[teamId].players?.reduce(
            (obj: Record<PlayerRole, string>, roleId) => ({
              ...obj,
              [roleId]: viewModel.interpretPlayerBudgetAllocationChoice(
                teamId,
                roleId
              ),
            }),
            {} as Record<PlayerRole, string>
          ),
          teamVote: viewModel.interpretTeamBudgetAllocationChoice(teamId),
        }))
    );
  }, [viewModel, phase1VoteFilter]);

  return (
    <div>
      <div style={{ maxWidth: '24vw' }}>
        <DebriefingBaseSelect
          label={f({
            id: 'teacher.debriefing.phase-2-vote-debrief.phase-1-decision',
          })}
          isMulti={false}
          placeholder="Tutte"
          options={[
            'Aumento delle risorse',
            'Riduzione delle risorse',
            'Mantenimento delle risorse',
          ].map(entry => ({
            label: entry,
            value: entry,
          }))}
          handleChange={choice => setPhase1VoteFilter(choice?.value)}
        />
      </div>

      <span className="block w-full h-12" />

      {entries.length ? (
        <BudgetAllocationVotesTable entries={entries} />
      ) : (
        <DebriefingNoResultsPlaceholder />
      )}
    </div>
  );
};

export default BudgetAllocationSection;
