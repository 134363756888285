import { Text, View } from '@react-pdf/renderer';
import { range } from 'lodash';
import React, { useContext } from 'react';
import { DARK_CALCITE } from '../colors';
import ReportContext from '../ReportContext';
import TableRowStyle from './styles/TableRowStyle';
import getRowBackgroundColorFromPosition from './utils/getRowBackgroundColorFromPosition';

type Props = {
  tableID: string;
  elements: Array<Array<string>>;
  tableStyles?: Array<Object>;
};

const Table: React.FC<Props> = props => {
  const { tableID, elements, tableStyles } = props;

  const { dictionary } = useContext(ReportContext);

  const numberOfColumns = elements[0].length;

  const largeCol = '50%';
  const standardCol = '16.6%';

  function getColWidth(position, tableID) {
    var colWidth = standardCol;

    if (tableID !== 'extraordinaryEvent') {
      switch (position) {
        case 0:
          colWidth = largeCol;
          break;
      }
    } else {
      switch (position) {
        case 0:
          colWidth = '200px';
          break;
        case 1:
          colWidth = '200px';
          break;
        case 2:
          colWidth = '320px';
          break;
      }
    }

    return colWidth;
  }

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {tableID === 'extraordinaryEvent' ? null : (
        <View
          style={{
            backgroundColor: getRowBackgroundColorFromPosition(0),
            ...TableRowStyle,
            flexDirection: 'row',
            height: '69.3px',
            borderTop: `1pt solid ${DARK_CALCITE}`,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          {range(numberOfColumns).map((col, index) => {
            return (
              <Text
                key={index}
                style={{
                  fontSize: 15,
                  letterSpacing: 0,
                  lineHeight: '16.8px',
                  width: index === 0 ? largeCol : standardCol,
                }}
              >
                {dictionary[`header${index + 1}${tableID}`]}
              </Text>
            );
          })}
        </View>
      )}
      {elements.map((element, index) => {
        return (
          <View
            key={index}
            style={{
              backgroundColor: getRowBackgroundColorFromPosition(index + 1),
              ...TableRowStyle,
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderTop: `1pt solid ${DARK_CALCITE}`,
              borderTopLeftRadius:
                tableID === 'extraordinaryEvent' && index === 0 ? 6 : 0,
              borderTopRightRadius:
                tableID === 'extraordinaryEvent' && index === 0 ? 6 : 0,
              borderBottom:
                index === elements.length - 1 ? `1pt solid ${DARK_CALCITE}` : 0,
              borderBottomRightRadius: index === elements.length - 1 ? 6 : 0,
              borderBottomLeftRadius: index === elements.length - 1 ? 6 : 0,
            }}
          >
            {range(numberOfColumns).map((row, index) => {
              var additionalStyle = {};

              if (tableStyles) {
                additionalStyle = tableStyles[index] ? tableStyles[index] : {};
              }

              return (
                <View
                  key={index}
                  style={{
                    width: getColWidth(index, tableID),
                    alignSelf: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      lineHeight: '19px',
                      fontFamily: 'RubikBold',
                      ...additionalStyle,
                    }}
                  >
                    {element[index]}
                  </Text>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

export default Table;
