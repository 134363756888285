import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import PlayersVotesTable from '../blocks/PlayersVotesTable';
import { CADET_BLUE, DARK, ORANGE } from '../colors';
import { CONTENT2 } from '../contentSizes';
import ReportContext from '../ReportContext';


const TeamChoicesValue = ({ players, votes, budgetAmount }) => {
  const { dictionary } = useContext(ReportContext);


  return (
    <Page style={{ position: 'relative', fontFamily: 'RubikRegular' }}>
      <Header />
      <View>
        <Text style={{
          color: ORANGE,
          fontSize: 26,
          fontFamily: 'RubikBold',
          letterSpacing: 0,
          lineHeight: '31px',
          textAlign: 'center'
        }}>
          {dictionary['TeamChoicesValueTitle']}
        </Text>
        <Text style={{
          color: ORANGE,
          textAlign: 'center',
          fontSize: 18
        }}>
          {dictionary['TeamChoicesValueSubtitle']}
        </Text>
        <View style={{
          flexDirection: 'row',
          justifyContent: "space-between",
          alignItems: 'center',
          alignContent: 'center',
          width: CONTENT2,
          margin: 'auto',
          marginTop: '36px',
        }}>
          <PlayersVotesTable tableID={'TeamChoicesValue'} players={players} votes={votes} width={440} />
          <View style={{
            flexDirection: 'column',
            width: 239,
            alignItems: 'center',
          }}>
            <Text style={{
              color: DARK,
              fontSize: 22,
              letterSpacing: 0,
              lineHeight: '28px',
            }}>
              {dictionary['TeamChoicesValuePlaceholder']}
            </Text>
            <View style={{
              width: 239,
              height: 115,
              marginTop: '13px',
              position: 'relative'
            }}>
              <Image name="box1" style={{
                height: 115,
              }} />
              <View style={{
                width: 239,
                height: 265,
                position: "absolute",
                top: 0,
                textAlign: 'center',
              }}>
                <Text style={{
                  position: 'absolute',
                  top: 40,
                  fontSize: 26,
                  fontFamily: 'RubikBold',
                  letterSpacing: 0,
                  lineHeight: '31px',
                  color: CADET_BLUE,
                  width: '100%',
                }}>
                  {budgetAmount}
                </Text>
              </View>
            </View>

          </View>
        </View>
      </View>
    </Page >
  );
};

export default TeamChoicesValue;
