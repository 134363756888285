import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import DebriefingBaseSelect from '../DebriefingBaseSelect';
import DebriefingNoResultsPlaceholder from '../DebriefingNoResultsPlaceholder';
import NextYearDebriefTable from '../plots/NextYearDebriefTable';

const NextYearDebrief = () => {
  const { formatMessage: f } = useIntl();
  const [teamFilter, setTeamFilter] = useState<string>();
  const [entry, setEntry] = useState<string>();

  const viewModel = useDebriefing(({ viewModel }) => viewModel);

  useEffect(() => {
    if (!viewModel?.teams) return;

    setEntry(viewModel.teams.find(id => id === teamFilter));
    if (!teamFilter) setTeamFilter(viewModel.teams[0]);
  }, [viewModel, teamFilter]);

  if (!viewModel) return <p>Loading...</p>;

  return (
    <div>
      <DebriefingBaseSelect
        isMulti={false}
        label="Team"
        placeholder={f({ id: 'teacher.debriefing.teams.none' })}
        options={viewModel.teams.map(teamId => ({
          label: teamId,
          value: teamId,
        }))}
        handleChange={newTeamFilter => setTeamFilter(newTeamFilter?.value)}
        value={teamFilter ? { label: teamFilter, value: teamFilter } : null}
      />

      <span className="block w-full h-2" />

      {entry ? (
        <NextYearDebriefTable
          totalDrugsCostsYear0={viewModel.totalDrugsCostsYear0}
          totalOMACostYear0={viewModel.totalOMACostYear0}
          totalMEPOCostYear0={viewModel.totalMEPOCostYear0}
          totalOMACostYear1={viewModel.teamTotalOMACostYear1(entry)}
          totalMEPOCostYear1={viewModel.teamTotalMEPOCostYear1(entry)}
          budgetAmountResult={viewModel.teamBudgetAmountResult(entry)}
          totalDrugsCostsYear1={viewModel.teamTotalDrugsCostsYear1(entry)}
          unexpectedEventCostsYear0={viewModel.unexpectedEventCostsYear0}
          unexpectedEventCostsYear1={viewModel.teamUnexpectedEventCostsYear1(
            entry
          )}
          complianceYear0={viewModel.complianceYear0}
          complianceDelta={viewModel.teamComplianceDelta(entry)}
          complianceYear1={viewModel.teamComplianceYear1(entry)}
          numberOfPatientsTreatedYear0={viewModel.numberOfPatientsTreatedYear0}
          numberOfPatientsTreatedYear1={viewModel.teamNumberOfPatientsTreatedYear1(
            entry
          )}
          percentageOfPatientsTreatedWithBioYear0={
            viewModel.percentageOfPatientsTreatedWithBioYear0
          }
          percentageOfPatientsTreatedWithBioYear1={viewModel.teamPercentageOfPatientsTreatedWithBioYear1(
            entry
          )}
        />
      ) : (
        <DebriefingNoResultsPlaceholder />
      )}
    </div>
  );
};

export default NextYearDebrief;
