import { View } from '@react-pdf/renderer';
import React from 'react';
import { DARK_BLUE } from '../colors';
import Image from './Image';

const Header: React.FC = () => {

  return (
    <View style={{
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      backgroundColor: DARK_BLUE,
      height: 104,
      paddingLeft: 31.09,
      paddingRight: 34.5,
      alignItems: 'center',
      marginBottom: '20px',
    }}>
      <Image name={'logo_sda_bocconi'} style={{
        height: 35,
        width: 161,
      }} />
      <Image name={'logo_hospitalia'} style={{
        height: 47,
        width: 176,
      }} />
      <Image name={'logo_area'} style={{
        height: 37,
        width: 173,
      }} />
    </View>
  );
};

export default Header;
