import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import PlayerRole from '../../types/PlayerRole.type';
import DebriefingBaseSelect from '../DebriefingBaseSelect';
import DebriefingNoResultsPlaceholder from '../DebriefingNoResultsPlaceholder';
import PlayersGoalsDebriefTable from '../plots/PlayersGoalsTable';

type Entry = {
  id: string;
  goal1: { id: string; achieved: boolean };
  goal2: { id: string; achieved: boolean };
  goal3: { id: string; achieved: boolean };
  count: number;
};

const PlayersGoalsSection = () => {
  const { formatMessage: f } = useIntl();
  const [entries, setEntries] = useState<Entry[]>([]);
  const [teamFilter, setTeamFilter] = useState<string>();
  const [roleFilter, setRoleFilter] = useState<string>();

  const viewModel = useDebriefing(({ viewModel }) => viewModel);

  useEffect(() => {
    if (!viewModel?.teams) return;

    if (teamFilter) {
      const teamId = viewModel.teams.find(teamId => teamId === teamFilter);

      setEntries(
        viewModel.state[teamId].players.map(playerId => ({
          id: f({ id: `profiles:${viewModel.roles[playerId]}.title` }),
          goal1: viewModel.playerGoals(teamId, playerId)[0],
          goal2: viewModel.playerGoals(teamId, playerId)[1],
          goal3: viewModel.playerGoals(teamId, playerId)[2],
          count: viewModel
            .playerGoals(teamId, playerId)
            .filter(({ achieved }) => achieved).length,
        }))
      );
    } else if (roleFilter)
      setEntries(
        viewModel.teams
          .map(
            teamId =>
              viewModel
                .teamPlayers(teamId)
                .find(roleId => roleId === roleFilter) && {
                teamId: teamId,
                playerId: viewModel
                  .teamPlayers(teamId)
                  .find(roleId => roleId === roleFilter) as PlayerRole,
              }
          )
          .filter(Boolean)
          .map(({ teamId, playerId }) => ({
            id: playerId,
            goal1: viewModel.playerGoals(teamId, playerId)[0],
            goal2: viewModel.playerGoals(teamId, playerId)[1],
            goal3: viewModel.playerGoals(teamId, playerId)[2],
            count: viewModel
              .playerGoals(teamId, playerId)
              .filter(({ achieved }) => achieved).length,
          }))
      );
    else setEntries([]);
  }, [viewModel, teamFilter, roleFilter]);

  if (!viewModel) return <p>Loading...</p>;

  return (
    <div className="w-full">
      <div className="flex flex-row mb-8">
        <DebriefingBaseSelect
          isMulti={false}
          label="Team"
          placeholder={
            roleFilter
              ? f({ id: 'teacher.debriefing.teams.all' })
              : f({ id: 'teacher.debriefing.teams.none' })
          }
          options={viewModel.teams.map(teamId => ({
            label: teamId,
            value: teamId,
          }))}
          handleChange={newTeamFilter => {
            setRoleFilter(undefined);
            setTeamFilter(newTeamFilter?.value);
          }}
          value={
            teamFilter
              ? {
                  label: teamFilter,
                  value: teamFilter,
                }
              : null
          }
        />
        <DebriefingBaseSelect
          isMulti={false}
          label="Ruolo"
          placeholder={
            teamFilter
              ? f({ id: 'teacher.debriefing.teams.all' })
              : f({ id: 'teacher.debriefing.teams.none' })
          }
          options={Object.entries(viewModel.roles).map(
            ([shortRole, longRole]) => ({
              label: f({ id: `profiles:${longRole}.title` }),
              value: shortRole,
            })
          )}
          handleChange={newRoleFilter => {
            setTeamFilter(undefined);
            setRoleFilter(newRoleFilter?.value);
          }}
          value={
            roleFilter
              ? {
                  label: f({
                    id: `profiles:${viewModel.roles[roleFilter]}.title`,
                  }),
                  value: roleFilter,
                }
              : null
          }
        />
      </div>

      {entries.length ? (
        <PlayersGoalsDebriefTable
          entries={entries}
          mode={teamFilter ? 'team' : 'role'}
        />
      ) : (
        <DebriefingNoResultsPlaceholder />
      )}
    </div>
  );
};

export default PlayersGoalsSection;
