"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports["default"] = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _sort = _interopRequireDefault(require("./sort"));

var _isEmpty = _interopRequireDefault(require("./isEmpty"));

var sortPoints = function sortPoints(a, b) {
  return a[1] - b[1] || a[3] - b[3];
};

var mergeAttributes = function mergeAttributes(key, left, right) {
  return key === 'attributes' ? R.merge(left, right) : right;
};

var generatePoints = R.o(R.sort(sortPoints), R.addIndex(R.chain)(function (run, i) {
  return [['start', run.start, run.attributes, i], ['end', run.end, run.attributes, i]];
}));
var flattenEmptyRuns = R.compose(R.map(R.reduce(R.mergeDeepWithKey(mergeAttributes), {})), R.groupWith(R.eqProps('start')));

var flattenRegularRuns = function flattenRegularRuns(runs) {
  var res = [];
  var points = generatePoints(runs);
  var start = -1;
  var attrs = {};
  var stack = [];

  for (var _iterator = points, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var _ref2 = _ref,
        type = _ref2[0],
        offset = _ref2[1],
        attributes = _ref2[2];

    if (start !== -1 && start < offset) {
      res.push({
        start: start,
        end: offset,
        attributes: attrs
      });
    }

    if (type === 'start') {
      stack.push(attributes);
      attrs = R.merge(attrs, attributes);
    } else {
      attrs = {};

      for (var i = 0; i < stack.length; i++) {
        if (stack[i] === attributes) {
          stack.splice(i--, 1);
        } else {
          attrs = R.merge(attrs, stack[i]);
        }
      }
    }

    start = offset;
  }

  return res;
};
/**
 * Flatten many runs
 *
 * @param  {Array}  runs
 * @return {Array} flatten runs
 */


var flatten = function flatten(runs) {
  if (runs === void 0) {
    runs = [];
  }

  return R.compose(_sort["default"], R.apply(R.useWith(R.concat, [flattenEmptyRuns, flattenRegularRuns])), R.partition(_isEmpty["default"]))(runs);
};

var _default = flatten;
exports["default"] = _default;