import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Page from '../blocks/Page';
import Table from '../blocks/Table';
import { GREEN_BLUE } from '../colors';
import { CONTENT2 } from '../contentSizes';
import ReportContext from '../ReportContext';

const NextYearChoices = ({ nextYearData }) => {
  const { dictionary } = useContext(ReportContext);

  const tableStyles = [
    {
      fontSize: 15,
      lineHeight: '18px',
      alignSelf: 'flex-start',
      fontFamily: 'RubikRegular',
    },
  ];

  return (
    <Page
      style={{
        position: 'relative',
        fontFamily: 'RubikRegular',
      }}
    >
      <Header />
      <Text
        style={{
          fontSize: 26,
          fontFamily: 'RubikBold',
          letterSpacing: 0,
          lineHeight: '31px',
          textAlign: 'center',
          color: GREEN_BLUE,
        }}
      >
        {dictionary['TeamChoicesAnswerTitle']}
      </Text>
      <Text
        style={{
          fontSize: 18,
          letterSpacing: 0,
          lineHeight: '22px',
          textAlign: 'center',
          color: GREEN_BLUE,
        }}
      >
        {dictionary['TeamChoicesAnswerSubtitle']}
      </Text>
      <View
        style={{
          position: 'relative',
          flex: 1,
          flexDirection: 'row',
          width: CONTENT2,
          margin: 'auto',
          marginTop: '17px',
        }}
      >
        <Table
          tableID={'nextYearChoices'}
          elements={nextYearData}
          tableStyles={tableStyles}
        />
      </View>
    </Page>
  );
};

export default NextYearChoices;
