export default function formatTimeDifference(
  difference: /** in milliseconds */ number
) {
  const abs = Math.abs(difference);
  const seconds = Math.floor((abs / 1000) % 60);
  const minutes = Math.floor((abs / 1000 / 60) % 60);
  const hours = Math.floor((abs / (1000 * 60 * 60)) % 24);
  const days = Math.floor(abs / (1000 * 60 * 60 * 24));

  return [minutes, seconds]
    .map(slot => slot.toString().padStart(2, '0'))
    .join(':');
}
