"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports["default"] = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _append = _interopRequireDefault(require("../attributedString/append"));

var _trim = _interopRequireDefault(require("../attributedString/trim"));

/**
 * Trucante block with ellipsis
 *
 * @param  {number}  lines quantity
 * @param  {Object}  paragraph block
 * @return {Object} sliced paragraph block
 */
var truncate = function truncate(block) {
  var runs = R.propOr([], 'runs', R.last(block));
  var font = R.path(['attributes', 'font'], R.last(runs));

  if (font) {
    return R.adjust(-1, R.compose((0, _append["default"])(font.glyphForCodePoint(8230)), _trim["default"]))(block);
  }

  return block;
};

var _default = truncate;
exports["default"] = _default;