import Select, { components } from 'react-select';
import { useIntl } from 'react-intl';

const NoOptionsMessage = props => {
  const { formatMessage: f } = useIntl();

  return (
    <components.NoOptionsMessage {...props}>
      <span>{f({ id: 'teacher:sessions.no-results' })}</span>
    </components.NoOptionsMessage>
  );
};

type Props = {
  label: string;
  placeholder: string;
  options: {
    label: string;
    value: string;
  }[];
  handleChange?: (...args: any[]) => void;
  isMulti: boolean;
  value?: any;
};

const DebriefingBaseSelect = (props: Props) => (
  <div>
    <label
      style={{
        fontFamily: '"Rubik", sans-serif',
        color: '#677b7e',
        fontSize: '14px',
        padding: '4px',
        fontWeight: 500,
        display: 'block',
        marginBottom: '2px',
        userSelect: 'none',
      }}
    >
      {props.label}
    </label>
    <Select
      isClearable
      isMulti={props.isMulti}
      placeholder={props.placeholder}
      styles={{
        control: styles => ({
          ...styles,
          backgroundColor: 'white',
          border: '2px solid gainsboro',
          width: '420px',
          marginRight: '48px',
          minHeight: '48px',
          borderRadius: '8px',
        }),
        input: styles => ({
          ...styles,
          fontFamily: '"Rubik", sans-serif',
        }),
        placeholder: styles => ({
          ...styles,
          fontFamily: '"Rubik", sans-serif',
        }),
        option: (styles, { isFocused }) => ({
          ...styles,
          backgroundColor: isFocused ? '#e6fdf9' : 'none',
          color: isFocused ? '#222450' : '#777788',
        }),
        menu: styles => ({
          ...styles,
          maxWidth: '420px',
        }),
      }}
      options={props.options}
      value={props.value}
      onChange={props.handleChange}
      components={{
        NoOptionsMessage,
      }}
    />
  </div>
);

export default DebriefingBaseSelect;
