import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import LOCATIONS from '../../data/locations.data';
import PhaseNavigationButton from './PhaseNavigationButton';
import PhaseOverview from './PhaseOverview';
import { useMonitoring } from '../../stores/game/factories/monitoring';
import Spinner from '../Spinner';
import Timer from './Timer';
import { current } from 'immer';

const PhasesOverview = () => {
  const { formatMessage } = useIntl();
  const viewModel = useMonitoring(({ viewModel }) => viewModel);

  const [currentPhase, setCurrentPhase] = useState<number>(0);
  const [isSearchingForPhase, setIsSearchingForPhase] = useState<boolean>(true);
  const [firstPopulatedRoom, setFirstPopulatedRoom] = useState<string>();

  useEffect(() => {
    if (!isSearchingForPhase) return;
    if (!viewModel) return;

    setTimeout(() => {
      setCurrentPhase(
        Math.max(
          0,
          Object.keys(LOCATIONS).indexOf(
            viewModel.gamePhaseToDebriefingPhase(viewModel.currentPhase)
          )
        )
      );

      setTimeout(() => {
        setIsSearchingForPhase(false);
      }, 400);
    }, 100);
  }, [viewModel, isSearchingForPhase]);

  useEffect(() => {
    if (!viewModel?.currentPhase) return;

    setFirstPopulatedRoom(viewModel.firstPopulatedRoom);
  }, [isSearchingForPhase]);

  if (!viewModel?.currentPhase)
    return <Spinner size="48px" style={{ margin: 'auto' }} />;

  return (
    <motion.div
      initial={{ filter: 'blur(48px)' }}
      animate={{
        filter: isSearchingForPhase ? 'blur(32px)' : 'blur(0px)',
      }}
      transition={{
        duration: 0.8,
      }}
    >
      <div id="phase-control" className="grid justify-between grid-cols-3">
        <PhaseNavigationButton
          disabled={currentPhase === 0}
          handleClick={() => setCurrentPhase(currentPhase - 1)}
          direction="left"
          label={formatMessage({ id: 'teacher:session.previous-phase' })}
        />

        <motion.h4
          key={Object.keys(LOCATIONS)[currentPhase]}
          initial={{ opacity: 0, filter: 'blur(14px)' }}
          animate={{ opacity: 1, filter: 'blur(0px)' }}
          className="relative text-2xl font-light text-center text-slate-600"
        >
          {formatMessage({
            id: `monitoring:${Object.keys(LOCATIONS)[currentPhase]}`,
          })}
        </motion.h4>
        <Timer
          currentPhase={
            Object.keys(LOCATIONS)[currentPhase] as keyof typeof LOCATIONS
          }
        />

        <PhaseNavigationButton
          disabled={Object.keys(LOCATIONS).length - 2 < currentPhase}
          handleClick={() => setCurrentPhase(currentPhase + 1)}
          direction="right"
          label={formatMessage({ id: 'teacher:session.next-phase' })}
        />
      </div>

      <PhaseOverview
        phase={Object.keys(LOCATIONS)[currentPhase] as keyof typeof LOCATIONS}
        firstPopulatedRoom={firstPopulatedRoom}
      />
    </motion.div>
  );
};

export default PhasesOverview;
