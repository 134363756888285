"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var R = _interopRequireWildcard(require("ramda"));

var _fromFragments = _interopRequireDefault(require("../attributedString/fromFragments"));

/**
 * Default word hyphenation engine used when no one provided.
 * Does not perform word hyphenation at all
 *
 * @param  {String} word
 * @return {Array} same word
 */
var defaultHyphenationEngine = function defaultHyphenationEngine(word) {
  return [word];
};
/**
 * Wrap words of attribute string
 *
 * @param  {Object} layout engines
 * @param  {Object}  layout options
 * @param  {Object}  attributed string
 * @return {Object} attributed string including syllables
 */


var wrapWords = function wrapWords(engines, options, attributedString) {
  if (engines === void 0) {
    engines = {};
  }

  if (options === void 0) {
    options = {};
  }

  var syllables = [];
  var fragments = [];
  var hyphenateWord = options.hyphenationCallback || engines.wordHyphenation && engines.wordHyphenation(options) || defaultHyphenationEngine;

  for (var _iterator = attributedString.runs, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var run = _ref;
    var string = '';
    var words = attributedString.string.slice(run.start, run.end).split(/([ ]+)/g).filter(Boolean);

    for (var _iterator2 = words, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
      var _ref2;

      if (_isArray2) {
        if (_i2 >= _iterator2.length) break;
        _ref2 = _iterator2[_i2++];
      } else {
        _i2 = _iterator2.next();
        if (_i2.done) break;
        _ref2 = _i2.value;
      }

      var word = _ref2;
      var parts = hyphenateWord(word);
      syllables.push.apply(syllables, parts);
      string += parts.join('');
    }

    fragments.push({
      string: string,
      attributes: run.attributes
    });
  }

  return (0, _extends2["default"])({}, (0, _fromFragments["default"])(fragments), {
    syllables: syllables
  });
};

var _default = R.curryN(3, wrapWords);

exports["default"] = _default;