import { useEffect } from 'react';
import { Redirect, Route } from 'wouter';
import { IntlProvider } from 'react-intl';
import Session from './pages/session';
import messages from './assets/locales/it';
import SessionsManagement from './comps/SessionsManagement';

export const App = () => {
  useEffect(
    () =>
      console.log(
        `[branch|commit]: [${process.env.CF_PAGES_BRANCH}|${process.env.GIT_COMMIT_HASH}]`
      ),
    []
  );

  return (
    <IntlProvider messages={messages} locale="it">
      <Route path="/sessions" component={SessionsManagement} />
      <Route path="/sessions/:sessionId/:rest*" component={Session} />
      <Route path="/" component={() => <Redirect to="/sessions" />} />
    </IntlProvider>
  );
};
