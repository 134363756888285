import StringToColor from 'string-to-color';

/** 
 * Returns a color for a given string.
 * 
 * @param  {string} text - The text to print.
 * @param  {string} topic - The topic of the text to print.
*/
export function log(text: string, topic?: string): void {
  if (process.env.NODE_ENV === 'production') return;

  const logTopic: string = topic || 'Log';

  console.log(
    `%c[${topic || 'Log'}] ${text}`,
    `color: ${
      typeof StringToColor === 'function' ? StringToColor(logTopic) : 'black'
    }`
  );
}
