import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import PlayersVotesTable from '../blocks/PlayersVotesTable';
import { CADET_BLUE, DARK, GREEN_BLUE } from '../colors';
import { CONTENT2 } from '../contentSizes';
import ReportContext from '../ReportContext';

const TeamChoicesAnswer = ({ players, votes, budgetAllocation }) => {
  const { dictionary } = useContext(ReportContext);

  return (
    <Page
      style={{
        position: 'relative',
        fontFamily: 'RubikRegular',
      }}
    >
      <Header />
      <View>
        <Text
          style={{
            color: GREEN_BLUE,
            fontSize: 26,
            fontFamily: 'RubikBold',
            letterSpacing: 0,
            lineHeight: '31px',
            textAlign: 'center',
          }}
        >
          {dictionary['TeamChoicesAnswerTitle']}
        </Text>
        <Text
          style={{
            color: GREEN_BLUE,
            textAlign: 'center',
            fontSize: 18,
            marginBottom: '36px',
          }}
        >
          {dictionary['TeamChoicesAnswerSubtitle']}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'center',
            width: CONTENT2,
            margin: 'auto',
          }}
        >
          <PlayersVotesTable
            tableID={'TeamChoicesAnswer'}
            players={players}
            votes={votes}
            width={597}
          />
          <View
            style={{
              flexDirection: 'column',
              width: 265,
            }}
          >
            <Text
              style={{
                color: DARK,
                fontSize: 22,
                letterSpacing: 0,
                lineHeight: '28px',
              }}
            >
              {dictionary['TeamChoicesAnswerPlaceholder']}
            </Text>
            <View
              style={{
                position: 'relative',
                marginTop: '13px',
                width: 239,
                height: 265,
              }}
            >
              <Image
                name="box2"
                style={{
                  height: 265,
                }}
              />
              <View
                style={{
                  width: 239,
                  height: 265,
                  position: 'absolute',
                  top: 0,
                }}
              >
                <Text
                  style={{
                    color: CADET_BLUE,
                    fontSize: 20,
                    fontFamily: 'RubikBold',
                    letterSpacing: 0,
                    lineHeight: '31px',
                    textAlign: 'center',
                    width: 200,
                    margin: 'auto',
                  }}
                >
                  {dictionary[budgetAllocation]}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default TeamChoicesAnswer;
