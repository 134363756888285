import { useIntl } from 'react-intl';
import DayJS from 'dayjs';
import SessionsManagementService from '../../services/SessionsManagementService';
import IconButton from '../icon-button';

interface IProps {
  id: string;
  name: string;
  date: { seconds: number };
}

const SessionRow = ({ id, name, date }: IProps) => {
  const { formatMessage } = useIntl();

  return (
    <div
      className="grid items-center w-full px-4 py-2 border-2 rounded-lg shadow-lg shadow-black/5 border-slate-200 bg-white/80 backdrop-blur-sm"
      style={{
        gridTemplateColumns: '100px 1fr 1fr 240px',
      }}
    >
      <div>
        <img
          src={formatMessage({ id: 'teacher:icons.session' })}
          alt="Session"
          className="transition-all duration-500 ease-in-out group-active:scale-90 group-active:duration-75"
        />
      </div>
      <div>{name}</div>
      <div className="text-slate-700">
        {DayJS(date.seconds * 1_000, 'X').format('DD/MM/YYYY')}
      </div>
      <div
        className={`flex flex-row items-center space-x-4 pbac-op-hosp_control.display-[resource=${id}]`}
      >
        <IconButton
          color="Blue"
          image={formatMessage({ id: 'teacher:icons.mission-control' })}
          label="Mission control"
          size="medium"
          onClick={() =>
            window.location.assign(`/sessions/${id}/mission-control/setup`)
          }
        />
        <IconButton
          color="Purple"
          image={formatMessage({ id: 'teacher:icons.debrief' })}
          label="Debriefing"
          size="medium"
          onClick={() =>
            window.location.assign(`/sessions/${id}/debriefing/teams-goals`)
          }
        />
        <IconButton
          color="Red"
          image={formatMessage({ id: 'teacher:icons.delete' })}
          label="Delete"
          size="medium"
          onClick={() => {
            if (confirm(`Cancellare la sessione "${id}"?`))
              SessionsManagementService.deleteSession(id).then(() =>
                window.location.reload()
              );
          }}
        />
      </div>
    </div>
  );
};

export default SessionRow;
