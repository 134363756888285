import {
  doc,
  updateDoc,
  setDoc,
  collection,
  query,
  onSnapshot,
  getDoc,
  getDocs,
  where,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  getFirestore,
} from 'firebase/firestore';
import { app } from './firestore';
import { RemoteFactory } from './remote-factory';


export const remote = RemoteFactory(getFirestore(app), {
  doc,
  updateDoc,
  setDoc,
  collection,
  query,
  onSnapshot,
  getDoc,
  getDocs,
  where,
  deleteDoc,
  arrayUnion,
  arrayRemove,
})

