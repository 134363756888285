import clsx from 'clsx';
import { useIntl } from 'react-intl';
import SessionsManagementService from '../../services/SessionsManagementService';
import { useMonitoring } from '../../stores/game/factories/monitoring';
import PlayerRole from '../../types/PlayerRole.type';

interface IProps {
  roleId: PlayerRole;
  isPlayerInLocation: boolean;
  pillSize: number;
  gameId: string;
  locations: string[];
  handleClick;
}

const PlayerPill = ({
  pillSize,
  isPlayerInLocation,
  roleId,
  gameId,
  locations,
  handleClick,
}: IProps) => {
  const { formatMessage } = useIntl();
  const { hasPlayerVoted, viewModel } = useMonitoring(
    ({ hasPlayerVoted, viewModel }) => ({
      viewModel,
      hasPlayerVoted,
    })
  );

  const isVotingPhase: boolean = locations.some(
    locationPath =>
      locationPath.includes('vote') || locationPath.includes('survey')
  );

  const isAvatarPhase: boolean = locations.includes('avatar');

  // Used to display the final voting waiting animated badge.
  const isWaitingForFinalDSVote: boolean =
    locations.some(locationPath =>
      locationPath.includes('phases/phase-3/vote')
    ) && roleId !== 'DS';

  // Used to display the voting status badge.
  const hasPlayerVotedInLocation: boolean = hasPlayerVoted(
    roleId,
    locations[0],
    gameId
  );

  if (!isPlayerInLocation) return <span />;

  return (
    <div
      className="relative flex items-center justify-center overflow-visible text-xs font-bold text-center text-white transition-all duration-200 ease-in-out border border-white rounded-full shadow-sm cursor-pointer group hover:font-semibold hover:text-sm hover:scale-105 bg-gradient-to-tr from-teal-600 to-teal-500 hover:shadow-lg"
      style={{
        width: `${pillSize}px`,
        height: `${pillSize}px`,
      }}
      onClick={async () =>
        handleClick({
          roleId,
          gameId,
          sessionId: viewModel.sessionId,
          url: SessionsManagementService.getPlayerGameLink(
            roleId,
            viewModel.sessionId,
            encodeURIComponent(gameId)
          ),
          nickname: viewModel.getPlayerNickname(gameId, roleId),
        })
      }
    >
      {isVotingPhase /* || isAvatarPhase  */ && (
        <>
          <span
            className={clsx(
              'absolute top-0 right-0 block rounded-full',
              isWaitingForFinalDSVote
                ? 'w-4 h-4 -top-1 -right-1 animate-spin'
                : 'w-2 h-2 border border-white top-0 right-0',
              isWaitingForFinalDSVote
                ? 'bg-transparent bg-contain'
                : hasPlayerVotedInLocation
                ? 'bg-green-500'
                : 'bg-yellow-500'
            )}
            style={{
              backgroundImage: isWaitingForFinalDSVote
                ? `url('${formatMessage({ id: 'monitoring:icons-waiting' })}')`
                : '#',
            }}
          />
          {!isWaitingForFinalDSVote && (
            <span
              className={clsx(
                'absolute top-0 right-0 block w-2 h-2 rounded-full animate-ping',
                hasPlayerVotedInLocation ? 'bg-green-600' : 'bg-yellow-600'
              )}
            />
          )}
        </>
      )}
      <p>{roleId}</p>
    </div>
  );
};

export default PlayerPill;
