"use strict";

exports.__esModule = true;
exports["default"] = void 0;

/**
 * Slice block at given height
 *
 * @param  {number}  height
 * @param  {Object}  paragraph block
 * @return {number} sliced paragraph block
 */
var sliceAtHeight = function sliceAtHeight(height) {
  return function (block) {
    var newBlock = [];
    var counter = 0;

    for (var _iterator = block, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
      var _ref;

      if (_isArray) {
        if (_i >= _iterator.length) break;
        _ref = _iterator[_i++];
      } else {
        _i = _iterator.next();
        if (_i.done) break;
        _ref = _i.value;
      }

      var line = _ref;
      counter += line.box.height;

      if (counter < height) {
        newBlock.push(line);
      } else {
        break;
      }
    }

    return newBlock;
  };
};

var _default = sliceAtHeight;
exports["default"] = _default;