import { useIntl } from 'react-intl';
import ROLES from '../../data/roles.data';
import PlayerRole from '../../types/PlayerRole.type';
import DebriefingBaseTable from '../DebriefingBaseTable';

type Props = {
  entries: {
    teamID: string;
    DS: string;
    DF: string;
    DM: string;
    DP: string;
    PE: string;
    CA: string;
    teamVote: string;
  }[];
};

const BudgetAllocationVotesTable = (props: Props) => {
  const { formatMessage: f } = useIntl();

  const valueColumns = ['DS', 'DF', 'DM', 'DP', 'PE', 'CA'].map(
    (role: PlayerRole) => ({
      Header: f({
        id: `profiles:${ROLES[role]}.shortname`,
      }),
      accessor: role,
      Cell: props => (
        <div {...props} style={{ fontSize: '12px' }}>
          {props.value
            ? f({ id: `decisions:phase-2.option:${props.value}` })
            : '/'}
        </div>
      ),
    })
  );

  const teamLabelColumn = {
    Header: 'Team',
    accessor: 'teamID',
    Cell: props => (
      <div {...props} style={{ minWidth: '120px' }}>
        {props.value}
      </div>
    ),
  };

  const teamVoteColumn = {
    Header: 'Voto team',
    accessor: 'teamVote',
    Cell: props => (
      <div {...props} style={{ fontWeight: '500', fontSize: '14px' }}>
        {f({ id: `decisions:phase-2.option:${props.value}` })}
      </div>
    ),
  };

  return (
    <div>
      <DebriefingBaseTable
        columns={[teamLabelColumn, ...valueColumns, teamVoteColumn]}
        data={props.entries}
      />
    </div>
  );
};

export default BudgetAllocationVotesTable;
