import {
  DocumentData,
  Firestore,
} from 'firebase/firestore';
import { IssuedVoteDoc } from './apis/vote';
import { AvatarRecord, GameRecord, VoteRecord } from './interface';
import OriginalGamePhaseId from '../types/OriginalGamePhaseId.type';

import {
  doc,
  updateDoc,
  setDoc,
  collection,
  query,
  getDoc,
  getDocs,
  where,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  getFirestore,
} from 'firebase/firestore/lite';
import { app } from './firestore';

/*
  https://console.firebase.google.com/project/hospitalia-b7039/firestore/data/~2Fsessions
*/


const gameRecordId = (sessionId: string, gameId: string) =>
  `${sessionId}::${gameId}`;

const SESSIONS_COLLECTION = 'sessions';
const GAMES_COLLECTION = 'games';
const LOCATIONS_COLLECTION = 'locations';
const VOTES_COLLECTION = 'votes';
const votesCollection = (gameId: string) => `votes-${gameId}`;
const avatarsCollection = (gameRecordId: string) => `avatars-${gameRecordId}`;
const meetingRooms = (gameId: string) => `meetings-${gameId}`;

export const RemoteFactory = (db: Firestore, opts: any) => {

  const {
    doc,
    updateDoc,
    setDoc,
    collection,
    query,
    onSnapshot,
    getDoc,
    getDocs,
    where,
    deleteDoc,
    arrayUnion,
    arrayRemove,
  } = opts;

  return {
    setPlayers(gameId: string, players: string[]) {
      const ref = doc(db, GAMES_COLLECTION, gameId);
      return updateDoc(ref, { players });
    },

    openChat(gameId: string) {
      const ref = doc(db, GAMES_COLLECTION, gameId);
      return updateDoc(ref, {
        isChatOpen: true,
        isChatReadable: true,
      });
    },

    closeChat(gameId: string) {
      const ref = doc(db, GAMES_COLLECTION, gameId);
      return updateDoc(ref, { isChatOpen: false });
    },

    saveVote(
      gameId: string,
      id: string,
      issuedVote: IssuedVoteDoc
    ): Promise<void> {
      const ref = doc(db, votesCollection(gameId), id);
      return setDoc(ref, issuedVote);
    },

    setRoleMeetingRoom(gameId: string, role: string, meetingRoomURL: string) {
      return setDoc(doc(db, meetingRooms(gameId), `${gameId}.${role}`), {
        role,
        meetingRoomURL,
      });
    },

    createSession(sessionId: string, name: string, date: Date) {
      return setDoc(doc(db, SESSIONS_COLLECTION, sessionId), {
        id: sessionId,
        name,
        date,
        games: [],
      });
    },

    async deleteSession(sessionId: string) {
      // Delete votes.
      const votes = await getDocs(
        query(
          collection(db, VOTES_COLLECTION),
          where('sessionId', '==', sessionId)
        )
      );

      await Promise.all(
        votes.docs.map(vote => deleteDoc(doc(db, VOTES_COLLECTION, vote.id)))
      );

      // Delete locations.
      const locations = await getDocs(
        query(
          collection(db, LOCATIONS_COLLECTION),
          where('sessionId', '==', sessionId)
        )
      );

      await Promise.all(
        locations.docs.map(location =>
          deleteDoc(doc(db, LOCATIONS_COLLECTION, location.id))
        )
      );

      // Delete games.
      const games = await getDocs(
        query(
          collection(db, GAMES_COLLECTION),
          where('sessionId', '==', sessionId)
        )
      );

      await Promise.all(
        games.docs.map(game => deleteDoc(doc(db, GAMES_COLLECTION, game.id)))
      );

      // Delete the session.
      return deleteDoc(doc(db, SESSIONS_COLLECTION, sessionId));
    },

    async watchGameLocations(
      gameId: string,
      callback: (docs: DocumentData[]) => void
    ) {
      const locationsQuery = query(
        collection(db, LOCATIONS_COLLECTION),
        where('gameId', '==', gameId)
      );

      onSnapshot(locationsQuery, snapshot => {
        callback(snapshot.docChanges().map(change => change.doc.data()));
      });
    },

    async createGame(gameId: string, sessionId: string, jazzyId?: string) {
      const sessionQuery = await getDoc(doc(db, SESSIONS_COLLECTION, sessionId));
      const session = sessionQuery.data();

      // Create the game.
      await setDoc(doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)), {
        id: gameId,
        jazzyId,
        sessionId,
        isStarted: false,
        players: [],
        currentPhase: 'init',
        currentPhaseStartTime: Date.now(),
        isChatOpen: false,
        isChatReadable: false,
      });

      // Add the game to its parent session.
      await setDoc(doc(db, SESSIONS_COLLECTION, sessionId), {
        ...session,
        games: [...session.games, gameId],
      });
    },

    async setNickname(gameRecordId: string, roleId: string, nickname: string) {
      const gameReference = doc(db, GAMES_COLLECTION, gameRecordId);
      const game = (await getDoc(gameReference)).data() as GameRecord;

      const newNicknames = {
        ...(game.nicknames || {}),
        [roleId]: nickname,
      };

      await updateDoc(gameReference, { nicknames: newNicknames });
    },

    async getGamesBySessionId(sessionId: string): Promise<GameRecord[]> {
      const gamesDocuments = await getDocs(
        query(
          collection(db, GAMES_COLLECTION),
          where('sessionId', '==', sessionId)
        )
      );

      return gamesDocuments.docs.map(game => game.data()) as GameRecord[];
    },

    async getVotesBySessionId(sessionId: string): Promise<VoteRecord[]> {
      const votesDocuments = await getDocs(
        query(
          collection(db, VOTES_COLLECTION),
          where('sessionId', '==', sessionId)
        )
      );

      return votesDocuments.docs.map(doc => doc.data()) as VoteRecord[];
    },

    async getAvatars(gameRecordId: string): Promise<AvatarRecord[]> {
      const avatarsDocuments = await getDocs(
        query(collection(db, avatarsCollection(gameRecordId)))
      );

      return avatarsDocuments.docs.map(doc => doc.data()) as AvatarRecord[];
    },

    async watchGamesLocationsBySessionId(
      sessionId: string,
      callback: (docs: DocumentData[]) => void
    ) {
      onSnapshot(
        query(
          collection(db, LOCATIONS_COLLECTION),
          where('sessionId', '==', sessionId)
        ),
        snapshot => {
          callback(snapshot.docs.map(document => document.data()));
        }
      );
    },

    async watchGamesVotesBySessionId(
      sessionId: string,
      callback: (docs: DocumentData[]) => void
    ) {
      onSnapshot(
        query(
          collection(db, VOTES_COLLECTION),
          where('sessionId', '==', sessionId)
        ),
        snapshot => {
          callback(snapshot.docs.map(document => document.data()));
        }
      );
    },

    async getAllSessions() {
      const sessions = await getDocs(query(collection(db, SESSIONS_COLLECTION)));
      return sessions.docs.map(document => document.data());
    },

    async getSessionById(id: string) {
      const sessionDocument = await getDoc(
        doc(db, SESSIONS_COLLECTION, id)
      );
      return await sessionDocument.data();
    },

    async watchGamesBySessionId(
      sessionId: string,
      callback: (docs: DocumentData[]) => void
    ) {
      const gamesBySessionIdQuery = query(
        collection(db, GAMES_COLLECTION),
        where('sessionId', '==', sessionId)
      );

      onSnapshot(gamesBySessionIdQuery, snapshot => {
        callback(snapshot.docs.map(document => document.data()));
      });
    },

    async removeGame(gameId: string, sessionId: string) {
      const sessionDocument = await getDoc(
        doc(db, SESSIONS_COLLECTION, sessionId)
      );
      const session = await sessionDocument.data();

      await deleteDoc(doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)));
      await updateDoc(doc(db, SESSIONS_COLLECTION, sessionId), {
        ...session,
        games: session.games.filter((game: string) => game !== gameId),
      });
    },

    async addPlayerToGame(shortRole: string, sessionId: string, gameId: string, jazzyPlayerId?: string) {
      return await updateDoc(
        doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)),
        {
          players: arrayUnion(`${shortRole}.T1`),
          jazzyPlayers: arrayUnion(jazzyPlayerId || shortRole),
        }
      );
    },

    async removePlayerFromGame(
      shortRole: string,
      sessionId: string,
      gameId: string
    ) {
      return await updateDoc(
        doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)),
        {
          players: arrayRemove(`${shortRole}.T1`),
        }
      );
    },

    async setMeetingRoomInSession(sessionId: string, role: string, room: string) {
      const sessionDocument = await getDoc(
        doc(db, SESSIONS_COLLECTION, sessionId)
      );
      const session = await sessionDocument.data();

      await Promise.all(
        session.games.map(gameId =>
          updateDoc(doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)), {
            [`meetingRooms.${role}`]: room,
          })
        )
      );
    },

    async startAllGamesInSession(sessionId: string) {
      const sessionDocument = await getDoc(
        doc(db, SESSIONS_COLLECTION, sessionId)
      );
      const session = await sessionDocument.data();

      await Promise.all(
        session.games.map(gameId =>
          updateDoc(doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)), {
            isStarted: true,
            startTime: Date.now(),
          })
        )
      );
    },

    async toggleChatForAllGamesInSession(
      sessionId: string,
      setChatToOpen: boolean
    ) {
      const sessionDocument = await getDoc(
        doc(db, SESSIONS_COLLECTION, sessionId)
      );
      const session = await sessionDocument.data();

      await Promise.all(
        session.games.map(gameId =>
          updateDoc(doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)), {
            isChatOpen: setChatToOpen,
            isChatReadable: true,
          })
        )
      );
    },

    async advanceGamesInSessionToPhase(
      sessionId: string,
      toPhase: OriginalGamePhaseId
    ) {
      const sessionDocument = await getDoc(
        doc(db, SESSIONS_COLLECTION, sessionId)
      );
      const session = await sessionDocument.data();

      await Promise.all(
        session.games.map(gameId =>
          updateDoc(doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)), {
            currentPhase: toPhase,
            currentPhaseStartTime: Date.now(),
            isMeetingOn: false,
          })
        )
      );

      const dividerId = Math.ceil(Math.random() * Date.now()).toString(36);

      if (['phase-1', 'phase-2', 'phase-3'].includes(toPhase))
        await Promise.all(
          session.games.map(gameId =>
            setDoc(
              doc(db, `messages-${gameRecordId(sessionId, gameId)}`, dividerId),
              {
                channelId: gameRecordId(sessionId, gameId),
                content: toPhase,
                sender: '--divider--',
                timestamp: Date.now(),
                messageId: dividerId,
                viewers: ['--divider--'],
              }
            )
          )
        );
    },

    async startMeetingForGamesInSession(
      sessionId: string,
      meetingId: 'first-meeting' | 'second-meeting'
    ) {
      const sessionDocument = await getDoc(
        doc(db, SESSIONS_COLLECTION, sessionId)
      );
      const session = await sessionDocument.data();

      await Promise.all(
        session.games.map(gameId =>
          updateDoc(doc(db, GAMES_COLLECTION, gameRecordId(sessionId, gameId)), {
            currentPhase: meetingId,
            // NOTE: This is set by the players.
            // currentMeeting:
            // meetingId === 'first-meeting' ? 'first-leg' : 'second-leg',
            isMeetingOn: true,
          })
        )
      );
    },
  }
};

export const LiteRemote = RemoteFactory(getFirestore(app), {
  doc,
  updateDoc,
  setDoc,
  collection,
  query,
  getDoc,
  getDocs,
  where,
  deleteDoc,
  arrayUnion,
  arrayRemove,
})

