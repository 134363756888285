import { useIntl } from 'react-intl';
import DebriefingBaseTable from '../DebriefingBaseTable';

const ReachedGoalBadge = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="bg-green-400 w-8 h-8 text-center rounded-full flex justify-center rerlative items-center select-none m-[auto_10px]">
      <img src={f({ id: 'icon:check' })} width="20" height="20" alt="V" />
    </div>
  );
};

const NotReachedGoalBadge = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="bg-red-400 w-8 h-8 text-center rounded-full flex justify-center rerlative items-center select-none m-[auto_10px]">
      <img src={f({ id: 'icon:cross' })} width="14" height="14" alt="X" />
    </div>
  );
};

type Props = {
  entries: {
    teamID: string;
    ReduceTotalDrugsCosts: boolean;
    ReduceUnexpectedEventsRelatedCosts: boolean;
    IncreaseTotalCompliance: boolean;
  }[];
};

const TeamGoalsTable = (props: Props) => {
  const { formatMessage: f } = useIntl();

  const columns = [
    {
      Header: 'Team',
      accessor: 'teamID',
      Cell: props => (
        <div {...props} style={{ minWidth: '240px' }}>
          {props.value}
        </div>
      ),
    },
    {
      Header: f({
        id: 'team-goals:ReduceTotalDrugsCosts.title',
      }),
      accessor: 'ReduceTotalDrugsCosts',
      Cell: props => (
        <div
          style={{ display: 'flex', width: '12vw', justifyContent: 'center' }}
          {...props}
        >
          {props.value ? <ReachedGoalBadge /> : <NotReachedGoalBadge />}
        </div>
      ),
    },
    {
      Header: f({
        id: 'team-goals:ReduceUnexpectedEventsRelatedCosts.title',
      }),
      accessor: 'ReduceUnexpectedEventsRelatedCosts',
      Cell: props => (
        <div
          style={{ display: 'flex', width: '12vw', justifyContent: 'center' }}
          {...props}
        >
          {props.value ? <ReachedGoalBadge /> : <NotReachedGoalBadge />}
        </div>
      ),
    },
    {
      Header: f({
        id: 'team-goals:IncreaseTotalCompliance.title',
      }),
      accessor: 'IncreaseTotalCompliance',
      Cell: props => (
        <div
          style={{ display: 'flex', width: '12vw', justifyContent: 'center' }}
          {...props}
        >
          {props.value ? <ReachedGoalBadge /> : <NotReachedGoalBadge />}
        </div>
      ),
    },
  ];

  return (
    <div>
      <DebriefingBaseTable columns={columns} data={props.entries} />
    </div>
  );
};

export default TeamGoalsTable;
