import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import { GREEN, GREEN_BLUE, RED } from '../colors';
import { CONTENT1 } from '../contentSizes';
import ReportContext from '../ReportContext';


const TeamResults = ({ kpis }) => {
  const { dictionary } = useContext(ReportContext);

  function getTeamResultsImage(correct) {
    switch (correct) {
      case true:
        return 'positive'
      default:
        return 'negative'
    }

  }

  function getTeamResultsBox(correct) {
    switch (correct) {
      case true:
        return 'positiveBox'
      default:
        return 'negativeBox'
    }

  }

  function getStyleByResult(correct) {
    var additionalStyle = {}

    switch (correct) {
      case true:
        additionalStyle = {
          color: GREEN
        }
        break;
      default:
        additionalStyle = {
          color: RED
        }
        break;
    }

    return additionalStyle

  }


  return (
    <Page style={{
      position: 'relative', fontFamily: 'RubikRegular',
    }}>
      <Header />
      <View style={{
        position: 'relative',
        margin: 'auto',
        marginTop: 0,
        width: CONTENT1,
      }}>
        <Text style={{
          textAlign: 'center',
          marginBottom: 51,
          color: GREEN_BLUE,
          fontSize: 26,
          fontFamily: 'RubikBold',
          letterSpacing: 0,
          lineHeight: '31px',
        }}>
          {dictionary['TeamResults']}
        </Text>
        <View style={{
          display: 'flex',
          width: '100%',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: 'auto',
          flexWrap: 'wrap',
        }}>
          {
            kpis.map((kpi, index) => {

              const { id, value, correct } = kpi

              const additionalTextStyle = getStyleByResult(correct)
              return (
                <View style={{
                  width: 212,
                  fontSize: 22,
                  letterSpacing: 0,
                  lineHeight: '26px',
                  alignItems: 'center',
                }} key={id}>
                  <Image name={getTeamResultsImage(correct)} style={{
                    width: '103px',
                    height: '103px',
                  }} />
                  <Text style={{
                    height: '78px',
                    fontSize: 22,
                    letterSpacing: 0,
                    lineHeight: '26px',
                    marginTop: '13px',
                    marginBottom: '46px',
                    width: 200,
                    textAlign: 'center',
                  }}>{dictionary[id]}</Text>
                  <View style={{
                    position: 'relative',
                    width: '212px'
                  }}>
                    <Image style={{
                      width: '212px'
                    }} name={getTeamResultsBox(correct)} />
                    <Text style={{
                      position: 'absolute',
                      left: "0",
                      top: "40px",
                      fontSize: 26,
                      fontFamily: 'RubikBold',
                      letterSpacing: 0,
                      lineHeight: '31px',
                      textAlign: 'center',
                      ...additionalTextStyle
                    }}>{value}</Text>
                  </View>
                </View>
              )
            })
          }
        </View>
      </View>
    </Page>
  );
};

export default TeamResults;
