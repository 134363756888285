import { useIntl } from 'react-intl';
import SessionsManagementService from '../services/SessionsManagementService';
import IconButton from './icon-button';

interface IProps {
  teams: {
    id: string;
    players: string[];
    isStarted: boolean;
    sessionId: string;
  }[];
}

const Teams = ({ teams }: IProps) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="max-h-[290px] overflow-y-auto pt-12 list-none space-y-2 flex-grow custom-scrollbar">
      {teams?.map(game => (
        <li key={game.id} className="flex items-center justify-between">
          <div className="flex items-center w-full px-6 py-4 text-sm font-bold rounded-md bg-slate-100/60 text-slate-600">
            {game.id}
          </div>
          <div
            style={{
              alignSelf: 'center',
              padding: '0 40px',
              gridColumn: 2,
            }}
          >
            {!game.isStarted && (
              <IconButton
                image={f({ id: 'teacher:icons.delete' })}
                label="Elimina"
                onClick={() =>
                  SessionsManagementService.removeGameFromSession(
                    game.id,
                    game.sessionId
                  )
                }
                color="#ff0000"
              />
            )}
          </div>
        </li>
      ))}
    </div>
  );
};

export default Teams;
