"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports["default"] = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _getFactors = _interopRequireDefault(require("./getFactors"));

var _getDistances = _interopRequireDefault(require("./getDistances"));

var _advanceWidth = _interopRequireDefault(require("../../attributedString/advanceWidth"));

/**
 * Adjust run positions by given distances
 *
 * @param {Array} distances
 * @param {Object} line
 * @returns {Object} line
 */
var justifyLine = function justifyLine(distances, line) {
  var index = 0;

  for (var _iterator = line.runs, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var run = _ref;

    for (var _iterator2 = run.positions, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
      var _ref2;

      if (_isArray2) {
        if (_i2 >= _iterator2.length) break;
        _ref2 = _iterator2[_i2++];
      } else {
        _i2 = _iterator2.next();
        if (_i2.done) break;
        _ref2 = _i2.value;
      }

      var position = _ref2;
      position.xAdvance += distances[index++];
    }
  }

  return line;
};
/**
 * A JustificationEngine is used by a Typesetter to perform line fragment
 * justification. This implementation is based on a description of Apple's
 * justification algorithm from a PDF in the Apple Font Tools package.
 *
 * //TODO: Make it immutable
 *
 * @param {Object} layout options
 * @param {Object} line
 * @returns {Object} line
 */


var justification = function justification(options, line) {
  var gap = line.box.width - (0, _advanceWidth["default"])(line);
  if (gap === 0) return; // Exact fit

  var factors = (0, _getFactors["default"])(gap, line, options);
  var distances = (0, _getDistances["default"])(gap, factors);
  return justifyLine(distances, line);
};

var _default = R.curryN(2, justification);

exports["default"] = _default;