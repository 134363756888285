import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import { PURPLE } from '../colors';
import { CONTENT2 } from '../contentSizes';
import ReportContext from '../ReportContext';


const ExtraOrdinaryText = ({ teamGoals }) => {
  const { dictionary } = useContext(ReportContext);


  return (
    <Page style={{
      position: 'relative', fontFamily: 'RubikRegular',
    }}>
      <Header />
      <View style={{
        width: CONTENT2,
        margin: 'auto',
        marginTop: 0,
      }}>
        <View style={{
          textAlign: 'center'
        }}>
          <Text style={{
            color: PURPLE,
            fontSize: 26,
            fontFamily: 'RubikBold',
            letterSpacing: 0,
            lineHeight: '31px',
          }}>{dictionary['ExtraordinaryEventTextTitle']}</Text>
          <Text style={{
            color: PURPLE,
            fontSize: 18,
            letterSpacing: 0,
            lineHeight: '22px',
          }}>{dictionary['ExtraordinaryEventTextSubtitle']}</Text>
          <Text style={{
            fontSize: 22,
            letterSpacing: 0,
            lineHeight: '28px',
            marginTop: '23px'
          }}>
            {dictionary['ExtraordinaryEventTextContent']}
          </Text>

        </View>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: 'auto',
          marginTop: '20px',
          width: '100%'
        }}>
          {
            teamGoals.map((goal, index) => {
              return (
                <View key={index} style={{
                  width: 250,
                  justifyContent: 'center',
                }}>
                  <View style={{
                    height: 180
                  }}>
                    <Image name={`${goal}`} style={{
                      width: 155,
                      height: 155,
                      alignSelf: 'center',
                    }} />
                  </View>
                  <View style={{
                    height: 100,
                    alignItems: 'flex-start'
                  }}>
                    <Text style={{
                      fontSize: 20,
                      letterSpacing: 0,
                      lineHeight: '26px',
                      textAlign: 'center'
                    }}>
                      {dictionary[`${goal}`]}
                    </Text>
                  </View>
                </View>
              )
            })
          }
        </View>
      </View>
    </Page>
  );
};

export default ExtraOrdinaryText;
