import { DARK_CALCITE } from '../../colors'


export default function (position: number, lenght: number) {

  var additionalStyles = {}

  if (position === 0) {
    additionalStyles = {
      borderTop: `1pt solid ${DARK_CALCITE}`,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,

    }
  } else {
    if (position === lenght - 1) {
      additionalStyles = {

        borderBottom: `1pt solid ${DARK_CALCITE}`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
      }
    }
  }


  return additionalStyles
}
