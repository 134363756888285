import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import SessionsManagementService from '../../services/SessionsManagementService';
import { Header, InnerContent, MainLayout, OuterContent } from '../main-layout';
import NavigationBar from '../navigation-bar';
import Button from './Button';
import SessionsPage from './SessionsPage';
import SessionCreationModal from './SessionCreationModal';
import { useIntl } from 'react-intl';
import Spinner from '../Spinner';
import { Session } from '../../remote/interface';

const SessionsManagement = () => {
  const { formatMessage } = useIntl();

  const [isCreationModalOpen, setIsCreationModalOpen] =
    useState<boolean>(false);

  const [sessions, setSessions] = useState<{
    future: Session[];
    past: Session[];
    futureSessionsCount: number;
    pastSessionsCount: number;
  }>();
  const [futurePage, setFuturePage] = useState<number>(0);
  const [pastPage, setPastPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>();

  useEffect(() => {
    async function init() {
      const data = await SessionsManagementService.getDisplayedSessions({
        futurePage,
        pastPage,
        searchTerm,
      });

      setSessions(data);
    }

    init();
  }, [futurePage, pastPage, searchTerm]);

  if (!sessions)
    return <Spinner size="256px" style={{ margin: '25vh auto' }} />;

  return (
    <MainLayout>
      <Header />

      <OuterContent>
        <NavigationBar />

        <InnerContent>
          <div className="flex flex-row justify-between mb-20">
            <Button
              label={
                isCreationModalOpen
                  ? 'Annulla creazione'
                  : formatMessage({ id: 'teacher:sessions.create-button' })
              }
              handleClick={() => setIsCreationModalOpen(_ => !_)}
            />

            <div>
              <input
                type="text"
                className="w-64 h-full px-6 m-auto rounded-full -translate-y-[1px] outline-none text-slate-900 bg-white border-2 border-slate-200"
                onChange={({ target: { value } }) => setSearchTerm(value)}
                placeholder="Cerca sessione"
              />
            </div>
          </div>

          {isCreationModalOpen ? (
            <SessionCreationModal
              setIsCreationModalOpen={setIsCreationModalOpen}
            />
          ) : (
            <>
              {sessions.future?.length > 0 && (
                <SessionsPage
                  page={futurePage}
                  pages={Math.ceil(
                    sessions.futureSessionsCount /
                      SessionsManagementService.pageSize
                  )}
                  setPage={setFuturePage}
                  pageType="Sessioni future"
                  sessions={sessions.future}
                />
              )}
              <br />
              <br />
              {sessions.past?.length > 0 && (
                <SessionsPage
                  page={pastPage}
                  pages={Math.ceil(
                    sessions.pastSessionsCount /
                      SessionsManagementService.pageSize
                  )}
                  setPage={setPastPage}
                  pageType="Sessioni passate"
                  sessions={sessions.past}
                />
              )}
            </>
          )}
        </InnerContent>
      </OuterContent>
    </MainLayout>
  );
};

export default SessionsManagement;
