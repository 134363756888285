import { Switch, Route, useRoute } from 'wouter';
import TeamsGoalsSection from '../comps/sections/TeamsGoalsSection';
import BudgetAllocationSection from '../comps/sections/BudgetAllocationSection';
import BudgetAmountSection from '../comps/sections/BudgetAmountSection';
import ChoicesRecapSection from '../comps/sections/ChoicesRecapSection';
import FinalVoteSection from '../comps/sections/FinalVoteSection';
import NextYearDebrief from '../comps/sections/NextYearSection';
import PlayersGoalsSection from '../comps/sections/PlayersGoalsSection';
import SatisfactionAndRepresentationSection from '../comps/sections/SatisfactionAndRepresentationSection';
import ReportsSection from '../comps/sections/ReportsSection';
import { NestedRoutes } from '../comps/utils/nested-routes';
import DebriefingNavigationBar from '../comps/DebriefingNavigationBar';
import { useLayoutEffect } from 'react';
import { useDebriefing } from '../stores/game/factories/debriefing';

const Debriefing = () => {
  const { initialize: initializeDebriefing, viewModel } = useDebriefing(
    ({ initialize, viewModel }) => ({ initialize, viewModel })
  );
  const [, parameters] = useRoute<{ sessionId: string }>(
    '/sessions/:sessionId/:rest*'
  );

  const sessionId: string = parameters?.sessionId;

  useLayoutEffect(() => {
    if (!parameters?.sessionId) return;

    async function init() {
      initializeDebriefing(parameters.sessionId);
    }

    init();
  }, [parameters?.sessionId]);

  if (!viewModel) return <p>Loading...</p>;

  return (
    <>
      <DebriefingNavigationBar basePath={`/sessions/${sessionId}/debriefing`} />
      <NestedRoutes base={`/sessions/${sessionId}/debriefing`}>
        <Switch>
          <Route path="/teams-goals" component={TeamsGoalsSection} />
          <Route path="/choices-recap" component={ChoicesRecapSection} />
          <Route path="/budget-amount" component={BudgetAmountSection} />
          <Route
            path="/budget-allocation"
            component={BudgetAllocationSection}
          />
          <Route path="/final-vote" component={FinalVoteSection} />
          <Route
            path="/satisfaction-and-representation"
            component={SatisfactionAndRepresentationSection}
          />
          <Route path="/players-goals" component={PlayersGoalsSection} />
          <Route path="/next-year" component={NextYearDebrief} />
          <Route path="/report" component={ReportsSection} />
        </Switch>
      </NestedRoutes>
    </>
  );
};

export default Debriefing;
