import { Route, Switch } from 'wouter';
import {
  Header,
  InnerContent,
  MainLayout,
  OuterContent,
} from '../comps/main-layout';
import LiveScreen from '../comps/monitoring/LiveScreen';
import NavigationBar from '../comps/navigation-bar';
import Debriefing from './debriefing';
import Setup from './setup';

const Session = () => (
  <MainLayout>
    <Header />

    <OuterContent>
      <NavigationBar />

      <InnerContent>
        <Switch>
          <Route
            path="/sessions/:sessionId/mission-control/setup"
            component={Setup}
          />
          <Route
            path="/sessions/:sessionId/mission-control/live"
            component={LiveScreen}
          />
          <Route
            path="/sessions/:sessionId/debriefing/:rest*"
            component={Debriefing}
          />
        </Switch>
      </InnerContent>
    </OuterContent>
  </MainLayout>
);

export default Session;
