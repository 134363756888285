import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import DebriefingBaseSelect from '../DebriefingBaseSelect';
import DebriefingNoResultsPlaceholder from '../DebriefingNoResultsPlaceholder';
import SatisfactionAndRepresentationVotesTable from '../plots/SatisfactionAndRepresentationVotesTable';

type Entry = {
  teamID: string;
  satisfactionAvg: number;
};

const SatisfactionAndRepresentationSection = () => {
  const { formatMessage: f } = useIntl();
  const [teamsFilters, setTeamsFilters] = useState<string[]>([]);
  const [entries, setEntries] = useState<Entry[]>([]);

  const viewModel = useDebriefing(({ viewModel }) => viewModel);

  useEffect(
    () =>
      viewModel?.teams &&
      setEntries(
        viewModel.teams
          .filter(
            teamId => !teamsFilters.length || teamsFilters.includes(teamId)
          )
          .map(teamId => ({
            teamID: teamId,
            satisfactionAvg:
              (viewModel.teamSatisfaction(teamId) +
                viewModel.teamRepresentation(teamId)) /
              2,
          }))
      ),
    [viewModel, teamsFilters]
  );

  if (!viewModel) return <p>Loading...</p>;

  return (
    <div>
      <div style={{ maxWidth: '420px' }}>
        <DebriefingBaseSelect
          isMulti
          label="Team"
          placeholder={f({ id: 'teacher.debriefing.teams.all' })}
          options={viewModel.teams.map(teamId => ({
            label: teamId,
            value: teamId,
          }))}
          handleChange={newTeamsFilters =>
            setTeamsFilters(newTeamsFilters.map(({ value }) => value))
          }
        />
      </div>

      <span className="block w-full h-12" />

      {entries.length ? (
        <SatisfactionAndRepresentationVotesTable entries={entries} />
      ) : (
        <DebriefingNoResultsPlaceholder />
      )}
    </div>
  );
};

export default SatisfactionAndRepresentationSection;
