import { useEffect, useState } from 'react';
import Accordion from '../comps/accordion';
import AddTeam from '../comps/add-team';
import ShadowedCard from '../comps/shadowed-card';
import TeamsDetailsList from '../comps/teams-details-list';
import TeamsSetupList from '../comps/teams-setup-list';
import ROLES from '../data/roles.data';
import { GameRecord } from '../remote/interface';
import SessionsManagementService from '../services/SessionsManagementService';

interface IProps {
  params: {
    sessionId: string;
  };
}

const Setup = ({ params }: IProps) => {
  const [games, setGames] = useState<GameRecord[]>([]);

  useEffect(() => {
    if (!params?.sessionId) return;

    async function init() {
      await SessionsManagementService.watchGamesBySessionId(
        params.sessionId,
        setGames
      );
    }

    init();
  }, [params?.sessionId]);

  const filteredGames = games.filter(({ id }) => id).filter(Boolean);

  if (!params.sessionId) return <p>Loading...</p>;

  return (
    <div className="flex items-start h-full space-x-4">
      <ShadowedCard className="w-1/3 h-4/5">
        <Title>Setup</Title>
        <AddTeam
          teams={filteredGames.map(({ id }) => id)}
          isDisabled={filteredGames?.some(({ isStarted }) => isStarted)}
        />
        <TeamsSetupList teams={filteredGames} />
      </ShadowedCard>

      <ShadowedCard className="w-2/3 overflow-y-auto h-4/5 custom-scrollbar">
        <Title>Teams</Title>
        <br />
        <TeamsDetailsList teams={filteredGames} sessionId={params.sessionId} />
        <hr className="my-4" />
        <Title>Meeting Rooms</Title>
        {Object.keys(ROLES)
          .filter(roleShortname =>
            filteredGames?.some(({ players }) =>
              players.some(p => p.split('.')[0] === roleShortname)
            )
          )
          .map(roleShortname => (
            <div
              className="my-2 flex items-center space-x-4"
              key={roleShortname}
            >
              <span className="w-[50px]">{roleShortname}:</span>
              <input
                className="w-[300px] p-3 text-sm border-2 rounded-md peer border-slate-400"
                placeholder="room.."
                value={
                  filteredGames[0]?.meetingRooms &&
                  filteredGames[0]?.meetingRooms[roleShortname]
                }
                onChange={e =>
                  SessionsManagementService.setMeetingRoomInSession(
                    params.sessionId,
                    roleShortname,
                    e.target.value
                  )
                }
              />
            </div>
          ))}
      </ShadowedCard>
    </div>
  );
};

export default Setup;

const Title = ({ children }) => (
  <h2 className="font-bold text-slate-800 uppercase text-lg mt-4 font-['Open_Sans']">
    {children}
  </h2>
);
