interface IProps {
  label: string;
  handleClick?: () => void;
  isDisabled?: boolean;
}

const Button = ({ label, handleClick, isDisabled }: IProps) => (
  <button
    onClick={handleClick}
    disabled={isDisabled}
    className="z-10 px-4 py-1 text-sm font-bold leading-none text-white uppercase transition-all duration-200 ease-in-out border-none rounded-full shadow outline-none cursor-pointer xl:py-2 2xl:text-lg xl:text-base xl:px-6 2xl:px-8 disabled:bg-slate-200 bg-hospitaliaTeal-base hover:bg-hospitaliaTeal-light active:bg-hospitaliaTeal-dark"
  >
    {label}
  </button>
);

export default Button;
