import { CALCITE, WHITE } from '../../colors'


export default function (position: number) {

  var backgroundColor = WHITE

  switch (position % 2) {
    case 1:
      backgroundColor = CALCITE

  }

  return backgroundColor
}
