import { useIntl } from 'react-intl';
import LOCATIONS from '../data/locations.data';
import SessionsManagementService from '../services/SessionsManagementService';
import { useMonitoring } from '../stores/game/factories/monitoring';
import Spinner from './Spinner';

interface IProps {
  phase: keyof typeof LOCATIONS;
}

const MeetingActivationBox = ({ phase }: IProps) => {
  const { formatMessage } = useIntl();
  const viewModel = useMonitoring(({ viewModel }) => viewModel);

  if (!viewModel?.games) return <Spinner size="32px" />;

  return (
    <div className="flex flex-col items-center justify-center w-full h-full px-4 pt-4 mr-4 space-y-2">
      <button
        disabled={!viewModel.gamesCanStartChatForPhase(phase)}
        onClick={() =>
          SessionsManagementService.openChatForAllGamesInSession(
            viewModel.sessionId
          )
        }
        className="px-6 py-2 text-xs font-bold leading-none text-white uppercase transition-all duration-500 ease-in-out rounded-full bg-emerald-600 disabled:cursor-not-allowed disabled:bg-slate-200 hover:duration-100 hover:bg-emerald-500"
      >
        Apri fase chat
      </button>
      <button
        disabled={!viewModel.gamesCanStartMeetingForPhase(phase)}
        onClick={() =>
          SessionsManagementService.startMeetingForGamesInSession(
            viewModel.sessionId,
            phase
          )
        }
        className="px-6 py-2 text-xs font-bold leading-none text-white uppercase transition-all duration-500 ease-in-out rounded-full bg-cyan-700 disabled:cursor-not-allowed disabled:bg-slate-200 hover:duration-100 hover:bg-cyan-600"
      >
        {formatMessage({ id: 'teacher:meeting.open-meeting' })}
      </button>
    </div>
  );
};

export default MeetingActivationBox;
