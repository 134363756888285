import React from 'react';
import { Image as PDFImage } from '@react-pdf/renderer';
import images from './images';

interface Props {
  name?: string;
  style?: object;
}

const Image: React.FC<Props> = ({ name, style }) => {
  return <PDFImage src={images(name)} style={style ? style : {}} />;
};

export default Image;
