import { CSSProperties } from 'react';

interface IProps {
  size: CSSProperties['width'];
  style?: CSSProperties;
}

const Spinner = ({ size, style }: IProps) => (
  <div
    className="w-full h-full my-auto bg-transparent border-2 rounded-full aspect-square animate-spin border-y-hospitaliaBlue-base border-l-hospitaliaBlue-base border-r-transparent"
    style={{
      width: size,
      height: size,
      ...(style || {}),
    }}
  />
);

export default Spinner;
