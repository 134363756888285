import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyA_erHMJ_aVTJwr7BIwsQ8ZMZ4LytHuqjY',
  authDomain: 'hospitalia-b7039.firebaseapp.com',
  projectId: 'hospitalia-b7039',
  storageBucket: 'hospitalia-b7039.appspot.com',
  messagingSenderId: '449783732368',
  appId: '1:449783732368:web:095a6b2f01a9eaf1cce015',
};

export const app = initializeApp(firebaseConfig);
