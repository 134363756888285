"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

exports.__esModule = true;
exports["default"] = void 0;

var R = _interopRequireWildcard(require("ramda"));

var _length = _interopRequireDefault(require("../run/length"));

var _advanceWidth = _interopRequireDefault(require("../run/advanceWidth"));

var _indexAtOffset = _interopRequireDefault(require("../run/indexAtOffset"));

/**
 * Get string index at offset
 *
 * @param  {Object}  attributed string
 * @param  {number}  offset
 * @return {number} string index at offset N
 */
var indexAtOffset = function indexAtOffset(offset, string) {
  var index = 0;
  var counter = 0;
  var runs = R.propOr([], 'runs', string);

  for (var _iterator = runs, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var run = _ref;
    var advanceWidth = (0, _advanceWidth["default"])(run);

    if (counter + advanceWidth > offset) {
      return index + (0, _indexAtOffset["default"])(offset - counter, run);
    }

    counter += advanceWidth;
    index += (0, _length["default"])(run);
  }

  return index;
};

var _default = R.curryN(2, indexAtOffset);

exports["default"] = _default;