import clsx from 'clsx';
import { useState } from 'react';
import LOCATIONS from '../../data/locations.data';
import useInterval from '../../hooks/useInterval.hook';
import { useMonitoring } from '../../stores/game/factories/monitoring';
import formatTimeDifference from '../../utils/format-time-difference';

interface IProps {
  currentPhase: keyof typeof LOCATIONS;
}

const Timer = ({ currentPhase }: IProps) => {
  const viewModel = useMonitoring(({ viewModel }) => viewModel);

  const [remainingTime, setRemainingTime] = useState<number>(0);

  useInterval(
    () =>
      setRemainingTime(
        // If the current session's phase is equivalent to the monitoring phase
        // we're currently on.
        viewModel.gamePhaseToDebriefingPhase(viewModel.currentPhase) ===
          currentPhase
          ? viewModel.remainingPhaseTime
          : undefined
      ),
    100
  );

  return remainingTime ? (
    <p
      title={currentPhase}
      className={clsx(
        'absolute left-0 right-0 w-full mx-auto font-semibold text-center uppercase top-8',
        remainingTime > 0 ? 'text-slate-400' : 'text-red-400'
      )}
    >
      Timer: {remainingTime > 0 ? '-' : '+'}
      {formatTimeDifference(remainingTime)}
    </p>
  ) : null;
};

export default Timer;
