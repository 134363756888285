import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import DebriefingBaseSelect from '../DebriefingBaseSelect';
import DebriefingNoResultsPlaceholder from '../DebriefingNoResultsPlaceholder';
import FinalVotesTable from '../plots/FinalVotesTable';

const decisions = [
  {
    label: 'pages:decisions-tender.statement',
    value: 'desertTendersCostsDecision',
  },
  { label: 'pages:decisions-diagnosis.statement', value: 'diagnosisDecision' },
  {
    label: 'pages:decisions-distribution.statement',
    value: 'directDistributionDecision',
  },
];

export type FinalVoteSectionEntry = {
  teamID: string;
  desertTendersCostsDecision?: string;
  desertTendersCostsMotivation?: string;
  directDistributionDecision?: string;
  directDistributionMotivation?: string;
  diagnosisDecision?: string;
  diagnosisMotivation?: string;
};

const FinalVoteSection = () => {
  const { formatMessage: f } = useIntl();
  const [teamsFilters, setTeamsFilters] = useState<string[]>([]);
  const [decisionsFilters, setDecisionsFilters] = useState<string[]>([]);
  const [entries, setEntries] = useState<FinalVoteSectionEntry[]>([]);

  const viewModel = useDebriefing(({ viewModel }) => viewModel);

  useEffect(() => {
    if (!viewModel?.teams) return;

    setEntries(
      viewModel.teams
        .filter(teamId => !teamsFilters.length || teamsFilters.includes(teamId))
        .map(teamId => ({
          teamID: teamId,
          desertTendersCostsDecision:
            viewModel.teamDesertTenderCostsDecision(teamId),
          desertTendersCostsMotivation:
            viewModel.teamDesertTenderCostsMotivation(teamId),
          directDistributionDecision:
            viewModel.teamDirectDistributionDecision(teamId),
          directDistributionMotivation:
            viewModel.teamDirectDistributionMotivation(teamId),
          diagnosisDecision: viewModel.teamDiagnosisDecision(teamId),
          diagnosisMotivation: viewModel.teamDiagnosisMotivation(teamId),
        }))
    );
  }, [viewModel, teamsFilters]);

  if (!viewModel) return <p>Loading...</p>;

  return (
    <div>
      <div className="flex flex-row">
        <DebriefingBaseSelect
          label="Team"
          isMulti
          placeholder={f({ id: 'teacher.debriefing.teams.all' })}
          options={viewModel.teams.map(teamId => ({
            label: teamId,
            value: teamId,
          }))}
          handleChange={newTeamsFilters =>
            setTeamsFilters(newTeamsFilters.map(({ value }) => value))
          }
        />
        <DebriefingBaseSelect
          label="Decisioni"
          isMulti
          placeholder="Tutte"
          options={decisions.map(decision => ({
            label: f({ id: decision.label }),
            value: decision.value,
          }))}
          handleChange={newDecisionsFilters =>
            setDecisionsFilters(newDecisionsFilters.map(({ value }) => value))
          }
        />
      </div>

      <span className="block w-full h-24" />

      {entries.length ? (
        <FinalVotesTable
          entries={entries}
          decisions={
            !decisionsFilters.length
              ? decisions.map(({ value }) => value)
              : decisionsFilters
          }
        />
      ) : (
        <DebriefingNoResultsPlaceholder />
      )}
    </div>
  );
};

export default FinalVoteSection;
