import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Page from '../blocks/Page';
import { PURPLE } from '../colors';
import { CONTENT2 } from '../contentSizes';
import ReportContext from '../ReportContext';
import ExtraordinaryEventTable from '../blocks/ExtraordinaryEventTable';


const extraordinaryEventTable = ({ extraordinaryEventData }) => {
  const { dictionary } = useContext(ReportContext);

  const tableStyles = [
    {
      fontSize: 18,
      letterSpacing: 0,
      lineHeight: '22px',
      fontFamily: 'RubikRegular',
      alignSelf: 'flex-start',
    },
    {
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '18px',
      fontFamily: 'RubikRegular',
      alignSelf: 'flex-start',

    },
    {
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '18px',
      fontFamily: 'RubikRegular',
      alignSelf: 'flex-start',

    },
  ]


  return (
    <Page style={{
      position: 'relative', fontFamily: 'RubikRegular',
    }}>
      <Header />
      <View>
        <Text style={{
          color: PURPLE,
          fontSize: 26,
          fontFamily: 'RubikBold',
          letterSpacing: 0,
          lineHeight: '31px',
          textAlign: 'center',
        }}>
          {dictionary['ExtraordinaryEventTextTitle']}
        </Text>
        <View style={{
          flex: 1,
          width: CONTENT2,
          margin: 'auto',
          marginTop: '18px'
        }}>
          <ExtraordinaryEventTable tableID={'extraordinaryEvent'} elements={extraordinaryEventData} tableStyles={tableStyles} />
        </View>
      </View>
    </Page >
  );
};

export default extraordinaryEventTable;
