import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';

export const render = (host: HTMLElement) =>
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    host
  );
