export default {
  reportTeam: 'Report del Team',
  YourTeamTitle: 'IL TUO TEAM',
  DirettoreSanitario: 'Direttore Sanitario',
  DirettoreFarmaciaOspedaliera: 'Direttore Farmacia Ospedaliera',
  DirettoreDipartimentoMedicinaInterna:
    'Direttore Dipartimento Medicina Interna',
  DirettoreUOCPneumologia: 'Direttore UOC Pneumologia',
  ProvveditoreEconomo: 'Provveditore Economo',
  ClinicoReferenteFederAsma: 'Clinico Referente FederAsma',

  DirettoreSanitarioName: 'Dr. Tramontana',
  DirettoreFarmaciaOspedalieraName: 'Dr. Matraccio',
  DirettoreDipartimentoMedicinaInternaName: 'Dr. Sofa',
  DirettoreUOCPneumologiaName: 'Dr. Dell’Aria',
  ProvveditoreEconomoName: 'Dr. Hardy-Scount',
  ClinicoReferenteFederAsmaName: 'Dr. Ventolini',

  YouAreOpenFeedbackTitle: 'You are open',
  MeMyselfAndIFeedbackTitle: 'My Myself and I',
  feedbackYouAreOpen:
    'Il vostro team dimostra <b>apertura verso il coinvolgimento strutturato di stakeholder esterni all’azienda</b> e su cui l’azienda non ha diretto potere gestionale e decisionale.',
  feedbackMeMyselfAndI:
    'Il vostro team ha deciso di focalizzare la propria attenzione su una <b>revisione delle procedure interne all’azienda</b> e di attivare iniziative su cui ha un diretto potere gestionale e decisionale',
  TeamResults: 'RISULTATI DEL TEAM',
  ReduceTotalDrugsCosts: 'Ridurre la spesa per farmaci dell’ospedale',
  ReduceUnexpectedEventsRelatedCosts:
    'Ridurre la spesa per la gestione degli eventi avversi',
  IncreaseTotalCompliance: 'Aumentare la compliance',

  TeamChoicesValueTitle: '1. DEFINIZIONE DELLE RISORSE',
  TeamChoicesValueSubtitle:
    'Di quanto faresti variare le risorse a disposizione dell’asma grave per il prossimo anno?',
  TeamChoicesValuePlaceholder:
    'Il tuo team ha deciso di applicare per il prossimo anno una variazione del budget sulla spesa dei farmaci per il trattamento dell’asma grave pari a',
  TeamChoicesAnswerTitle: '2. ALLOCAZIONE DELLE RISORSE E SCELTE TERAPEUTICHE',
  TeamChoicesAnswerSubtitle:
    'Come penseresti di fare variare o rimodulare l’adozione di farmaci biologici per il prossimo anno?',
  TeamChoicesAnswerPlaceholder: 'Il tuo team ha deciso di',

  ExtraordinaryEventTextTitle: '3. GESTIONE EVENTO STRAORDINARIO',
  ExtraordinaryEventTextSubtitle:
    'Sulla base dell’assemblea straordinaria che hai avuto con i membri del team, quali delle seguenti scelte vorresti perseguire?',
  ExtraordinaryEventTextContent:
    'Il report regionale che avete ricevuto ha individuato i seguenti punti di attenzione:',

  header1nextYearChoices: '',
  header2nextYearChoices: 'Risorse dell’anno precedente',
  header3nextYearChoices: 'Variazione decisa dal team',
  header4nextYearChoices: 'Risorse per l’anno prossimo',
  header1extraordinaryEvent: '',
  header2extraordinaryEvent: '',
  header3extraordinaryEvent: '',

  obiettivo1: 'Diagnosi e presa in carico del paziente affetto da asma grave',
  obiettivo2: 'Costi correlati alla distribuzione diretta (DD)',
  obiettivo3: 'Costi per i ricorsi e le gare deserte',
  obiettivo4: 'Aumento dell’efficienza del magazzino',
  obiettivo5: 'Incremento dell’aderenza terapeutica',
  obiettivo6:
    'Aumento del numero dei pazienti pediatrici affetti da asma grave trattati con farmaci biologoci',

  ChatTitle: 'CHAT',
  ChatSubtitle:
    'Clicca il pulsante sottostante per visualizzare i discorsi affrontati durante la simulazione',
  ChatShow: 'MOSTRA',

  FacultySign: 'Faculty:',
  FacultyNames1: 'Patrizio Armeni, Arianna Bertolani,',
  FacultyNames2: 'Francesco Costa, Monica Otto',
  ReportBySign: 'Report by',
  LearningLabSign: 'Learning Lab',
  TeachingSign: 'Teaching and Learning Empowerment',
  LearningLabLinkSign: 'http://learninglab.sdabocconi.it/',
  LearningLabEmailSign: 'learninglab@sdabocconi.it',

  DismissICS:
    'Utilizzo di soli farmaci biologici (senza trattare pazienti con altre tipologie di farmaci, come i corticosteroidi per via inalatoria - ICS)',
  ChangeOMABudget: 'Riduzione dell’utilizzo di Broncolumab',
  ChangeMEPOBudget: 'Riduzione dell’utilizzo di Polmolumab',
  DoNothing: 'Nessuna modifica',
  MoveBudgetFromOMAtoMEPO:
    'Riduzione dell’utilizzo di Broncolumab; aumento dell’utilizzo di Polmolumab',
  MoveBudgetFromMEPOtoOMA:
    'Riduzione dell’utilizzo di Polmolumab; aumento dell’utilizzo di Broncolumab',
  IncreaseOMAandMEPOBudget: 'Aumento di entrambi i farmaci biologici',

  IncreaseOMABudget: 'Aumento dell’utilizzo di Broncolumab',
  DecreaseOMABudget: 'Riduzione dell’utilizzo di Broncolumab',
  IncreaseMEPOBudget: 'Aumento dell’utilizzo di Polmolumab',
  DecreaseMEPOBudget: 'Riduzione dell’utilizzo di Polmolumab',

  DecreaseBudgetFor1M: '- 1.000.000 €',
  DontChangeBudget: '0 €',
  IncreaseBudgetFor500K: '+ 500.000 €',
  DecreaseBudgetFor1MAnd500K: '- 1.500.000 €',
  DecreaseBudgetFor500K: '- 500.000 €',
  IncreaseBudgetFor1MAnd500K: '+ 1.500.000 €',
  DecreaseBudgetFor100K: '- 100.000 €',
  IncreaseBudgetFor3M: '+ 3.000.000 €',
  IncreaseBudgetFor1M: '+ 1.000.000 €',

  diagnosis: 'Diagnosi e presa in carico del paziente affetto da asma grave',
  distribution: 'Costi correlati alla distribuzione diretta (DD)',
  tender: 'Costi per i ricorsi e le gare deserte',

  ImproveWelfareContinuity:
    'Investire sul miglioramento della continuità assistenziale tra ospedale e territorio',
  InvestOnPeopleSensitization:
    'Investire in attività di sensibilizzazione della popolazione sui temi dell’asma grave',
  IncreaseDPCUtilization: 'Puntare su un maggiore utilizzo della DPC',
  ImproveWarehouseManagement:
    'Investire in opere di riorganizzazione del magazzino e delle sue funzioni',
  ImproveSuppliersCompetition:
    'Individuare modalità volte ad aumentare il livello di competizione tra le imprese fornitrici',
  CentralizePurchasing:
    'Spingere verso un maggior grado di centralizzazione degli acquisti',
};
