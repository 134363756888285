import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import DebriefingBaseTable from '../DebriefingBaseTable';

type HoverableCellProps = {
  label: string;
  description: string;
};

const HoverableCell = (props: HoverableCellProps) => {
  const { formatMessage: f } = useIntl();

  return (
    <div
      className="relative flex flex-wrap justify-center text-center cursor-help group"
      style={{
        maxWidth: '10vw',
        fontSize: '14px',
      }}
    >
      {props.description && (
        <img
          src={f({ id: 'teacher:icons.info' })}
          width="24"
          height="24"
          alt="Info"
          style={{ margin: 'auto', display: 'block' }}
        />
      )}

      <p className="mt-4 leading-tight">{props.label}</p>

      {props.description && (
        <div className="absolute transition-all duration-500 ease-in-out scale-50 opacity-0 pointer-events-none group-hover:bottom-16 bottom-2 max-w-96 group-hover:scale-100 group-hover:opacity-100">
          <motion.div
            className="flex p-4 text-lg leading-tight bg-black rounded shadow-lg backdrop-blur-sm text-slate-100"
            style={{
              fontSize: props.description?.length > 300 && '12.9px',
            }}
          >
            {props.description}
          </motion.div>
          <div className="absolute left-0 right-0 block w-4 h-4 mx-auto rotate-45 bg-black -bottom-2" />
        </div>
      )}
    </div>
  );
};

type Props = {
  entries: any[];
  decisions: string[];
};

const FinalVotesTable = (props: Props) => {
  const { formatMessage: f } = useIntl();

  const teamColumn = {
    Header: f({
      id: 'Team',
    }),
    accessor: 'teamID',
    Cell: props => (
      <div {...props} style={{ minWidth: '120px' }}>
        {props.value}
      </div>
    ),
  };

  const decisionsColumns = [
    {
      Header: (
        <p style={{ maxWidth: '10vw' }}>
          {f({
            id: 'pages:decisions-diagnosis.statement',
          })}
        </p>
      ),
      accessor: 'diagnosisDecision',
      Cell: props => (
        <HoverableCell
          {...props}
          label={
            props.value
              ? f({ id: `pages:decisions-diagnosis.answer-${props.value}` })
              : '/'
          }
          description={props.row.original.diagnosisMotivation}
        />
      ),
    },
    {
      Header: (
        <p style={{ maxWidth: '10vw' }}>
          {f({
            id: 'pages:decisions-distribution.statement',
          })}
        </p>
      ),
      accessor: 'directDistributionDecision',
      Cell: props => (
        <HoverableCell
          {...props}
          label={
            props.value
              ? f({
                  id: `pages:decisions-distribution.answer-${props.value}`,
                })
              : '/'
          }
          description={props.row.original.directDistributionMotivation}
        />
      ),
    },
    {
      Header: (
        <p style={{ maxWidth: '10vw' }}>
          {f({
            id: 'pages:decisions-tender.statement',
          })}
        </p>
      ),
      accessor: 'desertTendersCostsDecision',
      Cell: props => (
        <HoverableCell
          {...props}
          label={
            props.value
              ? f({ id: `pages:decisions-tender.answer-${props.value}` })
              : '/'
          }
          description={props.row.original.desertTendersCostsMotivation}
        />
      ),
    },
  ];

  return (
    <div>
      <DebriefingBaseTable
        columns={[
          teamColumn,
          ...decisionsColumns.filter(c => props.decisions.includes(c.accessor)),
        ]}
        data={props.entries}
      />
    </div>
  );
};

export default FinalVotesTable;
