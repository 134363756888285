import clsx from 'clsx';
import { RefObject, useState } from 'react';

interface IProps {
  direction: 'left' | 'right';
  scrollableReference: RefObject<HTMLDivElement>;
}

const PhaseScrollButton = ({ direction, scrollableReference }: IProps) => {
  const scrollOffset: number = 128 * (direction === 'left' ? -1 : 1);
  const [scrolling, setScrolling] = useState<boolean>(false);

  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (
    reference: RefObject<HTMLDivElement>,
    amount: number
  ) => {
    if (!reference?.current || scrolling) return;

    const startX: number = reference.current.scrollLeft;
    let currentTime: number = 0;
    const increment: number = 2;
    const duration: number = 200;

    setScrolling(true);

    const animateScroll = () => {
      currentTime += increment;

      if (reference?.current)
        reference.current.scrollLeft = easeInOutQuad(
          currentTime,
          startX,
          amount * 2,
          duration
        );

      if (currentTime < duration) setTimeout(animateScroll, increment);
      else setScrolling(false);
    };

    animateScroll();
  };

  return (
    <div
      className={clsx(
        'w-[100px] top-0 group max-h-full h-full from-transparent hover:to-blue-200/50 to-white absolute transition-all duration-200 ease-in-out z-20 flex flex-col justify-center items-center cursor-pointer text-center select-none',
        {
          'left-[146px] bg-gradient-to-l rounded-l-lg': direction === 'left',
          'right-0 left-auto bg-gradient-to-r rounded-r-lg':
            direction === 'right',
        }
      )}
      onClick={() => smoothScroll(scrollableReference, scrollOffset)}
    >
      <img
        className={clsx(
          'w-12 h-12 opacity-25 group-hover:opacity-50 transition-all duration-500 ease-in-out group-hover:scale-110',
          direction === 'left'
            ? 'rotate-180 group-hover:-translate-x-2 -translate-x-1'
            : 'group-hover:translate-x-2 translate-x-1'
        )}
        src="https://www.svgrepo.com/show/361712/caret-right.svg"
        alt="Scroll arrow"
      />
    </div>
  );
};

export default PhaseScrollButton;
