import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import DebriefingBaseSelect from '../DebriefingBaseSelect';
import DebriefingNoResultsPlaceholder from '../DebriefingNoResultsPlaceholder';
import BudgetAmountVotesChart from '../plots/BudgetAmountVotesChart';

const BudgetAmountSection = () => {
  const { formatMessage: f } = useIntl();

  const [teamFilter, setTeamFilter] = useState<string>();
  const [roleFilter, setRoleFilter] = useState<string>();
  const [entries, setEntries] = useState<
    { name: string; amt: string | number }[]
  >([]);

  const viewModel = useDebriefing(({ viewModel }) => viewModel);

  useEffect(() => {
    if (!viewModel?.teams) return;

    // If we're displaying by role.
    if (roleFilter)
      setEntries(
        viewModel.teams.map(teamId => ({
          amt: viewModel
            .teamBudgetAmountVotes(teamId)
            .find(({ voter }) => viewModel.roles[voter] === roleFilter)?.amount,
          name: teamId,
        }))
      );
    // If we're displaying by team.
    else if (teamFilter)
      setEntries(
        viewModel
          .teamBudgetAmountVotes(
            viewModel.teams.find(teamId => teamId === teamFilter)
          )
          .map(BAVote => ({
            amt: BAVote.amount,
            name: BAVote.voter,
          }))
      );
    else setEntries([]);
  }, [viewModel, teamFilter, roleFilter]);

  if (!viewModel) return <p>Loading...</p>;

  return (
    <div className="w-full">
      <div className="flex flex-row mb-8">
        <DebriefingBaseSelect
          isMulti={false}
          label="Team"
          placeholder={
            roleFilter
              ? f({ id: 'teacher.debriefing.teams.all' })
              : f({ id: 'teacher.debriefing.teams.none' })
          }
          options={
            viewModel?.teams?.map(teamId => ({
              label: teamId,
              value: teamId,
            })) || []
          }
          handleChange={newTeamFilter => {
            setRoleFilter(undefined);
            setTeamFilter(newTeamFilter?.value);
          }}
          value={teamFilter ? { label: teamFilter, value: teamFilter } : null}
        />
        <DebriefingBaseSelect
          isMulti={false}
          label="Ruolo"
          placeholder={
            teamFilter
              ? f({ id: 'teacher.debriefing.teams.all' })
              : f({ id: 'teacher.debriefing.teams.none' })
          }
          options={Object.values(viewModel.roles).map(role => ({
            label: f({ id: `profiles:${role}.title` }),
            value: role,
          }))}
          handleChange={newRoleFilter => {
            setTeamFilter(undefined);
            setRoleFilter(newRoleFilter?.value);
          }}
          value={
            roleFilter
              ? {
                  label: f({ id: `profiles:${roleFilter}.title` }),
                  value: roleFilter,
                }
              : null
          }
        />
      </div>

      <span className="block w-full h-8" />

      {entries.length ? (
        <BudgetAmountVotesChart
          entries={entries.map(({ name, amt }) => ({
            name: teamFilter
              ? f({ id: `profiles:${viewModel.roles[name]}.shortname` })
              : name,
            amt: amt,
          }))}
        />
      ) : (
        <DebriefingNoResultsPlaceholder />
      )}
    </div>
  );
};

export default BudgetAmountSection;
