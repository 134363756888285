import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import ROLES from '../../data/roles.data';
import buildAssetPath from '../../utils/build-asset-path';

interface IProps {
  playerInfo: Record<string, string>;
  onClose;
}

const PlayerDetailsModal = ({ playerInfo, onClose }: IProps) => {
  const { formatMessage } = useIntl();

  if (!playerInfo) return null;
  const role = ROLES[playerInfo?.roleId];

  return (
    <motion.div
      className="w-[32vw] xl:w-[20vw] bg-white rounded-xl absolute inset-0 border-2 border-slate-200 shadow-xl m-auto h-[280px]"
      draggable
      drag
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      initial={{ scale: 2, opacity: 0 }}
      animate={{
        scale: playerInfo ? 1 : 0,
        opacity: playerInfo ? 1 : 0,
        pointerEvents: playerInfo ? 'all' : 'none',
      }}
    >
      <div className="relative flex flex-col justify-center w-full h-full p-6 select-none">
        <motion.img
          whileHover={{ scale: 1.2, rotate: 90, right: 10, top: 10 }}
          whileTap={{ scale: 0.9 }}
          src={buildAssetPath('images/close.png')}
          alt="Close"
          className="absolute flex items-center justify-center w-6 h-6 cursor-pointer top-2 right-2"
          onClick={onClose}
        />

        <p className="mb-6 text-xl leading-none text-slate-800">
          Info sul {formatMessage({ id: `profiles:${role}.title` })}:
        </p>
        <InfoRow
          label="Ruolo:"
          value={formatMessage({
            id: `profiles:${role}.title`,
          })}
        />
        <InfoRow label="Team:" value={playerInfo?.gameId} />
        <p className="text-slate-600">
          Link di gioco:{' '}
          <a
            href={playerInfo?.url}
            target="_blank"
            className="text-blue-400 underline"
          >
            qui
          </a>
        </p>
        <InfoRow label="Sessione:" value={playerInfo?.sessionId} />
        {playerInfo?.nickname?.length ? (
          <InfoRow label="Nickname:" value={playerInfo?.nickname} />
        ) : null}
      </div>
    </motion.div>
  );
};

const InfoRow = ({ label, value }) => (
  <div className="flex flex-row items-center space-x-2">
    <p className="text-slate-700">{label}</p>
    <p className="font-semibold text-slate-500">{value}</p>
  </div>
);

export default PlayerDetailsModal;
