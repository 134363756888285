import { PDFDownloadLink, Font, PDFViewer } from '@react-pdf/renderer';
import { useIntl } from 'react-intl';
import { IReportData } from '../../pdf-report/interfaces';
import Report from '../../pdf-report/Report';
import DownloadButton from './DownloadReportButton';
import rubikRegular from '../../pdf-report/public/fonts/Rubik/Rubik-Regular.ttf';
import rubikBold from '../../pdf-report/public/fonts/Rubik/Rubik-Bold.ttf';
import montserratRegular from '../../pdf-report/public/fonts/montserrat/Montserrat-Regular.ttf';
import montserratMedium from '../../pdf-report/public/fonts/montserrat/Montserrat-Medium.ttf';
import montserratBold from '../../pdf-report/public/fonts/montserrat/Montserrat-Bold.ttf';
import montserratSemiBold from '../../pdf-report/public/fonts/montserrat/Montserrat-SemiBold.ttf';
import montserratLightItalic from '../../pdf-report/public/fonts/montserrat/Montserrat-LightItalic.ttf';
import montserratMediumItalic from '../../pdf-report/public/fonts/montserrat/Montserrat-MediumItalic.ttf';
import montserratBoldItalic from '../../pdf-report/public/fonts/montserrat/Montserrat-BoldItalic.ttf';
import poppinsRegular from '../../pdf-report/public/fonts/poppins/Poppins-Regular.ttf';

interface IProps {
  data: IReportData;
}

const TeamReportLink = ({ data }: IProps) => {
  const { formatMessage: f } = useIntl();

  initFonts();

  return (
    <PDFDownloadLink
      fileName={`${data.teamName}-report.pdf`}
      document={<Report {...data} />}
    >
      {({ loading }) => (
        <DownloadButton disabled={loading}>
          {loading ? (
            'Attendi...'
          ) : (
            <>
              Scarica
              <img
                src={f({ id: 'teacher:icons.download' })}
                width="14"
                height="14"
                alt="Scarica report"
                style={{
                  marginLeft: '10px',
                }}
              />
            </>
          )}
        </DownloadButton>
      )}
    </PDFDownloadLink>
  );
};

export default TeamReportLink;

const initFonts = () => {
  Font.register({
    family: 'RubikRegular',
    src: rubikRegular,
    fontWeight: 'regular',
  });
  Font.register({
    family: 'RubikBold',
    src: rubikBold,
    fontWeight: 'bold',
  });
  Font.register({
    family: 'MontserratRegular',
    src: montserratRegular,
    fontWeight: 'regular',
  });
  Font.register({
    family: 'MontserratMedium',
    src: montserratMedium,
    fontWeight: 'bold',
  });
  Font.register({
    family: 'MontserratBold',
    src: montserratBold,
    fontWeight: 'bold',
  });
  Font.register({
    family: 'MontserratSemiBold',
    src: montserratSemiBold,
    fontWeight: 'bold',
  });
  Font.register({
    family: 'MontserratLightItalic',
    src: montserratLightItalic,
    fontWeight: 'regular',
  });
  Font.register({
    family: 'MontserratMediumItalic',
    src: montserratMediumItalic,
    fontWeight: 'regular',
  });
  Font.register({
    family: 'MontserratBoldItalic',
    src: montserratBoldItalic,
    fontWeight: 'bold',
  });
  Font.register({
    family: 'PoppinsRegular',
    src: poppinsRegular,
    fontWeight: 'regular',
  });

  Font.registerHyphenationCallback(word => [word]);
};
