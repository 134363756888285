import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

interface IProps {
  entries: { name: string; amt: string | number }[];
}

const BudgetAmountVotesChart = ({ entries }: IProps) => (
  <div className="w-full bg-white h-[60vh]">
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={640}
        height={480}
        data={entries}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="name" name="Ruolo" />
        <Tooltip
          formatter={(value: any, name: string, props: any) => [
            new Intl.NumberFormat('it-IT', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
            }).format(value || 0),
            name,
            props,
          ]}
          contentStyle={{
            border: '2px solid #e0e0e0',
            borderRadius: '8px',
            padding: '20px',
            boxShadow: '0 4px 24px rgba(0, 0, 0, 0.2)',
          }}
        />
        <ReferenceLine y={0} stroke="#000000" />
        <Bar dataKey="amt" name="Variazione budget" fill="#8884d8">
          {entries.map((entry, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={Number(entry.amt) < 0 ? 'IndianRed' : 'MediumSeaGreen'}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default BudgetAmountVotesChart;
