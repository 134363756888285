import { Fragment, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import LOCATIONS from '../../data/locations.data';
import MeetingActivationBox from '../MeetingActivationBox';
import PhaseAdvancementBox from '../PhaseAdvancementBox';
import VotingActivationBox from '../VotingActivationBox';
import PhaseScrollButton from './PhaseScrollButton';
import RoomBox from './RoomBox';
import PlayerDetailsModal from './PlayerDetailsModal';

interface IProps {
  phase: keyof typeof LOCATIONS;
  firstPopulatedRoom?: string;
}

const PhaseRooms = ({ phase, firstPopulatedRoom }: IProps) => {
  const { formatMessage } = useIntl();

  const containerReference = useRef<HTMLDivElement>(null);

  const [playerDetailsModalContent, setPlayerDetailsModalContent] =
    useState<any>();

  return (
    <>
      <div
        ref={containerReference}
        id="phase-rooms-scrollable-container"
        className="flex flex-row flex-grow pt-4 pb-4 overflow-x-scroll rounded-lg scrollino"
      >
        <PhaseScrollButton
          scrollableReference={containerReference}
          direction="right"
        />
        <PhaseScrollButton
          scrollableReference={containerReference}
          direction="left"
        />

        <div className="relative flex-none block w-16 h-full px-4 py-2" />

        {Object.keys(LOCATIONS).map(
          phaseId =>
            phase === phaseId && (
              <Fragment key={phaseId}>
                {Object.entries(LOCATIONS[phaseId]).map(
                  ([locationId, locationPaths]: [string, string[]]) => (
                    <Fragment key={locationId}>
                      {locationId === 'vote' && (
                        <>
                          {['phase1', 'phase2'].includes(phaseId) && (
                            <>
                              <MeetingActivationBox phase={phaseId} />
                              <RoomBox
                                phaseId={
                                  phaseId === 'phase1'
                                    ? 'first-meeting'
                                    : ('second-meeting' as any)
                                }
                                id={
                                  phaseId === 'phase1'
                                    ? 'first-meeting'
                                    : 'second-meeting'
                                }
                                name="Meeting"
                                meetingRoomId={
                                  phaseId === 'phase1'
                                    ? 'first-meeting'
                                    : 'second-meeting'
                                }
                                isFirstPopulatedRoom={false}
                                locations={[]}
                                setPlayerDetailsModalContent={
                                  setPlayerDetailsModalContent
                                }
                              />
                            </>
                          )}
                          <VotingActivationBox phase={phaseId} />
                        </>
                      )}
                      <RoomBox
                        id={locationId}
                        name={formatMessage({
                          id: `monitoring:${phaseId}:${locationId}`,
                        })}
                        locations={locationPaths}
                        isFirstPopulatedRoom={firstPopulatedRoom === locationId}
                        setPlayerDetailsModalContent={
                          setPlayerDetailsModalContent
                        }
                        phaseId={phaseId}
                      />
                    </Fragment>
                  )
                )}
              </Fragment>
            )
        )}

        {!['endgame', 'phase3'].includes(phase) && (
          <PhaseAdvancementBox phase={phase} />
        )}

        <div className="relative block w-24 h-full px-4 py-[8px] flex-none" />
      </div>

      <PlayerDetailsModal
        playerInfo={playerDetailsModalContent}
        onClose={() => setPlayerDetailsModalContent(undefined)}
      />
    </>
  );
};

export default PhaseRooms;
