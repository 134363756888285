import ROLES from '../data/roles.data';
import LOCATIONS from '../data/locations.data';
import ILocationSnapshot from '../stores/game/factories/location-snapshot.interface';
import { GameRecord, VoteRecord } from '../remote/interface';
import { log } from '../utils/log';
import PlayerRole from '../types/PlayerRole.type';
import OriginalGamePhaseId from '../types/OriginalGamePhaseId.type';

export default class MonitoringViewModel {
  id: string;
  state: {
    locations: {
      [gameId: string]: {
        [playerId: string]: {
          locations: ILocationSnapshot['locations'];
        };
      };
    };
    votes: VoteRecord[];
    games: GameRecord[];
  } = {
    locations: {},
    votes: [],
    games: [],
  };

  constructor() {
    this.id = 'MonitoringViewModel'.concat(
      '-',
      Math.ceil(Math.random() * Date.now()).toString(36)
    );

    log(`New view model \`${this.id}\` instantiated.`, 'MonitoringViewModel');
  }

  public ingestJSON(
    stateSlice: keyof MonitoringViewModel['state'],
    payload: ILocationSnapshot[] | GameRecord[] | VoteRecord[]
  ) {
    if (stateSlice === 'locations')
      this.state.locations = this.parseLocationsSnapshots(
        payload as ILocationSnapshot[]
      );
    else if (stateSlice === 'games') this.state.games = payload as GameRecord[];
    else if (stateSlice === 'votes') this.state.votes = payload as VoteRecord[];

    const newViewModel = new MonitoringViewModel();

    newViewModel.state.games = JSON.parse(
      JSON.stringify(stateSlice === 'games' ? payload : this.state.games)
    );
    newViewModel.state.locations = JSON.parse(
      JSON.stringify(
        stateSlice === 'locations'
          ? this.parseLocationsSnapshots(payload as ILocationSnapshot[])
          : this.state.locations
      )
    );
    newViewModel.state.votes = JSON.parse(
      JSON.stringify(stateSlice === 'votes' ? payload : this.state.votes)
    );

    return newViewModel;
  }

  parseLocationsSnapshots(snapshots: ILocationSnapshot[]) {
    const result: {
      [gameId: string]: {
        [playerId: string]: {
          locations: ILocationSnapshot['locations'];
        };
      };
    } = {};

    // TODO: Remove useless `splits` once game properly sets playerId to a playerId
    // instead of a gamePlayId.
    const getPlayerIdFromLocationsSnapshot = (snapshot: ILocationSnapshot) =>
      snapshot.playerId.split('::')[1].split(':')[1].split('.')[0];

    const gamesIds = [...new Set(snapshots.map(({ gameId }) => gameId))];

    for (const gameId of gamesIds) {
      result[gameId] = {};

      for (const playerLocationsSnapshot of snapshots.filter(
        snapshot => snapshot.gameId === gameId
      ))
        result[gameId][
          getPlayerIdFromLocationsSnapshot(playerLocationsSnapshot)
        ] = {
          locations: playerLocationsSnapshot.locations,
        };
    }

    return result;
  }

  public toJSON() {
    return this.state;
  }

  get games() {
    return this.state.games;
  }

  get locations() {
    return this.state.locations;
  }

  get votes() {
    return this.state.votes;
  }

  get gameIds(): string[] {
    return Object.keys(this.state.locations);
  }

  get roles(): Record<PlayerRole, string> {
    return ROLES;
  }

  get sessionId(): string {
    return this.state.games[0]?.sessionId;
  }

  get isChatOpen(): boolean {
    return this.state.games.every(({ isChatOpen }) => isChatOpen);
  }

  get totalPlayersCount(): number {
    return this.games.reduce((acc, { players }) => acc + players.length, 0);
  }

  get currentPhase(): OriginalGamePhaseId {
    const currentPhases = this.state.games.map(
      ({ currentPhase }) => currentPhase
    );
    const currentPhasesPositions = currentPhases.map(phase =>
      PHASE_NAMES.indexOf(phase)
    );
    const olderPhasePosition = Math.min(...currentPhasesPositions) || 0;

    return currentPhases.find(p => p === PHASE_NAMES[olderPhasePosition]);
  }

  get isMeetingOn(): boolean {
    return this.state.games.every(({ isMeetingOn }) => isMeetingOn);
  }

  gamePhaseToDebriefingPhase(
    gamePhase: OriginalGamePhaseId
  ): keyof typeof LOCATIONS {
    return {
      init: 'landing',
      intro: 'intro',
      'intro-2': 'intro',
      'phase-1': 'phase1',
      'first-meeting': 'phase1',
      'phase-1-voting': 'phase1',
      'phase-1-outcomes': 'phase1',
      'phase-1-survey': 'phase1',
      'phase-1-end': 'phase1',
      'phase-2': 'phase2',
      'second-meeting': 'phase2',
      'phase-2-voting': 'phase2',
      'phase-2-outcomes': 'phase2',
      'phase-2-survey': 'phase2',
      'phase-2-end': 'phase2',
      'phase-3': 'phase3',
      'phase-3-voting': 'phase3',
      'phase-3-outcomes': 'phase3',
      'phase-3-survey': 'phase3',
      'phase-3-end': 'phase3',
      conclusions: 'endgame',
    }[gamePhase] as keyof typeof LOCATIONS;
  }

  get firstPopulatedRoom() {
    return (
      LOCATIONS[this.gamePhaseToDebriefingPhase(this.currentPhase)] &&
      Object.entries(
        LOCATIONS[this.gamePhaseToDebriefingPhase(this.currentPhase)]
      ).find(([, locationPaths]) =>
        this.games.some(({ id, players }) =>
          players.some(playerId =>
            this.playerIsInLocation(
              id,
              this.getRoleIdFromPlayerId(playerId),
              locationPaths,
              this.gamePhaseToDebriefingPhase(this.currentPhase)
            )
          )
        )
      )?.[0]
    );
  }

  getRoleIdFromPlayerId(playerId: string) {
    return playerId.split('.')[0];
  }

  playersByGame(gameId: string): string[] {
    if (!this.state.locations?.[gameId]) return;

    return Object.keys(this.state.locations[gameId]);
  }

  playerIsInLocation(
    gameId: string,
    roleId: string,
    locations: string[],
    phaseId: keyof typeof LOCATIONS
  ): boolean {
    const playerLocationsInfo = this.state.locations[gameId]?.[roleId];

    if (!playerLocationsInfo?.locations) return;

    if (this.gamePhaseToDebriefingPhase(this.currentPhase) !== phaseId)
      return false;

    const isMeetingOn: boolean = this.state.games.find(
      ({ id }) => id === gameId
    )?.isMeetingOn;

    const isReadingDocsRoom: boolean = locations.includes('docs');
    const isPlayerReadingDocs: boolean =
      playerLocationsInfo.locations.basecampLocation === 'docs';

    // This is used to catch the case where the player is in the first docs
    // room of the `intro` phase. In this case we wanna return early, and return
    // `true` i f the player is in 'docs' room, but has not visited avatar,
    // and return `false` if the player has visited avatar.
    if (locations[0] === 'intro/docs')
      return (
        playerLocationsInfo.locations.latestLocation === 'docs' && // <- PERCHE NON SI PUO AGGIORNARE IL CAZZO DEL BASECAMP LOCATION IN INTRO SOLTANTO.
        !playerLocationsInfo.locations.visitedLocations.includes('avatar')
      );

    // This is used to catch the case where the player is in the second and last
    // docs room of the `intro` phase. In this case we wanna return early, and return
    // `true` i f the player is in 'docs' room and has visited avatar, and
    // return `false` if the player has not visited avatar.
    if (locations[0] === LOCATIONS.intro['final-docs'][0])
      return (
        isPlayerReadingDocs &&
        playerLocationsInfo.locations.visitedLocations.includes('avatar')
      );

    // This is used to catch the case where the player is reading the docs in
    // phases which are not `intro` (namely: `phase1` and `phase3`, as
    // `phase2` has no docs rooms in it).
    if (!isMeetingOn && isReadingDocsRoom && isPlayerReadingDocs) return true;

    if (isMeetingOn) {
      if (locations.includes('first-leg'))
        return (
          this.state.games.find(({ id }) => id === gameId)?.currentMeeting ===
          'first-leg'
        );

      if (locations.includes('second-leg'))
        return (
          this.state.games.find(({ id }) => id === gameId)?.currentMeeting ===
          'second-leg'
        );

      return false;
    }

    const hasToUseLatestLocation: boolean =
      (phaseId === 'intro' &&
        playerLocationsInfo.locations.latestLocation !== 'team-goals') || // <- PERCHE NON SI PUO AGGIORNARE IL CAZZO DEL BASECAMP LOCATION IN INTRO SOLTANTO.
      phaseId === 'landing';

    return !!locations.includes(
      hasToUseLatestLocation
        ? playerLocationsInfo.locations.latestLocation
        : playerLocationsInfo.locations.basecampLocation
    );
  }

  playerIsInMeeting(gameId: string, roleId: PlayerRole, meetingId: string) {
    return (
      this.currentPhase === meetingId &&
      this.state.games
        .find(({ id }) => id === gameId)
        ?.players.includes(`${roleId}.T1`)
    );
  }

  canAdvanceGamePhase(fromPhase: keyof typeof LOCATIONS): boolean {
    const originalGamePhaseId: GameRecord['currentPhase'] = {
      landing: 'intro',
      intro: 'intro-2',
      phase1: 'phase-1-end',
      phase2: 'phase-2-end',
      phase3: 'phase-3',
      endgame: 'endgame',
    }[fromPhase] as GameRecord['currentPhase'];

    switch (fromPhase) {
      case 'landing':
        return (
          this.currentPhase === 'init' &&
          Object.values(this.state.locations).length > 0 &&
          Object.values(this.state.locations).every(game =>
            Object.values(game).every(location =>
              location.locations.visitedLocations.includes('rules')
            )
          )
        );
      case 'intro':
        return (
          this.currentPhase === originalGamePhaseId &&
          Object.values(this.state.locations).every(game =>
            Object.values(game).every(location =>
              location.locations.visitedLocations.includes('team-goals')
            )
          )
        );

      case 'phase1':
        return (
          this.currentPhase === originalGamePhaseId &&
          this.allPlayersHaveVoted('phase-1-survey-representation') &&
          this.allPlayersHaveVoted('phase-1-survey-satisfaction') &&
          Object.values(this.state.locations).every(game =>
            Object.values(game).every(location =>
              location.locations.visitedLocations.includes(
                'phases/phase-1/survey'
              )
            )
          )
        );

      case 'phase2':
        return (
          this.currentPhase === originalGamePhaseId &&
          this.allPlayersHaveVoted('phase-2-survey-representation') &&
          this.allPlayersHaveVoted('phase-2-survey-satisfaction') &&
          Object.values(this.state.locations).every(game =>
            Object.values(game).every(location =>
              location.locations.visitedLocations.includes(
                'phases/phase-2/survey'
              )
            )
          )
        );

      case 'phase3':
        false;

      case 'endgame':
        false;

      default:
        return false;
    }
  }

  /**
   * Returns true if all players have voted for a given voting session.
   *
   * @param {string} votingSessionId - The voting session id to check for.
   * @returns {boolean} Whether all players have voted for the given voting session.
   */
  allPlayersHaveVoted(votingSessionId: string): boolean {
    const isPhase3DSVote: boolean = votingSessionId === 'phase-3';

    const sessionVotesCount: number = isPhase3DSVote
      ? this.state.votes.filter(({ session }) =>
          [
            'phase-3-distribution',
            'phase-3-diagnosis',
            'phase-3-tender',
          ].includes(session)
        ).length
      : this.state.votes.filter(({ session }) => session === votingSessionId)
          .length;

    return (
      sessionVotesCount ===
      (isPhase3DSVote ? this.gameIds.length * 3 : this.totalPlayersCount)
    );
  }

  gamesArePastPhase(phase: keyof typeof LOCATIONS): boolean {
    // For each phase in the game, there is a location that, if visited, means
    // the game is past that phase. Thi sis a mapping of those locations.
    const locationCriteria = {
      landing: (location: string) => location.startsWith('intro/mandate'),
      intro: (location: string) => location.startsWith('phases/phase-1/intro'),
      phase1: (location: string) => location.startsWith('phases/phase-2/intro'),
      phase2: (location: string) => location.startsWith('phases/phase-3/intro'),
      phase3: () => false,
      endgame: () => false,
    };

    const playersHaveVisitedNextPhaseLocation: boolean = Object.values(
      this.state.locations
    ).some(game =>
      Object.values(game).some(player =>
        player.locations.visitedLocations.some(locationCriteria[phase])
      )
    );

    const phaseCriteria = {
      landing: (currentPhase: string) =>
        ['intro', 'phase-1', 'phase-2', 'phase-3', 'endgame'].includes(
          currentPhase
        ),
      intro: (currentPhase: string) =>
        ['phase-1', 'phase-2', 'phase-3', 'endgame'].includes(currentPhase),
      phase1: (currentPhase: string) =>
        ['phase-2', 'phase-3', 'endgame'].includes(currentPhase),
      phase2: (currentPhase: string) =>
        ['phase-3', 'endgame'].includes(currentPhase),
      phase3: () => false,
      endgame: () => false,
    };

    const gameIsInFollowingPhase: boolean = this.games.every(game =>
      phaseCriteria[phase](game.currentPhase)
    );

    return playersHaveVisitedNextPhaseLocation && gameIsInFollowingPhase;
  }

  /**
   * This is basically the same as `gamesCanStartMeetingForPhase`, but checking
   * for the opposite `isChatOpen` state.
   *
   * @param {string} phase - The phase to check for.
   * @returns {boolean} A boolean value indicating whether all games are past the given phase.
   */
  gamesCanStartChatForPhase(phase: keyof typeof LOCATIONS): boolean {
    // For each phase in the game, there is a location that, if it's the latest
    // for each player, means that each game in the current session can start
    // voting for the given phase. This is a mapping of those locations.
    const locationsCriteria = {
      landing: () => false,
      intro: () => false,
      phase1: ({ latestLocation, basecampLocation }) =>
        (basecampLocation === 'docs' ||
          basecampLocation === 'phases/phase-1/scenario') &&
        latestLocation === 'phases/phase-1/scenario',
      phase2: ({ latestLocation, basecampLocation }) =>
        (basecampLocation === 'docs' ||
          basecampLocation === 'phases/phase-2/scenario') &&
        latestLocation === 'phases/phase-2/scenario',
      phase3: ({ latestLocation, basecampLocation }) =>
        basecampLocation === 'docs' &&
        latestLocation === 'phases/phase-3/scenario',
      endgame: () => false,
    };

    return (
      !this.isChatOpen &&
      !this.isMeetingOn &&
      Object.values(this.state.locations).every(gameLocations =>
        Object.values(gameLocations).every(({ locations }) =>
          locationsCriteria[phase](locations)
        )
      )
    );
  }

  gamesCanStartMeetingForPhase(phase: keyof typeof LOCATIONS): boolean {
    // For each phase in the game, there is a location that, if it's the latest
    // for each player, means that each game in the current session can start
    // voting for the given phase. This is a mapping of those locations.
    const locationsCriteria = {
      landing: () => false,
      intro: () => false,
      phase1: ({ latestLocation, basecampLocation }) =>
        (basecampLocation === 'docs' ||
          basecampLocation === 'phases/phase-1/scenario') &&
        latestLocation === 'phases/phase-1/scenario',
      phase2: ({ latestLocation, basecampLocation }) =>
        (basecampLocation === 'docs' ||
          basecampLocation === 'phases/phase-2/scenario') &&
        latestLocation === 'phases/phase-2/scenario',
      phase3: ({ latestLocation, basecampLocation }) =>
        basecampLocation === 'docs' &&
        latestLocation === 'phases/phase-3/scenario',
      endgame: () => false,
    };

    return (
      this.isChatOpen &&
      Object.values(this.state.locations).every(gameLocations =>
        Object.values(gameLocations).every(({ locations }) =>
          locationsCriteria[phase](locations)
        )
      )
    );
  }

  /**
   * Checks whether all the games in the current session are ready to start
   * voting for the given phase by checking whether all games are in the
   * corresponding pre-voting meeting phase and whether all players have **not**
   * voted for the upcoming voting session yet.
   *
   * @param {string} phase - The phase to check for.
   * @returns {boolean} A boolean value indicating whether all games are ready to start voting for the given phase.
   */
  gamesCanVoteForPhase(phase: keyof typeof LOCATIONS): boolean {
    if (this.gamePhaseToDebriefingPhase(this.currentPhase) !== phase)
      return false;

    const meetingsCriteria = {
      landing: () => false,
      intro: () => false,
      phase1: ({ currentPhase }) => currentPhase === 'first-meeting',
      phase2: ({ currentPhase }) => currentPhase === 'second-meeting',
      phase3: () => true,
      endgame: () => false,
    };

    const isInRightMeeting: boolean = Object.values(this.state.games).every(
      meetingsCriteria[phase]
    );

    const votingSessionId: 'phase-1' | 'phase-2' | 'phase-3' = {
      phase1: 'phase-1',
      phase2: 'phase-2',
      phase3: 'phase-3',
    }[phase];

    if (phase === 'phase3')
      return this.games.every(
        game =>
          game.currentPhase !== 'phase-3-voting' &&
          !this.allPlayersHaveVoted(votingSessionId) &&
          game.players.every(p => {
            const role = p.split('.')[0];
            const playerLocationsInfo = this.state.locations[game.id]?.[role];
            return playerLocationsInfo.locations.visitedLocations.includes(
              'phases/phase-3/scenario'
            );
          })
      );

    if (!votingSessionId) return false;

    // Probably redundant check, but just in case.
    return isInRightMeeting && !this.allPlayersHaveVoted(votingSessionId);
  }

  getPlayerNickname(gameId: string, roleId: string) {
    return this.state.games.find(({ id }) => id === gameId)?.nicknames?.[
      `${roleId}.T1`
    ];
  }

  getPhaseDuration(phaseId: OriginalGamePhaseId) {
    return {
      intro: 45 * 60 * 1_000,
      'intro-2': 45 * 60 * 1_000,

      'phase-1': 45 * 60 * 1_000,
      'first-meeting': 30 * 60 * 1_000,
      'phase-1-voting': 5 * 60 * 1_000,

      'phase-2': 45 * 60 * 1_000,
      'second-meeting': 30 * 60 * 1_000,
      'phase-2-voting': 5 * 60 * 1_000,

      'phase-3': 45 * 60 * 1_000,
      'phase-3-voting': 5 * 60 * 1_000,
    }[phaseId];
  }

  get averageCurrentPhaseStartTime(): number {
    return (
      this.state.games.reduce(
        (total, game) => total + game.currentPhaseStartTime,
        0
      ) / this.state.games.length
    );
  }

  get remainingPhaseTime(): number {
    return (
      this.currentPhase !== 'init' &&
      this.averageCurrentPhaseStartTime +
        this.getPhaseDuration(this.currentPhase) -
        Date.now()
    );
  }
}

// gameId => gameId
// sessionId::gameId => gameRecordId

// sessionId::gameId:roleId.T1

const PHASE_NAMES = [
  'init',
  'intro',
  'intro-2',
  'phase-1',
  'first-meeting',
  'phase-1-voting',
  'phase-1-outcomes',
  'phase-1-end',
  'phase-2',
  'second-meeting',
  'phase-2-voting',
  'phase-2-outcomes',
  'phase-2-end',
  'phase-3',
  'phase-3-voting',
  'phase-3-outcomes',
  'conclusions',
];
