import DebriefingNavigationButton from './DebriefingNavigationButton';

interface IProps {
  basePath: string;
}

const DebriefingNavigationBar = ({ basePath }: IProps) => (
  <div className="flex flex-row w-full mb-16 space-x-8">
    <DebriefingNavigationButton
      label="Obiettivi team"
      url={`${basePath}/teams-goals`}
    />
    <DebriefingNavigationButton
      label="Riepilogo scelte"
      url={`${basePath}/choices-recap`}
    />
    <DebriefingNavigationButton
      label="Scelta 1"
      url={`${basePath}/budget-amount`}
    />
    <DebriefingNavigationButton
      label="Scelta 2"
      url={`${basePath}/budget-allocation`}
    />
    <DebriefingNavigationButton
      label="Scelta 3"
      url={`${basePath}/final-vote`}
    />
    <DebriefingNavigationButton
      label="Soddisfazione"
      url={`${basePath}/satisfaction-and-representation`}
    />
    <DebriefingNavigationButton
      label="Obiettivi"
      url={`${basePath}/players-goals`}
    />
    <DebriefingNavigationButton
      label="Anno successivo"
      url={`${basePath}/next-year`}
    />
    <DebriefingNavigationButton label="Report" url={`${basePath}/report`} />
  </div>
);

export default DebriefingNavigationBar;
