import { useState, useEffect, FC } from 'react';
import { useIntl } from 'react-intl';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import DebriefingBaseSelect from '../DebriefingBaseSelect';
import DebriefingNoResultsPlaceholder from '../DebriefingNoResultsPlaceholder';
import TeamGoalsTable from '../plots/TeamGoalsTable';
import Spinner from '../Spinner';

type Entry = {
  teamID: string;
  ReduceTotalDrugsCosts: boolean;
  ReduceUnexpectedEventsRelatedCosts: boolean;
  IncreaseTotalCompliance: boolean;
};

interface IProps {}

const TeamsGoalsDebrief: FC<IProps> = ({}) => {
  const { formatMessage: f } = useIntl();

  const [teamsFilters, setTeamsFilters] = useState<string[]>([]);
  const [goalsFilters, setGoalsFilters] = useState<string[]>([]);
  const [entries, setEntries] = useState<Entry[]>([]);

  const viewModel = useDebriefing(({ viewModel }) => viewModel);

  useEffect(() => {
    if (viewModel)
      setEntries(
        viewModel?.teams
          .filter(
            teamId => !teamsFilters?.length || teamsFilters.includes(teamId)
          )
          .filter(
            teamId =>
              !goalsFilters?.length ||
              goalsFilters.every(
                goal => viewModel?.getTeamAchievements(teamId)[goal]
              )
          )
          .map(teamId => ({
            teamID: teamId,
            ...viewModel?.getTeamAchievements(teamId),
          }))
      );
  }, [viewModel, teamsFilters, goalsFilters]);

  if (!viewModel?.teams)
    return (
      <Spinner
        size="256px"
        style={{
          margin: 'auto',
        }}
      />
    );

  return (
    <div className="w-full">
      <div className="flex flex-row mb-8">
        <DebriefingBaseSelect
          isMulti
          label="Team"
          placeholder={f({ id: 'teacher.debriefing.teams.all' })}
          options={
            viewModel?.teams.map(teamId => ({
              label: teamId,
              value: teamId,
            })) as {
              label: string;
              value: string;
            }[]
          }
          handleChange={newTeamsFilters =>
            setTeamsFilters(
              newTeamsFilters.map(
                (item: { label: string; value: string }) => item.value
              )
            )
          }
        />
        <DebriefingBaseSelect
          isMulti
          label={f({
            id: 'teacher.debriefing.teams-goals-debrief.reached-goals',
          })}
          placeholder="-"
          options={[
            {
              value: 'ReduceTotalDrugsCosts',
              label: f({ id: 'team-goals:ReduceTotalDrugsCosts.title' }),
            },
            {
              value: 'ReduceUnexpectedEventsRelatedCosts',
              label: f({
                id: 'team-goals:ReduceUnexpectedEventsRelatedCosts.title',
              }),
            },
            {
              value: 'IncreaseTotalCompliance',
              label: f({ id: 'team-goals:IncreaseTotalCompliance.title' }),
            },
          ]}
          handleChange={newGoalsFilters =>
            setGoalsFilters(newGoalsFilters.map(item => item.value))
          }
        />
      </div>

      {entries?.length ? (
        <TeamGoalsTable entries={entries} />
      ) : (
        <DebriefingNoResultsPlaceholder />
      )}
    </div>
  );
};

export default TeamsGoalsDebrief;
