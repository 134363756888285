import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import { GREEN_BLUE } from '../colors';
import { CONTENT1 } from '../contentSizes';
import ReportContext from '../ReportContext';

const YourTeam = ({ players, roles }) => {
  const { dictionary } = useContext(ReportContext);

  return (
    <Page style={{ position: 'relative', fontFamily: 'RubikRegular' }}>
      <Header />
      <View
        style={{
          position: 'relative',
          margin: 'auto',
          marginTop: 0,
          width: CONTENT1,
        }}
      >
        <Text
          style={{
            marginBottom: '30px',
            fontSize: 26,
            fontFamily: 'RubikBold',
            letterSpacing: 0,
            lineHeight: '31px',
            textAlign: 'center',
            color: GREEN_BLUE,
          }}
        >
          {dictionary['YourTeamTitle']}
        </Text>
        <View
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {players.map(({ role, avatar }, index) => {
            const playerAvatar = avatar || 'avatar-0';

            return (
              <View
                key={`${role} ${index}`}
                style={{
                  fontSize: 20,
                  letterSpacing: 0,
                  lineHeight: '18px',
                  width: '240px',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <Image
                  style={{
                    width: '118px',
                    height: '118px',
                    margin: 'auto',
                  }}
                  name={playerAvatar}
                />
                <Text>{dictionary[`${roles[role]}Name`]}</Text>
                <Text
                  style={{
                    fontSize: 13,
                    fontFamily: 'RubikRegular',
                  }}
                >
                  {dictionary[`${roles[role]}`]}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </Page>
  );
};

export default YourTeam;
