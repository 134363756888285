import { useIntl } from 'react-intl';
import buildAssetPath from '../utils/build-asset-path';

export const MainLayout = ({ children }) => (
  <div className="w-screen max-h-screen p-0 m-0 overflow-y-hidden bg-white">
    {children}
  </div>
);

export const Header = () => {
  const { formatMessage: f } = useIntl();

  return (
    <nav className="flex items-center justify-between h-[100px] w-full bg-[#30a3ae] text-white text-center z-[100] px-4 lg:px-6 xl:px-8 2xl:px-10 py-[10px]">
      <img
        className="h-[37px]"
        src={f({ id: 'logos.sda-bocconi.image.url' })}
        alt="logo"
      />
      <img
        className="h-[39px]"
        src={f({ id: 'logos.hospitalia-logo.image.url' })}
        alt="logo"
      />
      <img
        className="h-[35px]"
        src={f({ id: 'logos.llab-area-white.image.url' })}
        alt="logo"
      />
    </nav>
  );
};

export const OuterContent = ({ children }) => (
  <div
    className="w-full h-full flex z-[4] bg-cover"
    style={{
      backgroundPositionY: -140,
    }}
  >
    {children}
  </div>
);

export const InnerContent = ({ children }) => (
  <div
    className="flex flex-col px-10 py-12 m-1 overflow-y-scroll lg:px-14 xl:px-16 2xl:px-20 custom-scrollbar"
    style={{
      width: 'calc(100% - 185px)',
      height: 'calc(100vh - 100px)',
      backgroundImage: `url('${buildAssetPath(
        'images/teacher_bg_light.png'
      )}')`,
    }}
  >
    {children}
  </div>
);
