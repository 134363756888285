import { useIntl } from 'react-intl';
import LOCATIONS from '../data/locations.data';
import SessionsManagementService from '../services/SessionsManagementService';
import { useMonitoring } from '../stores/game/factories/monitoring';
import Spinner from './Spinner';

interface IProps {
  phase: keyof typeof LOCATIONS;
}

const VotingActivationBox = ({ phase }: IProps) => {
  const { formatMessage } = useIntl();
  const { viewModel } = useMonitoring(({ viewModel }) => ({
    viewModel,
  }));

  if (!viewModel?.games) return <Spinner size="32px" />;

  return (
    <div className="flex flex-col items-center justify-center w-64 h-full px-4 pt-4 mr-4 space-y-2">
      <button
        disabled={!viewModel.gamesCanVoteForPhase(phase)}
        onClick={() =>
          SessionsManagementService.openVotingForGamesInSession(
            viewModel.sessionId,
            phase
          )
        }
        className="px-6 py-2 text-sm font-bold leading-none text-white uppercase transition-all duration-500 ease-in-out bg-yellow-700 rounded-full disabled:cursor-not-allowed disabled:bg-slate-200 hover:duration-100 hover:bg-yellow-600"
      >
        {formatMessage({ id: 'teacher:session.vote' })}
      </button>
    </div>
  );
};

export default VotingActivationBox;
