import create, { GetState, SetState } from 'zustand';
import { remote } from '../../../remote';
import { GameRecord, VoteRecord } from '../../../remote/interface';
import MonitoringViewModel from '../../../services/MonitoringViewModel';
import { StoreSlice } from '../../utils/store-slice';
import ILocationSnapshot from './location-snapshot.interface';

const getRoleByVote = vote => vote.voter.split('.')[0];

export const createMonitoringSlice: StoreSlice<{
  viewModel: MonitoringViewModel;

  initialize: (sessionId: string) => Promise<void>;
  hasPlayerVoted: (
    roleId: string,
    votingSessionId: string,
    gameId: string
  ) => boolean;
  haveGamesFinishedVotingPhase: (votingSessionId: string) => boolean;
}> = (set, get) => {
  return {
    viewModel: undefined,

    async initialize(sessionId) {
      console.info(`Initializing monitoring for session \`${sessionId}\`...`);

      /*
        {locations} --\
        {votes}    --->  viewModel state: { locations, votes, games }
        {games}   ---/
      */

      set({
        viewModel: new MonitoringViewModel(),
      });

      const unsubscribeLocations = remote.watchGamesLocationsBySessionId(
        sessionId,
        payloads =>
          set({
            viewModel: get().viewModel.ingestJSON(
              'locations',
              payloads as ILocationSnapshot[]
            ),
          })
      );

      const unsubscribeGames = remote.watchGamesBySessionId(sessionId, games =>
        set({
          viewModel: get().viewModel.ingestJSON(
            'games',
            games.filter(Boolean) as GameRecord[]
          ),
        })
      );

      const unsubscribeVotes = remote.watchGamesVotesBySessionId(
        sessionId,
        votes =>
          set({
            viewModel: get().viewModel.ingestJSON(
              'votes',
              votes.filter(Boolean) as VoteRecord[]
            ),
          })
      );
    },

    hasPlayerVoted(roleId, votingSessionId, gameId) {
      if (!votingSessionId) return false;

      // Since `votingSessionId` is shaped like `phases/phase-1/vote` and
      // `phases/phase-1/survey`, we try // to extract the phase from it in the
      // following -barbaric- way.
      const extractedPhaseId: string = votingSessionId.split('/')[1];

      const isSurvey: boolean = votingSessionId.split('/')[2] === 'survey';

      if (isSurvey)
        return (
          get().viewModel.votes.filter(
            vote =>
              vote.gameId === gameId &&
              getRoleByVote(vote) === roleId &&
              (vote.session === `${extractedPhaseId}-survey-satisfaction` ||
                vote.session === `${extractedPhaseId}-survey-representation`)
          ).length === 2
        );
      else
        return get().viewModel.votes.some(
          vote =>
            vote.gameId === gameId &&
            getRoleByVote(vote) === roleId &&
            vote.session === extractedPhaseId
        );
    },

    haveGamesFinishedVotingPhase(votingSessionId) {
      if (!['phase-1', 'phase-2', 'phase-3'].includes(votingSessionId))
        return false;

      return get().viewModel.games.every(({ id, players }) =>
        players.every(player =>
          get().hasPlayerVoted(player.split('.')[0], votingSessionId, id)
        )
      );
    },
  };
};

export const useMonitoring = create(
  (set: SetState<any>, get: GetState<any>) => ({
    ...createMonitoringSlice(set, get),
  })
);
