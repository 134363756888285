import { PILLS_GAP, PILL_SIZE } from '../../constants';

interface IProps {
  teams: string[];
}

const PhaseOverviewTeamLabels = ({ teams }: IProps) => (
  <div className="pt-8 w-[140px] flex flex-col relative">
    <p className="absolute text-sm font-semibold uppercase -left-1 -top-8 text-slate-500">
      Squadre
    </p>
    <div
      id="team-labels-container"
      className="grid items-center content-center w-full px-2 py-2 translate-y-1 border-2 border-transparent"
      style={{
        gridTemplateRows: `repeat(${
          teams?.filter(Boolean).length
        }, ${PILL_SIZE}px)`,
        rowGap: '4px',
        height: `${
          teams?.filter(Boolean).length * (PILL_SIZE + PILLS_GAP) +
          PILL_SIZE / 1.5
        }px`,
      }}
    >
      {teams?.filter(Boolean).map(team => (
        <p
          key={team}
          className="flex items-center h-full my-auto text-xs font-semibold leading-none uppercase overflow-ellipsis text-slate-600"
        >
          {team}
        </p>
      ))}
    </div>
  </div>
);

export default PhaseOverviewTeamLabels;
