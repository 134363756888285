var UnicodeTrie = require('unicode-trie');
var data = require('./data.json');
var trieBuffer = require('./trie.json');

// Trie is serialized as a Buffer in node, but here
// we may be running in a browser so we make an Uint8Array
var trieData = new Uint8Array(trieBuffer.data);
var trie = new UnicodeTrie(trieData);

var log2 = Math.log2 || function (n) {
  return Math.log(n) / Math.LN2;
};
var bits = function bits(n) {
  return log2(n) + 1 | 0;
};

// compute the number of bits stored for each field
var CATEGORY_BITS = bits(data.categories.length - 1);
var COMBINING_BITS = bits(data.combiningClasses.length - 1);
var SCRIPT_BITS = bits(data.scripts.length - 1);
var EAW_BITS = bits(data.eaw.length - 1);
var NUMBER_BITS = 10;

// compute shift and mask values for each field
var CATEGORY_SHIFT = COMBINING_BITS + SCRIPT_BITS + EAW_BITS + NUMBER_BITS;
var COMBINING_SHIFT = SCRIPT_BITS + EAW_BITS + NUMBER_BITS;
var SCRIPT_SHIFT = EAW_BITS + NUMBER_BITS;
var EAW_SHIFT = NUMBER_BITS;
var CATEGORY_MASK = (1 << CATEGORY_BITS) - 1;
var COMBINING_MASK = (1 << COMBINING_BITS) - 1;
var SCRIPT_MASK = (1 << SCRIPT_BITS) - 1;
var EAW_MASK = (1 << EAW_BITS) - 1;
var NUMBER_MASK = (1 << NUMBER_BITS) - 1;

var getCategory = function getCategory(codePoint) {
  var val = trie.get(codePoint);
  return data.categories[val >> CATEGORY_SHIFT & CATEGORY_MASK];
};

var getCombiningClass = function getCombiningClass(codePoint) {
  var val = trie.get(codePoint);
  return data.combiningClasses[val >> COMBINING_SHIFT & COMBINING_MASK];
};

var getScript = function getScript(codePoint) {
  var val = trie.get(codePoint);
  return data.scripts[val >> SCRIPT_SHIFT & SCRIPT_MASK];
};

var getEastAsianWidth = function getEastAsianWidth(codePoint) {
  var val = trie.get(codePoint);
  return data.eaw[val >> EAW_SHIFT & EAW_MASK];
};

var getNumericValue = function getNumericValue(codePoint) {
  var val = trie.get(codePoint);
  var num = val & NUMBER_MASK;

  if (num === 0) {
    return null;
  } else if (num <= 50) {
    return num - 1;
  } else if (num < 0x1e0) {
    var numerator = (num >> 4) - 12;
    var denominator = (num & 0xf) + 1;
    return numerator / denominator;
  } else if (num < 0x300) {
    val = (num >> 5) - 14;
    var exp = (num & 0x1f) + 2;

    while (exp > 0) {
      val *= 10;
      exp--;
    }
    return val;
  } else {
    val = (num >> 2) - 0xbf;
    var _exp = (num & 3) + 1;
    while (_exp > 0) {
      val *= 60;
      _exp--;
    }
    return val;
  }
};

var isAlphabetic = function isAlphabetic(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Lu' || category === 'Ll' || category === 'Lt' || category === 'Lm' || category === 'Lo' || category === 'Nl';
};

var isDigit = function isDigit(codePoint) {
  return getCategory(codePoint) === 'Nd';
};

var isPunctuation = function isPunctuation(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Pc' || category === 'Pd' || category === 'Pe' || category === 'Pf' || category === 'Pi' || category === 'Po' || category === 'Ps';
};

var isLowerCase = function isLowerCase(codePoint) {
  return getCategory(codePoint) === 'Ll';
};

var isUpperCase = function isUpperCase(codePoint) {
  return getCategory(codePoint) === 'Lu';
};

var isTitleCase = function isTitleCase(codePoint) {
  return getCategory(codePoint) === 'Lt';
};

var isWhiteSpace = function isWhiteSpace(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Zs' || category === 'Zl' || category === 'Zp';
};

var isBaseForm = function isBaseForm(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Nd' || category === 'No' || category === 'Nl' || category === 'Lu' || category === 'Ll' || category === 'Lt' || category === 'Lm' || category === 'Lo' || category === 'Me' || category === 'Mc';
};

var isMark = function isMark(codePoint) {
  var category = getCategory(codePoint);
  return category === 'Mn' || category === 'Me' || category === 'Mc';
};

var index = {
  getCategory: getCategory,
  getCombiningClass: getCombiningClass,
  getScript: getScript,
  getEastAsianWidth: getEastAsianWidth,
  getNumericValue: getNumericValue,
  isAlphabetic: isAlphabetic,
  isDigit: isDigit,
  isPunctuation: isPunctuation,
  isLowerCase: isLowerCase,
  isUpperCase: isUpperCase,
  isTitleCase: isTitleCase,
  isWhiteSpace: isWhiteSpace,
  isBaseForm: isBaseForm,
  isMark: isMark
};

export { getCategory, getCombiningClass, getScript, getEastAsianWidth, getNumericValue, isAlphabetic, isDigit, isPunctuation, isLowerCase, isUpperCase, isTitleCase, isWhiteSpace, isBaseForm, isMark };
export default index;
