import { useIntl } from 'react-intl';
import DebriefingBaseTable from '../DebriefingBaseTable';

type Props = {
  entries: {
    teamID: string;
    'phase-1': number | string;
    'phase-2': number | string;
    'phase-3': number | string;
  }[];
  phases: string[];
};

const ChoicesRecapTable = (props: Props) => {
  const { formatMessage: f } = useIntl();

  const columns = [
    {
      Header: 'Team',
      accessor: 'teamID',
      Cell: props => (
        <div {...props} style={{ minWidth: '160px' }}>
          {props.value}
        </div>
      ),
    },
    {
      Header: 'Fase 1',
      accessor: 'phase-1',
      Cell: props => <div {...props}>{props.value}</div>,
    },
    {
      Header: 'Fase 2',
      accessor: 'phase-2',
      Cell: props => (
        <div {...props} style={{ maxWidth: '320px' }}>
          {f({ id: `decisions:phase-2.option:${props.value}` })}
        </div>
      ),
    },
    {
      Header: 'Esito fase 3',
      accessor: 'phase-3',
      Cell: props => (
        <div {...props} style={{ maxWidth: '320px' }}>
          {f({ id: `pages:feedbacks-${props.value}.title` })}
        </div>
      ),
    },
  ];

  return (
    <div>
      <DebriefingBaseTable
        columns={[
          columns[0],
          ...columns.filter(column => props.phases.includes(column.accessor)),
        ]}
        data={props.entries}
      />
    </div>
  );
};

export default ChoicesRecapTable;
