import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'wouter';
import LOCATIONS from '../data/locations.data';
import SessionsManagementService from '../services/SessionsManagementService';
import { useMonitoring } from '../stores/game/factories/monitoring';
import Spinner from './Spinner';

interface IProps {
  phase: keyof typeof LOCATIONS;
}

const PhaseAdvancementBox = ({ phase }: IProps) => {
  const { formatMessage } = useIntl();
  const [location] = useLocation();
  const [advancing, setAdvancing] = useState(false);
  const viewModel = useMonitoring(({ viewModel }) => viewModel);

  if (!viewModel?.games) return <Spinner size="32px" />;

  const disabled =
    !viewModel.canAdvanceGamePhase(phase) || viewModel.gamesArePastPhase(phase);

  return (
    <div className="flex flex-col items-center justify-center w-64 h-full px-4 pt-4">
      <button
        disabled={disabled || advancing}
        onClick={() => {
          setAdvancing(true);
          SessionsManagementService.advanceGamesInSessionFromPhase(
            viewModel.sessionId,
            phase
          ).then(() => (window.location.href = location));
        }}
        className="px-8 py-4 text-sm font-bold leading-none text-white uppercase transition-all duration-500 ease-in-out bg-blue-900 rounded-full disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-100 hover:duration-100 hover:bg-blue-800"
      >
        {formatMessage({ id: 'teacher:session.next-phase' })}
      </button>
      {!disabled && (
        <span className="text-xs text-center px-8 my-2">
          Tutti i team sono pronti per avanzare
        </span>
      )}
    </div>
  );
};

export default PhaseAdvancementBox;
