
import { useState, useEffect } from 'react';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import DebriefingBaseSelect from '../DebriefingBaseSelect';
import DebriefingNoResultsPlaceholder from '../DebriefingNoResultsPlaceholder';
import ChoicesRecapTable from '../plots/ChoicesRecapTable';

type Entry = {
  teamID: string;
  'phase-1': string;
  'phase-2': string;
  'phase-3': string;
};

const ChoicesRecapSection = () => {
  const [teamsFilters, setTeamsFilters] = useState<string[]>([]);
  const [phasesFilters, setPhasesFilters] = useState<string[]>([]);
  const [entries, setEntries] = useState<Entry[]>([]);

  const viewModel = useDebriefing(({ viewModel }) => viewModel);


  useEffect(() => {
    if (viewModel)
      setEntries(
        viewModel.teams
          .filter(
            teamId => !teamsFilters.length || teamsFilters.includes(teamId)
          )
          .map(teamId => ({
            teamID: teamId,
            'phase-1':
              viewModel.teamBudgetAmountResult(teamId) > 0
                ? 'Aumento delle risorse'
                : viewModel.teamBudgetAmountResult(teamId) < 0
                ? 'Riduzione delle risorse'
                : 'Mantenimento delle risorse',
            'phase-2': viewModel.interpretTeamBudgetAllocationChoice(teamId),
            'phase-3': viewModel.teamFeedback(teamId),
          }))
      );
  }, [viewModel, teamsFilters]);

  return (
    <div className="w-full">
      <div className="flex flex-row mb-8">
        <DebriefingBaseSelect
          isMulti
          label="Team"
          placeholder="Tutti"
          options={viewModel?.teams.map(teamId => ({
            label: teamId,
            value: teamId,
          }))}
          handleChange={newTeamsFilters =>
            setTeamsFilters(
              newTeamsFilters.map(
                (item: { label: string; value: string }) => item.value
              )
            )
          }
        />
        <DebriefingBaseSelect
          isMulti
          label="Fase"
          placeholder="Tutte"
          options={[
            { value: 'phase-1', label: 'Fase 1' },
            { value: 'phase-2', label: 'Fase 2' },
            { value: 'phase-3', label: 'Fase 3' },
          ]}
          handleChange={newPhasesFilters =>
            setPhasesFilters(
              newPhasesFilters.map(
                (item: { label: string; value: string }) => item.value
              )
            )
          }
        />
      </div>

      {entries.length ? (
        <ChoicesRecapTable
          entries={entries}
          phases={
            !phasesFilters.length
              ? ['phase-1', 'phase-2', 'phase-3']
              : phasesFilters
          }
        />
      ) : (
        <DebriefingNoResultsPlaceholder />
      )}
    </div>
  );
};

export default ChoicesRecapSection;
