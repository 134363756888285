import clsx from 'clsx';
import { Link, useLocation } from 'wouter';

const DebriefingNavigationButton = ({ url, label }) => {
  const [currentLocation] = useLocation();
  const isActive: boolean = currentLocation.endsWith(url);

  return (
    <button
      className={clsx(
        'inline-block text-lg border-b-2 rounded-sm cursor-pointer bg-none',
        isActive ? 'border-[#30a3ae]' : 'border-transparent'
      )}
    >
      <Link
        href={url}
        style={{
          color: isActive ? '#30a3ae' : '#586767',
          fontWeight: isActive ? 400 : 200,
        }}
      >
        {label}
      </Link>
    </button>
  );
};

export default DebriefingNavigationButton;
