import { Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React, { useContext } from 'react';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import { BLUE, WHITE } from '../colors';
import ReportContext from '../ReportContext';

const Cover = ({ teamName, date }) => {
  const { dictionary } = useContext(ReportContext);

  return (
    <Page style={{ position: 'relative' }}>
      <View
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: BLUE,
          color: WHITE,
          fontFamily: 'MontserratRegular',
        }}
      >
        <Image
          name="Background_Cover"
          style={{
            width: '901px',
            position: 'absolute',
            right: '0px',
            bottom: '0px',
          }}
        />
        <Image
          name="logo_sda_bocconi"
          style={{
            height: '65px',
            width: '301px',
            position: 'absolute',
            top: '45px',
            left: '64px',
          }}
        />
        <Image
          name="logo_hospitalia"
          style={{
            height: '103px',
            width: '394px',
            position: 'absolute',
            top: '254px',
            left: '64px',
          }}
        />
        <Image
          name="Stroke_3"
          style={{
            height: '1px',
            width: '452px',
            border: '1pt solid #FFFFFF',
            opacity: 0.7,
            position: 'absolute',
            top: '518px',
            left: '64px',
          }}
        />
        <Image
          name="logo_area"
          style={{
            height: '54px',
            width: '237px',
            position: 'absolute',
            top: '541px',
            right: '17px',
          }}
        />
        <Text
          style={{
            position: 'absolute',
            top: '490px',
            left: '68px',
            fontFamily: 'PoppinsRegular',
            fontSize: 24,
            letterSpacing: 0,
            lineHeight: '35px',
          }}
        >
          {moment(date).format('DD.MM.YYYY')}
        </Text>
        <View
          style={{
            position: 'absolute',
            flexDirection: 'row',
            bottom: '56px',
            left: '68px',
            fontSize: 24,
            letterSpacing: 0,
            lineHeight: '29px',
          }}
        >
          <Text>{dictionary['reportTeam']} </Text>
          <Text
            style={{
              fontFamily: 'MontserratBold',
            }}
          >
            {teamName}
          </Text>
        </View>
      </View>
    </Page>
  );
};

export default Cover;
