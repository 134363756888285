// import reduce from 'lodash/reduce';
import Default from '../../../public/images/default.jpg';
import Background_Cover from '../../../public/images/Background_Cover.png';
import logo_area from '../../../public/images/logo_area.png';
import logo_hospitalia from '../../../public/images/logo_hospitalia.png';
import logo_sda_bocconi from '../../../public/images/logo_sda_bocconi.png';
import Stroke_3 from '../../../public/images/Stroke_3.png';
import box1 from '../../../public/images/box1.png';
import box2 from '../../../public/images/box2.png';
import positive from '../../../public/images/positive.png';
import negative from '../../../public/images/negative.png';
import positiveBox from '../../../public/images/Rectangle.png';
import negativeBox from '../../../public/images/Rectangle-Copy-2.png';
import chatBox from '../../../public/images/Rectangle-Copy.png';
import avatar0 from '../../../public/images/avatar-male-a.png';
import avatar1 from '../../../public/images/avatar-male-b.png';
import avatar2 from '../../../public/images/avatar-male-c.png';
import avatar3 from '../../../public/images/avatar-male-d.png';
import avatar4 from '../../../public/images/avatar-male-e.png';
import avatar5 from '../../../public/images/avatar-male-f.png';
import avatar6 from '../../../public/images/avatar-female-a.png';
import avatar7 from '../../../public/images/avatar-female-b.png';
import avatar8 from '../../../public/images/avatar-female-c.png';
import avatar9 from '../../../public/images/avatar-female-d.png';
import avatar10 from '../../../public/images/avatar-female-e.png';
import avatar11 from '../../../public/images/avatar-female-f.png';
import YouAreOpen from '../../../public/images/open.png';
import MeMyselfAndI from '../../../public/images/myself.png';
import Chat from '../../../public/images/thank-you.png';
import obiettivo1 from '../../../public/images/obiettivo-1.png';
import obiettivo2 from '../../../public/images/obiettivo-2.png';
import obiettivo3 from '../../../public/images/obiettivo-3.png';
import obiettivo4 from '../../../public/images/obiettivo-4.png';
import obiettivo5 from '../../../public/images/obiettivo-5.png';
import obiettivo6 from '../../../public/images/obiettivo-6.png';
import logoAreaLast from '../../../public/images/logoAreaLast.png';

const images = (name: string) => {
  if (!name) return Default;
  switch (name) {
    case 'Background_Cover':
      return Background_Cover;
    case 'logo_area':
      return logo_area;
    case 'logo_hospitalia':
      return logo_hospitalia;
    case 'logo_sda_bocconi':
      return logo_sda_bocconi;
    case 'Stroke_3':
      return Stroke_3;
    case 'box1':
      return box1;
    case 'box2':
      return box2;
    case 'positive':
      return positive;
    case 'negative':
      return negative;
    case 'positiveBox':
      return positiveBox;
    case 'negativeBox':
      return negativeBox;
    case 'chatBox':
      return chatBox;
    case 'avatar-0':
      return avatar0;
    case 'avatar-1':
      return avatar1;
    case 'avatar-2':
      return avatar2;
    case 'avatar-3':
      return avatar3;
    case 'avatar-4':
      return avatar4;
    case 'avatar-5':
      return avatar5;
    case 'avatar-6':
      return avatar6;
    case 'avatar-7':
      return avatar7;
    case 'avatar-8':
      return avatar8;
    case 'avatar-9':
      return avatar9;
    case 'avatar-10':
      return avatar10;
    case 'avatar-11':
      return avatar11;
    case 'YouAreOpen':
      return YouAreOpen;
    case 'MeMyselfAndI':
      return MeMyselfAndI;
    case 'Chat':
      return Chat;
    case 'obiettivo1':
      return obiettivo1;
    case 'obiettivo2':
      return obiettivo2;
    case 'obiettivo3':
      return obiettivo3;
    case 'obiettivo4':
      return obiettivo4;
    case 'obiettivo5':
      return obiettivo5;
    case 'obiettivo6':
      return obiettivo6;
    case 'logoAreaLast':
      return logoAreaLast;
    default: {
      console.log('PDFReport: image not found with name: ', name);
      return Default;
    }
  }
};

export default images;
