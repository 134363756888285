import clsx from 'clsx';

const PhaseNavigationButton = ({ direction, disabled, label, handleClick }) => (
  <button
    className={clsx(
      'flex opacity-75 hover:opacity-90 hover:bg-slate-100 active:bg-slate-200 transition-all duration-500 ease-in-out self-center group justify-self-center items-center justify-center px-8 py-2 text-lg border-none rounded outline-none cursor-pointer disabled:cursor-not-allowed disabled:opacity-20 group bg-none',
      direction === 'right' ? 'flex-row' : 'flex-row-reverse'
    )}
    disabled={disabled}
    onClick={handleClick}
  >
    <p className="text-xs font-bold uppercase text-slate-900">{label}</p>
    <img
      className={clsx(
        'w-8 h-8 transition-all duration-500 ease-in-out group-hover:scale-125',
        direction === 'right'
          ? 'group-hover:translate-x-1'
          : 'group-hover:-translate-x-1 rotate-180'
      )}
      // src={f({
      //   id: 'teacher:icons.arrow-bottom',
      // })}
      src="https://www.svgrepo.com/show/361712/caret-right.svg"
      style={{
        marginLeft: direction === 'right' ? '10px' : 'auto',
        marginRight: direction === 'left' ? '10px' : 'auto',
      }}
      alt="phase-arrow"
    />
  </button>
);

export default PhaseNavigationButton;
