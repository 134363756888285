import clsx from 'clsx';
import { useIntl } from 'react-intl';
import DebriefingBaseTable from '../DebriefingBaseTable';

// TODO: Custom tooltip!
const GoalBadge = ({ reached, label, title }) => (
  <>
    <span
      className={clsx(
        'w-10 h-10 rounded-full flex relative group',
        reached ? 'bg-green-500' : 'bg-red-500'
      )}
    >
      <span className="absolute bottom-0 left-0 right-0 w-64 p-4 mx-auto text-center uppercase transition-all duration-500 ease-in-out scale-50 rounded-lg shadow-xl opacity-0 pointer-events-none bg-slate-900 text-slate-100 -translate-x-28 group-hover:bottom-10 group-hover:opacity-100 blur-lg group-hover:blur-none group-hover:scale-100">
        {title}
        <hr className="my-2" />
        <p
          className={clsx(
            'mt-1 text-xs font-bold uppercase',
            reached ? 'text-green-100' : 'text-red-100'
          )}
        >
          obiettivo{reached ? ' ' : ' non '}raggiunto
        </p>
      </span>

      <img
        src={
          reached
            ? 'https://storage.googleapis.com/ledsp-props-and-tools-sets/hospitalia-staging/images/check.png'
            : 'https://storage.googleapis.com/ledsp-props-and-tools-sets/hospitalia-staging/images/cross.png'
        }
        className="w-1/2 m-auto h-1/2 cursor-help"
        alt={label}
      />
    </span>
  </>
);

type Goal = {
  id: string;
  achieved: boolean;
};

type Props = {
  entries: {
    id: string;
    goal1: Goal;
    goal2: Goal;
    goal3: Goal;
    count: number;
  }[];
  mode?: 'team' | 'role';
};

const PlayersGoalsDebriefTable = (props: Props) => {
  const { formatMessage: f } = useIntl();

  const columns = [
    {
      Header: props.mode === 'team' ? 'Ruolo' : 'Team',
      accessor: 'id',
      Cell: props => <div {...props}>{props.value}</div>,
    },
    {
      Header: f({
        id: 'Obiettivo 1',
      }),
      accessor: 'goal1',
      Cell: props => (
        <div {...props}>
          <GoalBadge
            reached={props.value.achieved}
            label={f({ id: `personal-goals:${props.value.id}.title` })}
            title={f({ id: `personal-goals:${props.value.id}.title` })}
          />
        </div>
      ),
    },
    {
      Header: f({
        id: 'Obiettivo 2',
      }),
      accessor: 'goal2',
      Cell: props => (
        <div {...props}>
          <GoalBadge
            reached={props.value.achieved}
            label={f({ id: `personal-goals:${props.value.id}.title` })}
            title={f({ id: `personal-goals:${props.value.id}.title` })}
          />
        </div>
      ),
    },
    {
      Header: f({
        id: 'Obiettivo 3',
      }),
      accessor: 'goal3',
      Cell: props => (
        <div {...props}>
          <GoalBadge
            reached={props.value.achieved}
            label={f({ id: `personal-goals:${props.value.id}.title` })}
            title={f({ id: `personal-goals:${props.value.id}.title` })}
          />
        </div>
      ),
    },
    {
      Header: f({
        id: 'Totale',
      }),
      accessor: 'count',
      Cell: props => (
        <div {...props}>
          <p>{`${props.value} / 3`}</p>
        </div>
      ),
    },
  ];

  return (
    <div>
      <DebriefingBaseTable columns={columns} data={props.entries} />
    </div>
  );
};

export default PlayersGoalsDebriefTable;
