import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import { GREEN_BLUE } from '../colors';
import { CONTENT2 } from '../contentSizes';
import ReportContext from '../ReportContext';

const OneImageWithText = ({ image, children }) => {
  const { dictionary } = useContext(ReportContext);

  function getImageStyle(image) {

    var imageStyle = {

    }

    switch (image) {
      case 'open':
        imageStyle = {
          width: 486,
          height: 348
        }
        break;
      case 'MyselfAndI':
        imageStyle = {
          width: 444,
          height: 360
        }
        break;
      default:
        imageStyle = {}
        break;
    }

    return imageStyle
  }

  var imageStyle = getImageStyle(image)

  var label = dictionary[`${image}FeedbackTitle`]?dictionary[`${image}FeedbackTitle`]:''


  return (
    <Page style={{
      position: 'relative', fontFamily: 'RubikRegular',
    }}>
      <Header />
      <View style={{
        width: '100%',
        height: '100%',
      }}>
        <View style={{
          width: CONTENT2,
          margin: 'auto',
          marginTop: 0,
          marginBottom: 0,
        }}>
          <Text style={{
            fontSize: 26,
            fontFamily: 'RubikBold',
            letterSpacing: 0,
            lineHeight: '31px',
            textAlign: 'center',
            textTransform: 'uppercase',
            marginBottom: '36px',
            color: GREEN_BLUE
          }}>{label}</Text>
        </View>
        <View style={{
          flexDirection: 'row',
          width: CONTENT2,
          alignSelf: 'center'
        }}>
          <Image style={{
            ...imageStyle,
          }} name={`${image}`} />
          <View style={{
            width: 239,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            margin: 'auto'
          }}>
            {children}
          </View>
        </View>
      </View>
    </Page>
  );
};

export default OneImageWithText;
