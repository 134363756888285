import ROLES from '../data/roles.data';
import { AvatarRecord, GameRecord, VoteRecord } from '../remote/interface';
import PlayerGoal from '../types/PlayerGoal.type';
import PlayerRole from '../types/PlayerRole.type';
import VotingSession from '../types/VotingSession.type';

export type AllocationDecisionOptions =
  | 'DismissICS'
  | 'ChangeOMABudget'
  | 'ChangeMEPOBudget'
  | 'DoNothing'
  | 'MoveBudgetFromOMAtoMEPO'
  | 'MoveBudgetFromMEPOtoOMA'
  | 'IncreaseOMAandMEPOBudget';

export type DirectDistributionDecisionOptions =
  | 'IncreaseDPCUtilization'
  | 'ImproveWarehouseManagement';

export type DesertTenderCostsDecisionOptions =
  | 'ImproveSuppliersCompetition'
  | 'CentralizePurchasing';

export type DiagnosisDecisionOptions =
  | 'ImproveWelfareContinuity'
  | 'InvestOnPeopleSensitization';

export type BudgetDecisionOptions =
  | 'IncreaseBudgetFor3M'
  | 'IncreaseBudgetFor1MAnd500K'
  | 'IncreaseBudgetFor1M'
  | 'IncreaseBudgetFor500K'
  | 'DontChangeBudget'
  | 'DecreaseBudgetFor100K'
  | 'DecreaseBudgetFor500K'
  | 'DecreaseBudgetFor1M'
  | 'DecreaseBudgetFor1MAnd500K';

const BudgetDecisionValues: Record<BudgetDecisionOptions, number> = {
  IncreaseBudgetFor3M: 3e6,
  IncreaseBudgetFor1MAnd500K: 1.5e6,
  IncreaseBudgetFor1M: 1e6,
  IncreaseBudgetFor500K: 0.5e6,
  DontChangeBudget: 0,
  DecreaseBudgetFor100K: -0.1e6,
  DecreaseBudgetFor500K: -0.5e6,
  DecreaseBudgetFor1M: -1e6,
  DecreaseBudgetFor1MAnd500K: -1.5e6,
};

type AllocationDecision =
  | 'DismissICS'
  | 'ChangeOMABudget'
  | 'ChangeMEPOBudget'
  | 'DoNothing'
  | 'MoveBudgetFromOMAtoMEPO'
  | 'MoveBudgetFromMEPOtoOMA'
  | 'IncreaseOMAandMEPOBudget';

const AllocationDecisionDeltaTreatments: Record<
  AllocationDecision,
  (v: {
    changeOMAPatientDelta: number;
    changeMEPOPatientDelta: number;
    changeICSPatientDelta: number;
  }) => {
    oma: number;
    mepo: number;
    ics: number;
  }
> = {
  DismissICS: v => ({ oma: 0, mepo: 0, ics: v.changeICSPatientDelta }),
  ChangeOMABudget: v => ({
    oma: v.changeOMAPatientDelta,
    mepo: 0,
    ics: -v.changeOMAPatientDelta,
  }),
  ChangeMEPOBudget: v => ({
    oma: 0,
    mepo: v.changeMEPOPatientDelta,
    ics: -v.changeMEPOPatientDelta,
  }),
  DoNothing: v => ({ oma: 0, mepo: 0, ics: 0 }),
  MoveBudgetFromOMAtoMEPO: v => ({ oma: -121, mepo: 128, ics: -7 }),
  MoveBudgetFromMEPOtoOMA: v => ({ oma: 217, mepo: -240, ics: 13 }),
  IncreaseOMAandMEPOBudget: v => ({
    oma: v.changeOMAPatientDelta,
    mepo: 0,
    ics: -v.changeOMAPatientDelta,
  }),
};

const toDeltaTreatments =
  (
    option:
      | 'DismissICS'
      | 'ChangeOMABudget'
      | 'ChangeMEPOBudget'
      | 'DoNothing'
      | 'MoveBudgetFromOMAtoMEPO'
      | 'MoveBudgetFromMEPOtoOMA'
      | 'IncreaseOMAandMEPOBudget'
  ) =>
  (v: {
    changeOMAPatientDelta: number;
    changeMEPOPatientDelta: number;
    changeICSPatientDelta: number;
  }): {
    oma: number;
    mepo: number;
    ics: number;
  } => {
    const treatments = AllocationDecisionDeltaTreatments[option];
    return treatments ? treatments(v) : { oma: 0, mepo: 0, ics: 0 };
  };

export default class DebriefingService {
  state: {
    [gameId: string]: {
      id: string;
      players: PlayerRole[];
      registeredVotes: Record<
        PlayerRole,
        Record<VotingSession, VoteRecord | string | number>
      >;
      startTime: number;
      avatars: AvatarRecord[];
    };
  } = {};

  costPerPatientOMA = 9_000;
  costPerPatientMEPO = 8_500;
  numberOfPatientsTreatedWithOMAYear0 = 960;
  numberOfPatientsTreatedWithICSYear0 = 800;
  numberOfPatientsTreatedWithMEPOYear0 = 240;
  costPerUnexpectedEventICS = 1_957.5;
  costPerUnexpectedEventBio = 968.9625;
  totalOMACostYear0 =
    this.costPerPatientOMA * this.numberOfPatientsTreatedWithOMAYear0;
  totalMEPOCostYear0 =
    this.costPerPatientMEPO * this.numberOfPatientsTreatedWithMEPOYear0;
  numberOfAdultPatientsTreatedWithOMAYear0 = 121;
  numberOfAllergicPatientsTreatedWithOMAYear0 = 600;
  numberOfPediatricPatientsTreatedWithOMAYear0 = 239;
  percentageOfPatientsTreatedWithBioYear0 = 0.6;
  adultPatientsTreatedWithOMAComplianceYear0 = 0.26;
  pediatricPatientsTreatedWithOMAComplianceYear0 = 0.4;
  allergicPatientsTreatedWithOMAComplianceYear0 = 0.35;
  complianceYear0 = 0.2549;
  unexpectedEventCostsYear0 = 2.728755e6;
  totalDrugsCostsYear0 = 10.68e6;
  numberOfPatientsTreatedYear0 =
    this.numberOfPatientsTreatedWithOMAYear0 +
    this.numberOfPatientsTreatedWithICSYear0 +
    this.numberOfPatientsTreatedWithMEPOYear0;
  costPerUnexpectedEventMEPO = this.costPerUnexpectedEventBio;
  costPerUnexpectedEventOMA = this.costPerUnexpectedEventBio;
  numberOfAllergicPatientsTreatedYear1 = 1_000;

  constructor(public readonly sessionId: string, state) {
    this.state = state;
  }

  public static fromJSON(
    sessionId: string,
    games: Record<
      string,
      { game: GameRecord; votes: VoteRecord[]; avatars: AvatarRecord[] }
    >
  ) {
    let result: DebriefingService['state'] = {};

    for (const [gameId, { game, votes, avatars }] of Object.entries(games)) {
      result[gameId] = {
        id: gameId,
        registeredVotes: {} as any,
        players: game.players
          // .filter(player => player.split('.')['1'] === gameId)
          .map(player => player.split('.')['0']) as PlayerRole[],
        startTime: game.startTime,
        avatars,
      };

      for (const vote of votes) {
        const [playerId, fakeT1TeamId] = vote.voter.split('.');

        if (vote.isAbstained) continue;

        result[gameId].registeredVotes[playerId] = {
          ...result[gameId].registeredVotes[playerId],
        };

        // TODO: Refactor this, use a single format for all votes.
        result[gameId].registeredVotes[playerId][vote.session] =
          vote.session.includes('survey-representation') ||
          vote.session.includes('survey-satisfaction')
            ? Number(vote.vote)
            : vote.session.includes('phase-3')
            ? vote
            : vote.vote;
      }
    }

    return new DebriefingService(sessionId, result);
  }

  get teams(): string[] {
    return Object.keys(this.state);
  }

  get roles(): Record<PlayerRole, string> {
    return ROLES;
  }

  teamStartedAt(teamId: string) {
    return this.state[teamId].startTime;
  }

  teamPlayers(teamId: string): string[] {
    return this.state[teamId].players;
  }

  playerAvatar(teamId: string, role: string) {
    return (
      this.state[teamId]?.avatars?.find(a => a.playerId === `${role}.T1`)
        ?.avatar || 'avatar-1'
    );
  }

  // Used for live.
  haveAllPlayersVoted(votingSessionId: string): boolean {
    const games = this.teams;

    const players = [];

    for (const game of games) players.push(...this.teamPlayers(game));

    return Object.entries(this.state).every(([teamId, team]) => {
      const players = this.teamPlayers(teamId);

      return votingSessionId === 'phase-3'
        ? team.registeredVotes.DS[votingSessionId]
        : players.every(player => {
            return team.registeredVotes[player][votingSessionId];
          });
    });
  }

  playerGoals(
    teamId: string,
    roleId: PlayerRole
  ): {
    id: PlayerGoal;
    achieved: boolean;
  }[] {
    return this.goalsByRole()[roleId].map(goalId => ({
      id: goalId,
      achieved: this.hasPlayerAchievedGoal(teamId, goalId),
    }));
  }

  hasPlayerAchievedGoal(teamId: string, goalId: PlayerGoal) {
    switch (goalId) {
      case 'ReduceCostOfAsmaDrugs':
        return this.teamBudgetAmountResult(teamId) < 0;
      case 'KeepAGoodOrganizationClimate':
        return (
          (this.teamRepresentation(teamId) + this.teamSatisfaction(teamId)) /
            2 >=
          0.6
        );
      case 'ReduceAdmissionsDueToUnexpectedEvents':
        return this.teamUnexpectedEventCostsDelta(teamId) < 0;
      case 'ImproveWarehouseEfficacy':
        return (
          this.teamDirectDistributionDecision(teamId) ===
          'ImproveWarehouseManagement'
        );
      case 'ImproveCompliance':
        return this.teamComplianceDelta(teamId) > 0;
      case 'ImproveTenderSpecificationWriting':
        return (
          this.teamDesertTenderCostsDecision(teamId) ===
          'ImproveSuppliersCompetition'
        );
      case 'ImprovePatientsWelfare':
        return (
          this.teamDiagnosisDecision(teamId) === 'ImproveWelfareContinuity'
        );
      case 'ImprovePeopleAwareness':
        return (
          this.teamDiagnosisDecision(teamId) === 'InvestOnPeopleSensitization'
        );
      case 'IncreaseNumberOfPediatricPatientsTreatedWithBio':
        return (
          this.teamNumberOfPediatricPatientsTreatedWithBioDelta(teamId) > 0
        );
      case 'IncreasePercentageOfPatientsTreatedWithBio':
        return this.teamPercentageOfPatientsTreatedWithBioDelta(teamId) > 0;
      default:
        return true;
    }
  }

  teamNumberOfPediatricPatientsTreatedWithBioDelta(teamId: string) {
    return (
      this.teamNumberOfPediatricPatientsTreatedWithOMAYear1(teamId) -
      this.numberOfPediatricPatientsTreatedWithOMAYear0
    );
  }

  teamPercentageOfPatientsTreatedWithBioDelta(teamId: string) {
    return (
      this.teamPercentageOfPatientsTreatedWithBioYear1(teamId) -
      this.percentageOfPatientsTreatedWithBioYear0
    );
  }

  teamNumberOfPediatricPatientsTreatedWithOMAYear1(teamId: string) {
    if (
      this.teamBudgetAllocationDecision(teamId) === 'MoveBudgetFromOMAtoMEPO' ||
      this.teamBudgetAllocationDecision(teamId) === 'ChangeMEPOBudget'
    )
      return this.numberOfPediatricPatientsTreatedWithOMAYear0;

    return (
      this.teamPercentageOfPediatricPatientsTreatedWithOMAYear1(teamId) *
      this.teamNumberOfPatientsTreatedWithOMAYear1(teamId)
    );
  }

  interpretPlayerBudgetAllocationChoice(
    teamId: string,
    roleId: PlayerRole
  ): string {
    const teamBudgetAmountResult = this.teamBudgetAmountResult(teamId);
    const roleBudgetAllocationDecision =
      this.state[teamId].registeredVotes[roleId]?.['phase-2'];

    if (!roleBudgetAllocationDecision) return '- Decision not taken -';

    switch (roleBudgetAllocationDecision) {
      case 'ChangeOMABudget': {
        if (teamBudgetAmountResult && teamBudgetAmountResult > 0)
          return 'IncreaseOMABudget';
        else return 'DecreaseOMABudget';
      }
      case 'ChangeMEPOBudget': {
        if (teamBudgetAmountResult && teamBudgetAmountResult > 0)
          return 'IncreaseMEPOBudget';
        else return 'DecreaseMEPOBudget';
      }
      // 'DismissICS' | 'IncreaseOMAandMEPOBudget' | 'MoveBudgetFrom*To*'
      default:
        return roleBudgetAllocationDecision as string;
    }
  }

  interpretTeamBudgetAllocationChoice(teamId: string): string {
    const teamBudgetAmountResult = this.teamBudgetAmountResult(teamId);
    const teamBudgetAllocationDecision =
      this.teamBudgetAllocationDecision(teamId);

    if (!teamBudgetAllocationDecision) return '- Decision not taken -';

    switch (teamBudgetAllocationDecision) {
      case 'ChangeOMABudget': {
        if (teamBudgetAmountResult && teamBudgetAmountResult > 0)
          return 'IncreaseOMABudget';
        else return 'DecreaseOMABudget';
      }
      case 'ChangeMEPOBudget': {
        if (teamBudgetAmountResult && teamBudgetAmountResult > 0)
          return 'IncreaseMEPOBudget';
        else return 'DecreaseMEPOBudget';
      }
      // 'DismissICS' | 'IncreaseOMAandMEPOBudget' | 'MoveBudgetFrom*To*'
      default:
        return teamBudgetAllocationDecision;
    }
  }

  getTeamAchievements(teamId: string) {
    return {
      ReduceTotalDrugsCosts:
        this.hasTeamAchievedReduceTotalDrugsCostsGoal(teamId),
      ReduceUnexpectedEventsRelatedCosts:
        this.hasTeamAchievedReduceUnexpectedEventsRelatedCostsGoal(teamId),
      IncreaseTotalCompliance:
        this.hasTeamAchievedIncreaseTotalComplianceGoal(teamId),
    };
  }

  teamTotalDrugsCostsDelta(teamId: string) {
    return this.teamTotalDrugsCostsYear1(teamId) - this.totalDrugsCostsYear0;
  }

  teamComplianceDelta(teamId: string) {
    return this.teamComplianceYear1(teamId) - this.complianceYear0;
  }

  teamBudgetAmountVotes(teamId: string) {
    return Object.entries(this.state[teamId].registeredVotes).map(
      ([roleId, roleVotes]) => ({
        voter: roleId,
        decision: String(roleVotes['phase-1']),
        amount: Number(BudgetDecisionValues[roleVotes['phase-1'] as string]),
      })
    );
  }

  teamBudgetAllocationVotes(teamId: string) {
    return Object.entries(this.state[teamId].registeredVotes).map(
      ([roleId, roleVotes]) => ({
        voter: roleId,
        decision: String(roleVotes['phase-2']),
        amount: Number(BudgetDecisionValues[roleVotes['phase-2'] as string]),
      })
    );
  }

  teamBudgetAmountResult(teamId: string): number {
    let result: number = 0;
    let countedVotes: number = 0;

    for (const playerVotes of Object.values(this.state[teamId].registeredVotes))
      if (playerVotes['phase-1']) {
        result += BudgetDecisionValues[playerVotes['phase-1'] as string];
        countedVotes++;
      }

    result = result / countedVotes;

    return result;
  }

  teamDirectDistributionDecision(teamId: string): string {
    return (
      this.state[teamId].registeredVotes.DS?.[
        'phase-3-distribution'
      ] as VoteRecord
    )?.vote;
  }

  teamDirectDistributionMotivation(teamId: string): string {
    return (
      this.state[teamId].registeredVotes.DS?.[
        'phase-3-distribution'
      ] as VoteRecord
    )?.motivation;
  }

  teamDesertTenderCostsDecision(teamId: string): string {
    return (
      this.state[teamId].registeredVotes.DS?.['phase-3-tender'] as VoteRecord
    )?.vote as string;
  }

  teamDesertTenderCostsMotivation(teamId: string): string {
    return (
      this.state[teamId].registeredVotes.DS?.['phase-3-tender'] as VoteRecord
    )?.motivation;
  }

  teamDiagnosisDecision(teamId: string): string {
    return (
      this.state[teamId].registeredVotes.DS?.['phase-3-diagnosis'] as VoteRecord
    )?.vote;
  }

  teamDiagnosisMotivation(teamId: string): string {
    return (
      this.state[teamId].registeredVotes.DS?.['phase-3-diagnosis'] as VoteRecord
    )?.motivation;
  }

  teamFeedback(teamId: string): string {
    const score = [
      this.teamDirectDistributionDecision(teamId) === 'IncreaseDPCUtilization'
        ? 0
        : 1,
      this.teamDesertTenderCostsDecision(teamId) ===
      'ImproveSuppliersCompetition'
        ? 0
        : 1,
      this.teamDiagnosisDecision(teamId) === 'ImproveWelfareContinuity' ? 0 : 1,
    ].reduce((sum: number, current: number) => sum + current, 0);

    if (score >= 2) return 'MeMyselfAndI';
    else return 'YouAreOpen';
  }

  teamSatisfaction(teamId: string): number {
    const votes = [];

    Object.values(this.state[teamId].registeredVotes).forEach(vote =>
      Object.keys(vote).forEach(
        voteId => voteId.includes('satisfaction') && votes.push(vote[voteId])
      )
    );

    return votes.reduce((total, current) => total + current, 0) / votes.length;
  }

  teamRepresentation(teamId: string): number {
    const votes = [];

    Object.values(this.state[teamId].registeredVotes).forEach(vote =>
      Object.keys(vote).forEach(
        voteId => voteId.includes('representation') && votes.push(vote[voteId])
      )
    );

    return votes.reduce((total, current) => total + current, 0) / votes.length;
  }

  // KPIs

  teamUnexpectedEventCostsDelta(teamId: string): number {
    return (
      this.teamUnexpectedEventCostsYear1(teamId) -
      this.unexpectedEventCostsYear0
    );
  }

  teamUnexpectedEventCostsYear1(teamId: string): number {
    return (
      this.teamNumberOfPatientsTreatedWithICSYear1(teamId) *
        this.costPerUnexpectedEventICS +
      this.teamNumberOfPatientsTreatedWithBioYear1(teamId) *
        this.costPerUnexpectedEventBio
    );
  }

  teamNumberOfPatientsTreatedWithICSYear1(teamId: string): number {
    return (
      this.numberOfPatientsTreatedWithICSYear0 +
      this.teamICSTreatmentsVariation(teamId)
    );
  }

  teamNumberOfPatientsTreatedWithBioYear1(teamId: string) {
    return (
      this.teamNumberOfPatientsTreatedWithOMAYear1(teamId) +
      this.teamNumberOfPatientsTreatedWithMEPOYear1(teamId)
    );
  }

  teamNumberOfPatientsTreatedWithOMAYear1(teamId: string): number {
    return (
      this.numberOfPatientsTreatedWithOMAYear0 +
      this.teamOMATreatmentsVariation(teamId)
    );
  }

  teamNumberOfPatientsTreatedWithMEPOYear1(teamId: string): number {
    return (
      this.numberOfPatientsTreatedWithMEPOYear0 +
      this.teamMEPOTreatmentsVariation(teamId)
    );
  }

  teamICSTreatmentsVariation(teamId: string) {
    return this.teamTreatmentsVariation(teamId).ics;
  }

  teamMEPOTreatmentsVariation(teamId: string) {
    return this.teamTreatmentsVariation(teamId).mepo;
  }

  teamOMATreatmentsVariation(teamId: string) {
    return this.teamTreatmentsVariation(teamId).oma;
  }

  teamBudgetAllocationDecision(teamId: string): AllocationDecision {
    const majority = (
      votes: Record<PlayerRole, Record<string, string | number | VoteRecord>>,
      phase: string,
      primaryVoter: string
    ) => {
      const votesCounter: Record<AllocationDecision, number> = {} as any;

      for (const [voter, voterVotes] of Object.entries(votes)) {
        const vote: AllocationDecision = voterVotes[
          phase
        ] as AllocationDecision;

        votesCounter[vote] =
          (votesCounter[vote] || 0) + (voter === primaryVoter ? 2 : 1);
      }

      const mostVoted: AllocationDecision = Object.keys(votesCounter).length
        ? (Object.keys(votesCounter).reduce((maximum, current) =>
            votesCounter[maximum] > votesCounter[current] ? maximum : current
          ) as AllocationDecision)
        : undefined;

      if (mostVoted) return mostVoted;
      else return '--null--';
    };

    return majority(
      this.state[teamId].registeredVotes,
      'phase-2',
      'DS'
    ) as AllocationDecision;
  }

  teamTreatmentsVariation(teamId: string) {
    return toDeltaTreatments(this.teamBudgetAllocationDecision(teamId))(
      this.teamPatientsVariation(teamId)
    );
  }

  teamPatientsVariation(teamId) {
    return {
      changeOMAPatientDelta:
        this.teamBudgetAmountResult(teamId) /
        (this.costPerPatientOMA - this.costPerUnexpectedEventOMA),
      changeICSPatientDelta:
        this.teamBudgetAmountResult(teamId) / this.costPerUnexpectedEventICS,
      changeMEPOPatientDelta:
        this.teamBudgetAmountResult(teamId) /
        (this.costPerPatientMEPO - this.costPerUnexpectedEventMEPO),
    };
  }

  goalsByRole(): Record<PlayerRole, PlayerGoal[]> {
    return {
      DS: [
        'ReduceCostOfAsmaDrugs',
        'KeepAGoodOrganizationClimate',
        'ReduceAdmissionsDueToUnexpectedEvents',
      ],
      DF: [
        'ReduceCostOfAsmaDrugs',
        'ImproveWarehouseEfficacy',
        'ImproveCompliance',
      ],
      DM: [
        'ReduceCostOfAsmaDrugs',
        'ReduceAdmissionsDueToUnexpectedEvents',
        'ImprovePatientsWelfare',
      ],
      DP: [
        'IncreasePercentageOfPatientsTreatedWithBio',
        'ReduceAdmissionsDueToUnexpectedEvents',
        'ImproveCompliance',
      ],
      PE: [
        'ReduceCostOfAsmaDrugs',
        'ImproveTenderSpecificationWriting',
        'ReduceAdmissionsDueToUnexpectedEvents',
      ],
      CA: [
        'ImprovePeopleAwareness',
        'IncreaseNumberOfPediatricPatientsTreatedWithBio',
        'ImproveCompliance',
      ],
    };
  }

  // TEAM GOALS

  public static teamsGoals(): string[] {
    return [
      'ReduceTotalDrugsCosts',
      'ReduceUnexpectedEventsRelatedCosts',
      'IncreaseTotalCompliance',
    ];
  }

  hasTeamAchievedReduceTotalDrugsCostsGoal(teamId: string): boolean {
    return this.isReduceTotalDrugsCostsTeamGoalAchieved(
      this.teamBudgetAmountResult(teamId)
    );
  }

  isReduceTotalDrugsCostsTeamGoalAchieved(budgetAmountResult: number): boolean {
    return budgetAmountResult < 0;
  }

  hasTeamAchievedReduceAdmissionsDueToUnexpectedEventsGoal(
    teamId: string
  ): boolean {
    return this.isReduceAdmissionsDueToUnexpectedEventsTeamGoalAchieved(
      this.teamUnexpectedEventCostsDelta(teamId)
    );
  }

  hasTeamAchievedReduceUnexpectedEventsRelatedCostsGoal(teamId: string) {
    return this.isReduceUnexpectedEventsRelatedCostsTeamGoalAchieved(
      this.teamUnexpectedEventCostsDelta(teamId)
    );
  }

  hasTeamAchievedIncreaseTotalComplianceGoal(teamId: string): boolean {
    return this.isIncreaseTotalComplianceTeamGoalAchieved(
      this.teamComplianceDelta(teamId)
    );
  }

  isReduceAdmissionsDueToUnexpectedEventsTeamGoalAchieved(
    unexpectedEventCostsDelta: number
  ): boolean {
    return unexpectedEventCostsDelta < 0;
  }

  isReduceUnexpectedEventsRelatedCostsTeamGoalAchieved(
    unexpectedEventCostsDelta: number
  ): boolean {
    return unexpectedEventCostsDelta < 0;
  }

  isIncreaseTotalComplianceTeamGoalAchieved(complianceDelta: number): boolean {
    return complianceDelta > 0;
  }

  // PERSONAL GOALS

  isReduceCostOfAsmaDrugsPersonalGoalAchieved(
    budgetAmountResult: number
  ): boolean {
    return budgetAmountResult < 0;
  }

  isKeepAGoodOrganizationClimatePersonalGoalAchieved(
    teamRepresentation: number,
    teamSatisfaction: number
  ): boolean {
    return (teamRepresentation + teamSatisfaction) / 2 >= 0.6;
  }

  isReduceAdmissionsDueToUnexpectedEventsPersonalGoalAchieved(
    unexpectedEventCostsDelta: number
  ): boolean {
    return unexpectedEventCostsDelta < 0;
  }

  isImproveWarehouseEfficacyPersonalGoalAchieved(
    directDistributionDecision: string
  ): boolean {
    return directDistributionDecision === 'ImproveWarehouseManagement';
  }

  isImproveCompliancePersonalGoalAchieved(complianceDelta: number): boolean {
    return complianceDelta > 0;
  }

  isImproveTenderSpecificationWritingPersonalGoalAchieved(
    desertTenderCostsDecision: string
  ): boolean {
    return desertTenderCostsDecision === 'ImproveSuppliersCompetition';
  }

  isImprovePatientsWelfarePersonalGoalAchieved(
    diagnosisDecision: string
  ): boolean {
    return diagnosisDecision === 'ImproveWelfareContinuity';
  }

  isImprovePeopleAwarenessPersonalGoalAchieved(
    diagnosisDecision: string
  ): boolean {
    return diagnosisDecision === 'InvestOnPeopleSensitization';
  }

  isIncreaseNumberOfPediatricPatientsTreatedWithBioPersonalGoalAchieved(
    numberOfPediatricPatientsTreatedWithBioDelta: number
  ): boolean {
    return numberOfPediatricPatientsTreatedWithBioDelta > 0;
  }

  isIncreasePercentageOfPatientsTreatedWithBioPersonalGoalAchieved(
    percentageOfPatientsTreatedWithBioDelta: number
  ): boolean {
    return percentageOfPatientsTreatedWithBioDelta > 0;
  }

  teamTotalOMACostYear1(teamId: string) {
    return (
      this.teamNumberOfPatientsTreatedWithOMAYear1(teamId) *
      this.costPerPatientOMA
    );
  }

  teamTotalMEPOCostYear1(teamId: string) {
    return (
      this.teamNumberOfPatientsTreatedWithMEPOYear1(teamId) *
      this.costPerPatientMEPO
    );
  }

  teamTotalDrugsCostsYear1(teamId: string) {
    return this.totalDrugsCostsYear0 + this.teamBudgetAmountResult(teamId);
  }

  teamComplianceYear1(teamId: string) {
    return (
      this.teamPercentageOfPatientsTreatedWithICSYear1(teamId) *
        this.teamNonBioComplianceYear1(teamId) +
      this.teamPercentageOfPatientsTreatedWithBioYear1(teamId) *
        this.teamBioComplianceYear1(teamId)
    );
  }

  teamPercentageOfPatientsTreatedWithBioYear1(teamId: string) {
    return 1 - this.teamPercentageOfPatientsTreatedWithICSYear1(teamId);
  }

  teamPercentageOfPatientsTreatedWithICSYear1(teamId: string) {
    return (
      this.teamNumberOfPatientsTreatedWithICSYear1(teamId) /
      this.teamNumberOfPatientsTreatedYear1(teamId)
    );
  }

  teamPercentageOfAdultPatientsTreatedWithOMAYear1(teamId: string) {
    if (this.teamBudgetAllocationDecision(teamId) === 'MoveBudgetFromOMAtoMEPO')
      return (
        this.teamNumberOfAdultPatientsTreatedWithOMAYear1(teamId) /
        this.teamNumberOfPatientsTreatedWithOMAYear1(teamId)
      );

    return (
      1 -
      this.teamPercentageOfPediatricPatientsTreatedWithOMAYear1(teamId) -
      this.teamPercentageOfAllergicPatientsTreatedWithOMAYear1(teamId)
    );
  }

  teamNumberOfAdultPatientsTreatedWithOMAYear1(teamId: string) {
    if (this.teamBudgetAllocationDecision(teamId) === 'MoveBudgetFromOMAtoMEPO')
      return 0;

    if (this.teamBudgetAllocationDecision(teamId) === 'ChangeMEPOBudget')
      return this.numberOfAdultPatientsTreatedWithOMAYear0;

    return (
      this.teamPercentageOfAdultPatientsTreatedWithOMAYear1(teamId) *
      this.teamNumberOfPatientsTreatedWithOMAYear1(teamId)
    );
  }

  teamAdultPatientsTreatedWithOMAComplianceYear1(teamId: string) {
    return this.adultPatientsTreatedWithOMAComplianceYear0;
  }

  teamNumberOfPatientsTreatedYear1(teamId: string) {
    return (
      this.teamNumberOfPatientsTreatedWithICSYear1(teamId) +
      this.teamNumberOfPatientsTreatedWithBioYear1(teamId)
    );
  }

  teamPercentageOfPediatricPatientsTreatedWithOMAYear1(teamId: string) {
    if (this.teamBudgetAllocationDecision(teamId) === 'MoveBudgetFromOMAtoMEPO')
      return (
        this.teamNumberOfPediatricPatientsTreatedWithOMAYear1(teamId) /
        this.teamNumberOfPatientsTreatedWithOMAYear1(teamId)
      );

    return (
      398 /
      this.teamNumberOfPatientsTreatedYear1(teamId) /
      this.teamPercentageOfPatientsTreatedWithOMAYear1(teamId)
    );
  }

  teamPediatricPatientsTreatedWithOMAComplianceYear1(teamId: string) {
    return this.pediatricPatientsTreatedWithOMAComplianceYear0;
  }

  teamPercentageOfAllergicPatientsTreatedWithOMAYear1(teamId: string) {
    if (this.teamBudgetAllocationDecision(teamId) === 'MoveBudgetFromOMAtoMEPO')
      return (
        this.teamNumberOfAllergicPatientsTreatedWithOMAComplianceYear1(teamId) /
        this.teamNumberOfPatientsTreatedWithOMAYear1(teamId)
      );

    return (
      this.numberOfAllergicPatientsTreatedYear1 /
      this.teamNumberOfPatientsTreatedYear1(teamId) /
      this.teamPercentageOfPatientsTreatedWithOMAYear1(teamId)
    );
  }

  teamNumberOfAllergicPatientsTreatedWithOMAComplianceYear1(teamId: string) {
    return this.allergicPatientsTreatedWithOMAComplianceYear0;
  }

  teamOMAComplianceYear1(teamId: string) {
    return (
      this.teamPercentageOfAdultPatientsTreatedWithOMAYear1(teamId) *
        this.teamAdultPatientsTreatedWithOMAComplianceYear1(teamId) +
      this.teamPercentageOfPediatricPatientsTreatedWithOMAYear1(teamId) *
        this.teamPediatricPatientsTreatedWithOMAComplianceYear1(teamId) +
      this.teamPercentageOfAllergicPatientsTreatedWithOMAYear1(teamId) *
        this.teamNumberOfAllergicPatientsTreatedWithOMAComplianceYear1(teamId)
    );
  }

  teamMEPOComplianceYear1(teamId: string) {
    return 0.26;
  }

  teamBioComplianceYear1(teamId: string) {
    return (
      this.teamPercentageOfPatientsTreatedWithOMAYear1(teamId) *
        this.teamOMAComplianceYear1(teamId) +
      this.teamPercentageOfPatientsTreatedWithMEPOYear1(teamId) *
        this.teamMEPOComplianceYear1(teamId)
    );
  }

  teamNonBioComplianceYear1(teamId: string) {
    return 0.138;
  }

  teamPercentageOfPatientsTreatedWithOMAYear1(teamId: string) {
    return (
      this.teamNumberOfPatientsTreatedWithOMAYear1(teamId) /
      this.teamNumberOfPatientsTreatedWithBioYear1(teamId)
    );
  }

  teamPercentageOfPatientsTreatedWithMEPOYear1(teamId: string) {
    return 1 - this.teamPercentageOfPatientsTreatedWithOMAYear1(teamId);
  }
}
