import React from 'react';
import it from '../dictionaries/it';

interface IReportContext {
  scenarios: any;
  dictionary: any;
}
const defaultReportContext = {
  scenarios: {},
  dictionary: it
};

const ReportContext = React.createContext<IReportContext>(defaultReportContext);

export default ReportContext;
