export const WHITE = 'white';
export const DARK = '#161616';
export const BLUE = '#2854C7';
export const DARK_BLUE = '#0046ad';
export const CALCITE = '#F6F6F6';
export const DARK_CALCITE = '#979797';
export const TITLE = BLUE
export const ORANGE = '#EC7763'
export const GREEN_BLUE = '#30A2AD'
export const PURPLE = '#8370EB'
export const GREEN = '#258640';
export const RED = '#B53721';
export const CADET_BLUE = '#30A3AE';
