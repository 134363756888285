import DayJS from 'dayjs';
import LOCATIONS from '../data/locations.data';
import { remote } from '../remote';
import { Session } from '../remote/interface';
import OriginalGamePhaseId from '../types/OriginalGamePhaseId.type';
import PlayerRole from '../types/PlayerRole.type';

export default class SessionsManagementService {
  public static pageSize: number = 4;

  public static async fetchAllSessions() {
    return (await remote.getAllSessions()) as Session[];
  }

  public static paginateSessions(sessions: Session[], page: number) {
    return sessions.slice(page * this.pageSize, (page + 1) * this.pageSize);
  }

  public static splitSessionsByDate(sessions: Session[]) {
    return {
      future: sessions.filter(({ date }) =>
        DayJS((date as any).seconds * 1_000, 'X')
          .add(1, 'day')
          .startOf('day')
          .isAfter(DayJS(new Date()).startOf('day').toDate())
      ),
      past: sessions.filter(({ date }) =>
        DayJS((date as any).seconds * 1_000, 'X')
          .startOf('day')
          .isBefore(DayJS(new Date()).startOf('day').toDate())
      ),
    };
  }

  public static async getDisplayedSessions({
    futurePage,
    pastPage,
    searchTerm,
  }: {
    futurePage: number;
    pastPage: number;
    searchTerm?: string;
  }) {
    const allSessions = await this.fetchAllSessions();

    const filteredSessions = searchTerm
      ? allSessions.filter(({ name }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : allSessions;

    const { future, past } = this.splitSessionsByDate(filteredSessions);

    return {
      future: this.paginateSessions(future, futurePage),
      past: this.paginateSessions(past, pastPage),
      futureSessionsCount: future.length,
      pastSessionsCount: past.length,
    };
  }

  public static async createSession(name: string, date: Date) {
    await remote.createSession(
      name
        .replace(/\./g, '_')
        .replace(/:/g, '_')
        .replace(/\s/g, '_')
        .toUpperCase(),
      name,
      date
    );

    typeof window !== 'undefined' && window.location.reload();
  }

  public static async deleteSession(id: string) {
    return await remote.deleteSession(id);
  }

  public static async addGameToSession(gameId: string, sessionId: string) {
    return await remote.createGame(gameId, sessionId);
  }

  public static async watchGamesBySessionId(sessionId: string, callback) {
    remote.watchGamesBySessionId(sessionId, callback);
  }

  public static async removeGameFromSession(gameId: string, sessionId: string) {
    return await remote.removeGame(gameId, sessionId);
  }

  public static isGameNameUnique(gameName: string, gameNames: string[]) {
    return (
      gameName &&
      !gameNames.some(
        name =>
          name.toUpperCase().replace(' ', '_') ===
          gameName.toUpperCase().replace(' ', '_')
      )
    );
  }

  public static async addPlayerToGame(
    shortRole: PlayerRole,
    sessionId: string,
    gameId: string
  ) {
    return await remote.addPlayerToGame(shortRole, sessionId, gameId);
  }

  public static async removePlayerFromGame(
    shortRole: string,
    sessionId: string,
    gameId: string
  ) {
    return await remote.removePlayerFromGame(shortRole, sessionId, gameId);
  }

  public static getPlayerGameLink(
    shortRole: string,
    sessionId: string,
    gameId: string
  ) {
    return `${process.env.GAME_URL}/init?demoId=${sessionId}::${gameId}:${shortRole}.T1`;
  }

  public static async startAllGamesInSession(sessionId: string) {
    return await remote.startAllGamesInSession(sessionId);
  }

  public static async setMeetingRoomInSession(
    sessionId: string,
    role: string,
    room: string
  ) {
    return await remote.setMeetingRoomInSession(sessionId, role, room);
  }

  public static async openChatForAllGamesInSession(sessionId: string) {
    return await remote.toggleChatForAllGamesInSession(sessionId, true);
  }

  public static async closeChatForAllGamesInSession(sessionId: string) {
    return await remote.toggleChatForAllGamesInSession(sessionId, false);
  }

  public static async advanceGamesInSessionFromPhase(
    sessionId: string,
    fromPhase: keyof typeof LOCATIONS
  ) {
    const phaseNameMappings = {
      landing: 'landing', // TODO: ??
      intro: 'intro',
      phase1: 'phase-1',
      phase2: 'phase-2',
      phase3: 'phase-3',
      endgame: 'conclusions',
    };

    const toPhase: keyof typeof LOCATIONS = Object.keys(LOCATIONS)[
      Object.keys(LOCATIONS).indexOf(fromPhase) + 1
    ] as keyof typeof LOCATIONS;

    return await remote.advanceGamesInSessionToPhase(
      sessionId,
      phaseNameMappings[toPhase] as OriginalGamePhaseId
    );
  }

  public static async openVotingForGamesInSession(
    sessionId: string,
    phase: keyof typeof LOCATIONS
  ) {
    const votingPhasesMappings = {
      landing: (() => new Error('Cannot vote here!'))(),
      intro: (() => new Error('Cannot vote here!'))(),
      phase1: 'phase-1-voting',
      phase2: 'phase-2-voting',
      phase3: 'phase-3-voting',
      endgame: (() => new Error('Cannot vote here!'))(),
    };

    return await remote.advanceGamesInSessionToPhase(
      sessionId,
      votingPhasesMappings[phase] as OriginalGamePhaseId
    );
  }

  public static async startMeetingForGamesInSession(
    sessionId: string,
    phase: keyof typeof LOCATIONS
  ) {
    const meetingPhasesMappings = {
      landing: (() => new Error('Cannot meet here!'))(),
      intro: (() => new Error('Cannot meet here!'))(),
      phase1: 'first-meeting',
      phase2: 'second-meeting',
      phase3: (() => new Error('Cannot meet here!'))(),
      endgame: (() => new Error('Cannot meet here!'))(),
    };

    await this.closeChatForAllGamesInSession(sessionId);

    return await remote.startMeetingForGamesInSession(
      sessionId,
      meetingPhasesMappings[phase] as 'first-meeting' | 'second-meeting'
    );
  }
}
