import React, { FunctionComponent, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';

const CaretIcon = ({ direction }: { direction: 'up' | 'down' }) => {
  const { formatMessage } = useIntl();

  return (
    <span className="w-4 h-4">
      <img
        src={formatMessage({ id: 'teacher:icons.arrow-bottom' })}
        style={{
          transform: `rotateX(${180 * (direction === 'up' ? 1 : 0)}deg)`,
        }}
        alt="Caret"
      />
    </span>
  );
};

type Props = {
  data: any[];
  columns: any[];
};

const DebriefingBaseTable: FunctionComponent<Props> = ({ columns, data }) => {
  const _columns = useMemo(() => columns, [columns]);
  const _data = useMemo(() => data, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        _columns,
        data,
        _data,
      },
      useSortBy
    );

  return (
    <motion.table
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{
        width: '100%',
        borderRadius: '8px',
        tableLayout: 'auto',
        borderSpacing: '0 4px',
        borderCollapse: 'separate',
      }}
      {...getTableProps()}
    >
      <thead
        style={{
          marginBottom: '8px',
          borderRadius: '8px',
        }}
      >
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                style={column.style}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <div
                  className="flex p-2 leading-tight select-none text-slate-600"
                  style={{
                    fontWeight: 200,
                  }}
                >
                  {column.render('Header')}

                  <span
                    style={{
                      display: 'inline-block',
                      margin: 'auto 2px auto 0',
                      opacity: 0.5,
                    }}
                  >
                    {column.isSorted ? (
                      <span
                        style={{
                          display: 'inline-block',
                          width: '24px',
                          userSelect: 'none',
                        }}
                      >
                        {column.isSortedDesc ? (
                          <CaretIcon direction="up" />
                        ) : (
                          <CaretIcon direction="down" />
                        )}
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);

          return (
            <tr
              style={{
                background: index % 2 ? '#fafaff' : 'white',
              }}
              {...row.getRowProps()}
            >
              {row.cells.map((cell, index: number) => (
                <td
                  key={index}
                  className="p-4"
                  style={{
                    height: '80px',
                    borderTop: '2px solid #e2e2e2',
                    borderBottom: '2px solid #e2e2e2',
                    borderRight:
                      index === row.cells.length - 1
                        ? '2px solid #e2e2e2'
                        : 'none',
                    borderLeft: index === 0 ? '2px solid #e2e2e2' : 'none',
                    borderRadius:
                      index === 0
                        ? '8px 0 0 8px'
                        : index === row.cells.length - 1
                        ? '0 8px 8px 0'
                        : '0',
                  }}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </motion.table>
  );
};

export default DebriefingBaseTable;
