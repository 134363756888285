import { useState } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import IconButton from './icon-button';
import NicknameInput from './nickname-input';
import ROLES from '../data/roles.data';
import SessionsManagementService from '../services/SessionsManagementService';
import { remote } from '../remote';
import PlayerRole from '../types/PlayerRole.type';

type TeamMembersListProps = {
  name?: string;
  players?: {
    token: string;
    role: `${PlayerRole}.T1`;
    nickname: string;
  }[];
  sessionId: string;
};

const TeamMembersList = ({
  players,
  name,
  sessionId,
}: TeamMembersListProps) => {
  const { formatMessage: f } = useIntl();

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div
      className={clsx(
        'select-none w-5/6 rounded-lg border-2 flex flex-col p-1',
        expanded ? 'border-hospitaliaTeal-base shadow-lg' : 'border-gray-500'
      )}
    >
      <div
        onClick={() => setExpanded(!expanded)}
        className="flex flex-row px-5 py-1 cursor-pointer"
      >
        <p className="flex-grow font-bold text-gray-600">{name}</p>
        <img
          src={f({ id: 'teacher:icons.arrow-bottom' })}
          alt="Espandi"
          className="flex-none w-4 h-4 m-auto"
        />
      </div>

      {expanded && (
        <ul className="p-2">
          {Object.entries(ROLES)?.map(
            ([shortRole, longRole]: [PlayerRole, string], index) => {
              const player = players?.find(
                player => player?.role.split('.')[0] === shortRole
              );

              return (
                <li
                  key={index}
                  className="mb-2 rounded-sm p-3 flex items-center w-full h-[50px]"
                >
                  <div
                    className={clsx(
                      'flex flex-none items-center justify-center w-10 h-10 mr-4 font-bold text-center text-white rounded-full cursor-default select-none',
                      player ? 'bg-hospitaliaTeal-base' : 'bg-stone-200'
                    )}
                  >
                    {f({
                      id: `profiles:${longRole}.shortname`,
                    })}
                  </div>
                  <p
                    className={clsx(
                      'mr-4 leading-none',
                      player ? 'text-slate-600' : 'text-stone-400'
                    )}
                  >
                    {f({
                      id: `profiles:${longRole}.title`,
                    })}
                  </p>
                  {!player && (
                    <IconButton
                      label="Attiva giocatore"
                      image={f({ id: 'icon:add-player' })}
                      color="#07D440"
                      onClick={() => {
                        SessionsManagementService.addPlayerToGame(
                          shortRole,
                          sessionId,
                          name
                        );
                      }}
                    />
                  )}
                  {player && (
                    <>
                      <IconButton
                        label={f({ id: 'teacher:session.url.copy' })}
                        image={f({ id: 'teacher:icons.copy-link' })}
                        color="#3764C3"
                        onClick={async () => {
                          navigator.clipboard.writeText(
                            await SessionsManagementService.getPlayerGameLink(
                              shortRole,
                              sessionId,
                              encodeURIComponent(name)
                            )
                          );
                        }}
                      />
                      <IconButton
                        label="Disattiva giocatore"
                        image={f({ id: 'teacher:icons.delete' })}
                        color="FireBrick"
                        onClick={() => {
                          SessionsManagementService.removePlayerFromGame(
                            shortRole,
                            sessionId,
                            name
                          );
                        }}
                      />
                    </>
                  )}
                  <div className="flex-grow ml-2">
                    {player && (
                      <NicknameInput
                        player={player}
                        updateNickname={async (roleId, nickname) => {
                          await remote.setNickname(
                            `${sessionId}::${name}`,
                            roleId,
                            nickname
                          );
                        }}
                      />
                    )}
                  </div>
                </li>
              );
            }
          )}
        </ul>
      )}
    </div>
  );
};

export default TeamMembersList;
