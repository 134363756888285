import React, { useContext } from 'react';
import { Document, Text } from '@react-pdf/renderer';
import { IKpi, IPlayer, IVote } from '../interfaces';
import ErrorBoundary from './blocks/ErrorBoundary';
import Html from './blocks/Html';
import Chat from './pages/Chat';
import Cover from './pages/Cover';
import ExtraordinaryEventTable from './pages/ExtraordinaryEventTable';
import ExtraordinaryEventText from './pages/ExtraordinaryEventText';
import Last from './pages/Last';
import NextYearChoices from './pages/NextYearChoices';
import OneImageWithText from './pages/OneImageWithText';
import TeamChoicesAnswer from './pages/TeamChoicesAnswer';
import TeamChoicesValue from './pages/TeamChoicesValue';
import TeamResults from './pages/TeamResults';
import YourTeam from './pages/YourTeam';
import ReportContext from './ReportContext';

type Props = {
  teamName: string;
  date: number;
  players: IPlayer[];
  kpis: IKpi[];
  feedbackID: string;
  budgetAmountVotes: IVote[];
  budgetAmount: string;
  budgetAllocationVotes: IVote[];
  budgetAllocation: string;
  chatLink: string;
  nextYearData: string[][];
  extraordinaryEventData: string[][];
};

const Report: React.FC<Props> = props => {
  const {
    teamName,
    date,
    players,
    kpis,
    feedbackID,
    budgetAmountVotes,
    budgetAmount,
    budgetAllocationVotes,
    budgetAllocation,
    chatLink,
    nextYearData,
    extraordinaryEventData,
  } = props;

  const { dictionary } = useContext(ReportContext);

  const teamGoals = ['obiettivo1', 'obiettivo2', 'obiettivo3'];

  const roles = {
    DS: 'DirettoreSanitario',
    DF: 'DirettoreFarmaciaOspedaliera',
    DM: 'DirettoreDipartimentoMedicinaInterna',
    DP: 'DirettoreUOCPneumologia',
    PA: 'ProvveditoreEconomo',
    CA: 'ClinicoReferenteFederAsma',
  };

  var feedbackText = dictionary[`feedback${feedbackID}`]
    ? dictionary[`feedback${feedbackID}`]
    : '';

  return (
    <ErrorBoundary>
      {/* @ts-ignore */}
      <Document>
        <Cover teamName={teamName} date={date} />
        <YourTeam players={players} roles={roles} />
        <TeamResults kpis={kpis} />
        <OneImageWithText image={feedbackID}>
          <Text
            style={{
              fontSize: 22,
              letterSpacing: 0,
              lineHeight: '28px',
              alignSelf: 'center',
            }}
          >
            <Html>{feedbackText}</Html>
          </Text>
        </OneImageWithText>
        <TeamChoicesValue
          players={players}
          votes={budgetAmountVotes}
          budgetAmount={budgetAmount}
        />
        <TeamChoicesAnswer
          players={players}
          votes={budgetAllocationVotes}
          budgetAllocation={budgetAllocation}
        />
        <NextYearChoices nextYearData={nextYearData} />
        <ExtraordinaryEventText teamGoals={teamGoals} />
        <ExtraordinaryEventTable
          extraordinaryEventData={extraordinaryEventData}
        />
        <Chat chatLink={chatLink} />
        <Last />
      </Document>
    </ErrorBoundary>
  );
};

export default Report;
