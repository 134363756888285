import { Link, Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Header from '../blocks/Header';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import { GREEN_BLUE, WHITE } from '../colors';
import ReportContext from '../ReportContext';


const Chat = ({ chatLink }) => {
  const { dictionary } = useContext(ReportContext);


  return (
    <Page style={{
      position: 'relative', fontFamily: 'RubikRegular',
    }}>
      <Header />
      <Image name={'Chat'} style={{
        position: 'absolute',
        top: '140px',
        left: '152px',
        height: 430,
        width: 811,
      }} />
      <View style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}>
        <View style={{
          textAlign: 'center'
        }}>
          <Text style={{
            fontSize: 26,
            fontFamily: 'RubikBold',
            letterSpacing: 0,
            lineHeight: '31px',
            color: GREEN_BLUE,
          }}>
            {dictionary['ChatTitle']}
          </Text>
          <Text style={{
            fontSize: 18,
            letterSpacing: 0,
            lineHeight: '22px',
            color: GREEN_BLUE

          }}>
            {dictionary['ChatSubtitle']}
          </Text>
          <View style={{
            position: 'relative',
            width: '251px',
            top: '179px',
            left: '420px'
          }}>
            <Image name="chatBox" style={{
              height: "77px",
              width: "251px",
              position: 'absolute',
              marginTop: '23px'
            }} />
            <Link style={{
              marginTop: '45px'
            }} src={chatLink}>
              <Text style={{
                fontSize: 26,
                fontFamily: 'RubikBold',
                letterSpacing: 0,
                lineHeight: '31px',
                color: WHITE
              }}>{dictionary['ChatShow']}</Text>
            </Link>

          </View>
        </View>
      </View>
    </Page>
  );
};

export default Chat;
