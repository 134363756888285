import create, { GetState, SetState } from 'zustand';
import { remote } from '../../../remote';
import {
  AvatarRecord,
  GameRecord,
  VoteRecord,
} from '../../../remote/interface';
import DebriefingService from '../../../services/DebriefingService';
import { log } from '../../../utils/log';
import { StoreSlice } from '../../utils/store-slice';

export const createDebriefingSlice: StoreSlice<{
  viewModel: DebriefingService;

  initialize: (sessionId: string) => Promise<void>;
}> = (set, get) => {
  return {
    viewModel: undefined,

    async initialize(sessionId) {
      log('Debriefing store slice', 'Initializing debriefing...');

      const sessionGames = await remote.getGamesBySessionId(sessionId);
      const sessionVotes = await remote.getVotesBySessionId(sessionId);

      const gamesIds: string[] = sessionGames.map(({ id }) => id);
      const avatarsByGameRecordId: Record<string, AvatarRecord[]> = {};
      for (const gameId of gamesIds) {
        const avatars = await remote.getAvatars(`${sessionId}::${gameId}`);
        avatarsByGameRecordId[gameId] = avatars;
      }

      const states: Record<
        string,
        { game: GameRecord; votes: VoteRecord[]; avatars: AvatarRecord[] }
      > = gamesIds.reduce(
        (result, currentGameId) => ({
          ...result,
          [currentGameId]: {
            game: sessionGames.find(({ id }) => id === currentGameId),
            votes: sessionVotes.filter(
              ({ gameId }) => gameId === currentGameId
            ),
            avatars: avatarsByGameRecordId[currentGameId],
          },
        }),
        {}
      );

      set({
        viewModel: DebriefingService.fromJSON(sessionId, states),
      });
    },
  };
};

export const useDebriefing = create(
  (set: SetState<any>, get: GetState<any>) => ({
    ...createDebriefingSlice(set, get),
  })
);
