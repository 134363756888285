import clsx from 'clsx';

const Button = (props: {
  onClick(): void;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
}) => (
  <button
    className={clsx(
      "bg-[#3764c3] text-white rounded-3xl text-sm px-4 py-3 font-['Open_Sans'] font-bold",
      props.className,
      props.disabled ? 'opacity-50 cursor-default' : 'cursor-pointer'
    )}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </button>
);

export default Button;
