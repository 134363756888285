import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRoute } from 'wouter';
import SessionsManagementService from '../services/SessionsManagementService';
import Button from './button';

interface IProps {
  teams: string[];
  isDisabled: boolean;
}

const AddTeam = ({ teams, isDisabled }: IProps) => {
  const { formatMessage: f } = useIntl();

  const [teamName, setTeamName] = useState<string>();

  const [, parameters] = useRoute<{ sessionId: string }>(
    '/sessions/:sessionId/mission-control/setup'
  );


  const canAddTeam = () =>
    // Only allow letters, numbers and underscores.
    /^[a-zA-Z0-9_]*$/.test(teamName) &&
    SessionsManagementService.isGameNameUnique(teamName, teams);

  const addTeam = async (teamName: string) => {
    if (!parameters) return;

    await SessionsManagementService.addGameToSession(
      teamName,
      parameters.sessionId
    );
  };

  return (
    <div className="flex items-start mt-6 space-x-4">
      <div className="w-4/6">
        <label className="block mx-2 mt-2 text-xs uppercase text-hospitaliaBlue-base/70">
          {f({ id: 'teacher:session.team-name' })}
        </label>
        <input
          className="w-full p-3 text-sm border-2 rounded-md peer border-slate-400"
          type="text"
          name="team-name"
          disabled={isDisabled}
          value={teamName || ''}
          onChange={e => setTeamName(e.target.value)}
          placeholder={
            isDisabled
              ? 'Partita iniziata. Teams congelati.'
              : f({ id: 'teacher:session.team-name' })
          }
        />
        <p className="transition-all duration-500 ease-in-out select-none text-xxs text-slate-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:mt-1">
          I nomi dei teams possono contenere solo lettere, numeri e _.
        </p>
      </div>
      <Button
        className="w-2/6 mt-6"
        disabled={isDisabled || !teamName || !canAddTeam()}
        onClick={() => {
          if (!teamName) return;

          addTeam(teamName);
          setTeamName(undefined);
        }}
      >
        {f({ id: 'teacher:session.team-add' })}
      </Button>
    </div>
  );
};

export default AddTeam;
