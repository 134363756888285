const DownloadButton = ({
  handleClick = () => {},
  disabled = false,
  children,
}) => (
  <button
    disabled={disabled}
    className="px-8 py-2 text-white transition-all duration-500 ease-in-out bg-blue-500 rounded-full active:bg-blue-600 active:duration-75 hover:bg-blue-400 hover:duration-100 disabled:bg-slate-200"
    onClick={handleClick}
  >
    <div className="flex flex-row items-center justify-between">{children}</div>
  </button>
);

export default DownloadButton;
