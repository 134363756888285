"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _height = _interopRequireDefault(require("../run/height"));

var _descent = _interopRequireDefault(require("../run/descent"));

var _advanceWidth = _interopRequireDefault(require("../run/advanceWidth"));

var _ascent = _interopRequireDefault(require("../attributedString/ascent"));

var DEST_REGEXP = /^#.+/;

var isSrcId = function isSrcId(src) {
  return src.match(DEST_REGEXP);
};

var renderAttachments = function renderAttachments(ctx, run) {
  ctx.save();
  var font = run.attributes.font;
  var space = font.glyphForCodePoint(0x20);
  var objectReplacement = font.glyphForCodePoint(0xfffc);
  var attachmentAdvance = 0;

  for (var i = 0; i < run.glyphs.length; i++) {
    var position = run.positions[i];
    var glyph = run.glyphs[i];
    attachmentAdvance += position.xAdvance || 0;

    if (glyph.id === objectReplacement.id && run.attributes.attachment) {
      ctx.translate(attachmentAdvance, position.yOffset || 0);
      renderAttachment(ctx, run.attributes.attachment);
      run.glyphs[i] = space;
      attachmentAdvance = 0;
    }
  }

  ctx.restore();
};

var renderAttachment = function renderAttachment(ctx, attachment) {
  var _attachment$xOffset = attachment.xOffset,
      xOffset = _attachment$xOffset === void 0 ? 0 : _attachment$xOffset,
      _attachment$yOffset = attachment.yOffset,
      yOffset = _attachment$yOffset === void 0 ? 0 : _attachment$yOffset,
      width = attachment.width,
      height = attachment.height,
      image = attachment.image;
  ctx.translate(-width + xOffset, -height + yOffset);
  ctx.image(image, 0, 0, {
    fit: [width, height],
    align: 'center',
    valign: 'bottom'
  });
};

var renderRun = function renderRun(ctx, run, options) {
  var _run$attributes = run.attributes,
      font = _run$attributes.font,
      fontSize = _run$attributes.fontSize,
      color = _run$attributes.color,
      link = _run$attributes.link,
      opacity = _run$attributes.opacity;
  var height = (0, _height["default"])(run);
  var descent = (0, _descent["default"])(run);
  var runAdvanceWidth = (0, _advanceWidth["default"])(run);

  if (options.outlineRuns) {
    ctx.rect(0, -height, runAdvanceWidth, height).stroke();
  }

  ctx.fillColor(color);
  ctx.fillOpacity(opacity);

  if (link) {
    if (isSrcId(link)) {
      ctx.goTo(0, -height - descent, runAdvanceWidth, height, link.slice(1));
    } else {
      ctx.link(0, -height - descent, runAdvanceWidth, height, link);
    }
  }

  renderAttachments(ctx, run);

  if (font.sbix || font.COLR && font.CPAL) {
    ctx.save();
    ctx.translate(0, -run.ascent);

    for (var i = 0; i < run.glyphs.length; i++) {
      var position = run.positions[i];
      var glyph = run.glyphs[i];
      ctx.save();
      ctx.translate(position.xOffset, position.yOffset);
      glyph.render(ctx, fontSize);
      ctx.restore();
      ctx.translate(position.xAdvance, position.yAdvance);
    }

    ctx.restore();
  } else {
    ctx.font(typeof font.name === 'string' ? font.name : font, fontSize);

    try {
      ctx._addGlyphs(run.glyphs, run.positions, 0, 0);
    } catch (error) {
      console.log(error);
    }
  }

  ctx.translate(runAdvanceWidth, 0);
};

var renderBackground = function renderBackground(ctx, rect, backgroundColor) {
  ctx.rect(rect.x, rect.y, rect.width, rect.height);
  ctx.fill(backgroundColor);
};

var renderDecorationLine = function renderDecorationLine(ctx, line) {
  ctx.save();
  ctx.lineWidth(line.rect.height);
  ctx.strokeOpacity(line.opacity);

  if (/dashed/.test(line.style)) {
    ctx.dash(3 * line.rect.height);
  } else if (/dotted/.test(line.style)) {
    ctx.dash(line.rect.height);
  }

  if (/wavy/.test(line.style)) {
    var dist = Math.max(2, line.rect.height);
    var step = 1.1 * dist;
    var stepCount = Math.floor(line.rect.width / (2 * step)); // Adjust step to fill entire width

    var remainingWidth = line.rect.width - stepCount * 2 * step;
    var adjustment = remainingWidth / stepCount / 2;
    step += adjustment;
    var cp1y = line.rect.y + dist;
    var cp2y = line.rect.y - dist;
    var x = line.rect.x;
    ctx.moveTo(line.rect.x, line.rect.y);

    for (var i = 0; i < stepCount; i++) {
      ctx.bezierCurveTo(x + step, cp1y, x + step, cp2y, x + 2 * step, line.rect.y);
      x += 2 * step;
    }
  } else {
    ctx.moveTo(line.rect.x, line.rect.y);
    ctx.lineTo(line.rect.x + line.rect.width, line.rect.y);

    if (/double/.test(line.style)) {
      ctx.moveTo(line.rect.x, line.rect.y + line.rect.height * 2);
      ctx.lineTo(line.rect.x + line.rect.width, line.rect.y + line.rect.height * 2);
    }
  }

  ctx.stroke(line.color);
  ctx.restore();
};

var renderLine = function renderLine(ctx, line, options) {
  var lineAscent = (0, _ascent["default"])(line);

  if (options.outlineLines) {
    ctx.rect(line.box.x, line.box.y, line.box.width, line.box.height).stroke();
  }

  ctx.save();
  ctx.translate(line.box.x, line.box.y + lineAscent);

  for (var _iterator = line.runs, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var run = _ref;

    if (run.attributes.backgroundColor) {
      var backgroundRect = {
        x: 0,
        y: -lineAscent,
        height: line.box.height,
        width: (0, _advanceWidth["default"])(run) - line.overflowRight
      };
      renderBackground(ctx, backgroundRect, run.attributes.backgroundColor);
    }

    renderRun(ctx, run, options);
  }

  ctx.restore();
  ctx.save();
  ctx.translate(line.box.x, line.box.y);

  for (var _iterator2 = line.decorationLines, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
    var _ref2;

    if (_isArray2) {
      if (_i2 >= _iterator2.length) break;
      _ref2 = _iterator2[_i2++];
    } else {
      _i2 = _iterator2.next();
      if (_i2.done) break;
      _ref2 = _i2.value;
    }

    var decorationLine = _ref2;
    renderDecorationLine(ctx, decorationLine);
  }

  ctx.restore();
};

var renderBlock = function renderBlock(ctx, block, options) {
  for (var _iterator3 = block, _isArray3 = Array.isArray(_iterator3), _i3 = 0, _iterator3 = _isArray3 ? _iterator3 : _iterator3[Symbol.iterator]();;) {
    var _ref3;

    if (_isArray3) {
      if (_i3 >= _iterator3.length) break;
      _ref3 = _iterator3[_i3++];
    } else {
      _i3 = _iterator3.next();
      if (_i3.done) break;
      _ref3 = _i3.value;
    }

    var line = _ref3;
    renderLine(ctx, line, options);
  }
};

var render = function render(ctx, blocks, options) {
  if (options === void 0) {
    options = {};
  }

  for (var _iterator4 = blocks, _isArray4 = Array.isArray(_iterator4), _i4 = 0, _iterator4 = _isArray4 ? _iterator4 : _iterator4[Symbol.iterator]();;) {
    var _ref4;

    if (_isArray4) {
      if (_i4 >= _iterator4.length) break;
      _ref4 = _iterator4[_i4++];
    } else {
      _i4 = _iterator4.next();
      if (_i4.done) break;
      _ref4 = _i4.value;
    }

    var block = _ref4;
    renderBlock(ctx, block, options);
  }
};

var _default = {
  render: render
};
exports["default"] = _default;