import { Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import { IPlayer, IVote } from '../../interfaces';
import ReportContext from '../ReportContext';
import Image from './Image';
import TableRowStyle from './styles/TableRowStyle';
import getRowAdditionalStylesFromPosition from './utils/getRowAdditionalStylesFromPosition';
import getRowBackgroundColorFromPosition from './utils/getRowBackgroundColorFromPosition';

type Props = {
  players: IPlayer[];
  votes: IVote[];
  width?: number;
  tableID: string;
};

const PlayersVotesTable: React.FC<Props> = ({
  players,
  votes,
  width,
  tableID,
}) => {
  const { dictionary } = useContext(ReportContext);

  return (
    <View
      style={{
        width: width ? width : '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {votes.map((vote, index) => {
        const player = players.find(player => player.role === vote.role);
        const avatar = player ? player.avatar : 'avatar-0';
        const RowAdditinalStyles = getRowAdditionalStylesFromPosition(
          index,
          votes.length
        );

        return (
          <View
            key={index}
            style={{
              backgroundColor: getRowBackgroundColorFromPosition(index),
              ...TableRowStyle,
              ...RowAdditinalStyles,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Image
                  style={{
                    width: '31px',
                    height: '31px',
                  }}
                  name={`${avatar}`}
                />
                <View
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: 200,
                  }}
                >
                  <Text>{dictionary[`${vote.role}Name`]}</Text>
                  <Text
                    style={{
                      fontSize: 10,
                    }}
                  >
                    {dictionary[vote.role]}
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  fontSize: tableID === 'TeamChoicesAnswer' ? 10 : 16,
                  textAlign: 'left',
                  maxWidth: 340,
                }}
              >
                {dictionary[vote.value]}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default PlayersVotesTable;
