import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import { useDebriefing } from '../../stores/game/factories/debriefing';
import ReportingService from '../../services/ReportingService';
import { IReportData } from '../../pdf-report/interfaces';
import TeamReportLink from './TeamReportLink';
import DownloadButton from './DownloadReportButton';

const ReportsSection = () => {
  const { formatMessage: f } = useIntl();

  const viewModel = useDebriefing(({ viewModel }) => viewModel);

  const [reportingService, setReportingService] = useState<ReportingService>();
  const [reports, setReports] = useState<
    { teamId: string; payload: IReportData }[]
  >([]);

  const [reportToDownload, prepareReport] = useState<IReportData>();

  useEffect(() => {
    if (!viewModel) return;
    setReportingService(new ReportingService({ debriefing: viewModel }));
  }, [viewModel]);

  useEffect(() => {
    if (!reportingService || !viewModel) return;

    setReports(
      viewModel.teams.map(teamId => ({
        teamId,
        payload: reportingService.preparePayload(teamId),
      }))
    );
  }, [viewModel, reportingService]);

  if (!viewModel) return <p>Loading...</p>;

  return (
    <div>
      <h2 className="mb-10 text-xl font-bold uppercase text-slate-700">
        Reports della sessione
      </h2>

      <div className="flex flex-col w-full mt-16 space-y-4">
        {reports.map(({ teamId, payload }, index) => (
          <motion.div
            key={teamId}
            className="flex flex-row items-center justify-between w-1/2 px-8 py-2 mb-4 rounded-xl bg-gradient-to-r from-blue-200 to-white group"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.1 * index,
              duration: 0.8,
              ease: 'easeInOut',
            }}
          >
            <p style={{ width: '60%' }}>Team "{teamId}"</p>
            {reportToDownload &&
            reportToDownload.teamName === payload.teamName ? (
              <TeamReportLink data={reportToDownload} />
            ) : (
              <DownloadButton handleClick={() => prepareReport(payload)}>
                <>
                  Prepara
                  <img
                    src={f({ id: 'teacher:icons.download' })}
                    width="14"
                    height="14"
                    alt="Prepara report"
                    style={{
                      marginLeft: '10px',
                    }}
                  />
                </>
              </DownloadButton>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ReportsSection;
