import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useDebouncedCallback from '../hooks/useDebouncedCallback.hook';

const NICKNAME_UPDATE_DEBOUNCE_TIMER: number = 800;

type Props = {
  player: { token: string; role: string; nickname?: string };
  updateNickname: (roleId: string, nickname: string) => void;
};

const NicknameInput = (props: Props) => {
  const { formatMessage: f } = useIntl();

  const [nickname, setNickname] = useState<string>(props.player.nickname || '');

  const debouncedNicknameUpdate = useDebouncedCallback(
    newvalue => props.updateNickname(props.player.role, newvalue),
    NICKNAME_UPDATE_DEBOUNCE_TIMER,
    [props.player.role, nickname]
  );

  useEffect(
    () => setNickname(props.player.nickname || ''),
    [props.player?.nickname]
  );

  useEffect(() => {
    if (nickname !== props.player.nickname) debouncedNicknameUpdate(nickname);
  }, [nickname]);

  return (
    <div
      className="flex flex-row w-full pl-8 flex-start"
      style={{
        borderLeft: '1px solid gainsboro',
      }}
    >
      <input
        className="flex-grow px-4 py-1 m-auto border-2 rounded outline-none focus:border-blue-200 border-slate-200 text-slate-600"
        type="text"
        placeholder="Nickname"
        onChange={e => setNickname(e?.target?.value)}
        value={nickname}
      />
      <span className="relative flex flex-row w-10 ml-4">
        {props.player?.nickname !== nickname ? (
          <span className="relative block w-6 h-6 m-auto bg-blue-200 rounded-full animate-bounce" />
        ) : (
          <span style={{ width: '48px', height: '25px', display: 'block' }} />
        )}
      </span>
    </div>
  );
};

export default NicknameInput;
