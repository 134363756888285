import clsx from 'clsx';

type IconButtonProps = {
  color: string;
  label: string;
  image: string;
  size?: 'small' | 'medium';
  onClick: () => void | Promise<void>;
};

const IconButton = ({
  color,
  label,
  image,
  size = 'medium',
  onClick,
}: IconButtonProps) => (
  <div className="relative">
    <button
      title={label}
      onClick={onClick}
      className={clsx(
        'relative peer flex items-center justify-center flex-none overflow-hidden bg-white rounded-full cursor-pointer select-none group',
        size === 'medium' ? 'w-10 h-10 p-2' : 'w-8 h-8 p-[6px]'
      )}
    >
      <img
        src={image}
        alt={label}
        className="transition-all duration-500 ease-in-out group-active:scale-90 group-active:duration-75"
      />
      <img
        src={image}
        alt={label}
        className="absolute transition-all duration-500 ease-in-out opacity-0 blur-lg group-hover:duration-75 group-hover:opacity-100"
      />
    </button>

    <span
      className="absolute left-0 right-0 w-24 px-2 py-2 mx-auto text-xs font-bold leading-none text-center text-white uppercase transition-all duration-500 ease-in-out scale-50 -translate-x-6 rounded opacity-0 pointer-events-none peer-hover:scale-100 blur-sm peer-hover:blur-none peer-hover:opacity-100 bottom-4 peer-hover:bottom-10"
      style={{ backgroundColor: color }}
    >
      {label}
    </span>
  </div>
);

export default IconButton;
