import { useIntl } from 'react-intl';
import DebriefingBaseTable from '../DebriefingBaseTable';
import StarRatings from 'react-star-ratings';

type Props = {
  entries: {
    teamID: string;
    satisfactionAvg: number;
  }[];
};

const SatisfactionAndRepresentationVotesTable = (props: Props) => {
  const { formatMessage: f } = useIntl();

  const columns = [
    {
      Header: 'Team',
      accessor: 'teamID',
      Cell: props => (
        <div {...props} style={{ minWidth: '400px' }}>
          {props.value}
        </div>
      ),
    },
    {
      Header: 'Grado medio di soddisfazione',
      accessor: 'satisfactionAvg',
      Cell: props => (
        <div
          {...props}
          style={{
            maxWidth: '200px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <StarRatings
            rating={props.value}
            starDimension="20px"
            starSpacing="2px"
            starRatedColor="teal"
            numberOfStars={5}
            name="rating"
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <DebriefingBaseTable columns={columns} data={props.entries} />
    </div>
  );
};

export default SatisfactionAndRepresentationVotesTable;
