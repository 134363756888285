const LOCATIONS = Object.freeze({
  landing: {
    rules: ['rules'],
  },

  intro: {
    mandate: ['intro/mandate'],
    roles: ['intro/roles'],
    docs: ['intro/docs', 'docs'],
    avatar: ['avatar'],
    profiles: [
      'profiles',
      'profiles/DS',
      'profiles/DM',
      'profiles/DF',
      'profiles/DP',
      'profiles/PE',
      'profiles/CA',
    ],
    teamGoals: ['team-goals'],
    'final-docs': ['intro-final-docs'],
  },

  phase1: {
    intro: ['phases/phase-1/intro'],
    scenario: ['phases/phase-1/scenario'],
    docs: ['docs'],
    // meeting: ['first-leg'],
    vote: ['phases/phase-1/vote'],
    outcome: ['phases/phase-1/outcome'],
    survey: ['phases/phase-1/survey'],
  },

  phase2: {
    intro: ['phases/phase-2/intro'],
    scenario: ['phases/phase-2/scenario'],
    // meeting: ['second-leg'],
    vote: ['phases/phase-2/vote'],
    outcome: ['phases/phase-2/outcome'],
    survey: ['phases/phase-2/survey'],
  },

  phase3: {
    intro: ['phases/phase-3/intro'],
    breakingNews: ['phases/phase-3/breaking-news'],
    scenario: ['phases/phase-3/scenario'],
    docs: ['docs'],
    vote: ['phases/phase-3/vote'], // TODO: Maybe handle people who have already voted by creating a fake 'waiting' room in teacher's UI.
    outcome: ['phases/phase-3/outcome'],
    survey: ['phases/phase-3/survey'],
  },

  endgame: {
    feedback: ['feedback'],
    achievements: ['achievements'],
    thanks: ['thanks'],
  },
});

export default LOCATIONS;
