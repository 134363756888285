import { useIntl } from 'react-intl';
import ROLES from '../data/roles.data';
import { GameRecord } from '../remote/interface';
import SessionsManagementService from '../services/SessionsManagementService';
import IconButton from './icon-button';
import TeamMembersList from './team-members-list';

interface IProps {
  teams: GameRecord[];
  sessionId: string;
}

const TeamsDetailsList = ({ teams, sessionId }: IProps) => {
  const { formatMessage: f } = useIntl();

  const copyGameLinksToClipboard = async (id: string, players) => {
    navigator.clipboard.writeText(
      Object.keys(ROLES).reduce((acc, role) => {
        const player = players?.find(player => player?.split('.')[0] === role);
        if (!player) return acc;
        return acc
          .concat(
            SessionsManagementService.getPlayerGameLink(
              role,
              sessionId,
              encodeURIComponent(id)
            )
          )
          .concat(',\n');
      }, '')
    );
  };

  return (
    <div className="flex flex-col space-y-2">
      {teams?.map(team => (
        <div key={team.id} className="flex flex-row items-start gap-8">
          <TeamMembersList
            players={team.players.map(player => ({
              token: player,
              role: player,
              nickname: team.nicknames?.[player],
            }))}
            name={team.id}
            sessionId={sessionId}
          />

          <div className="flex justify-center m-[2px]">
            <IconButton
              label={f({ id: 'teacher:session.url.copy-all' })}
              image={f({ id: 'teacher:icons.copy-link' })}
              color="#3764C3"
              onClick={() => copyGameLinksToClipboard(team.id, team.players)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamsDetailsList;
