import { useState } from 'react';
import { Link, Route, useLocation, useRoute } from 'wouter';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

const NavigationBar = () => {
  const { formatMessage: f } = useIntl();
  const [location] = useLocation();

  const [, params] = useRoute<{ sessionId: string }>(
    '/sessions/:sessionId/:rest*'
  );
  const sessionId: string = params?.sessionId;

  return (
    <div
      className="bg-slate-200 select-none font-OpenSans text-slate-400 pt-6 lg:pt-8 xl:pt-10 2xl:pt-12 w-[100px] lg:w-[120px] xl:w-[160px] 2xl:w-[185px] flex flex-col z-[5]"
      style={{ boxShadow: '8px 0px 25px -12px rgba(0, 0, 0, 0.25)' }}
    >
      <h2
        className="pl-5 font-light uppercase"
        style={{
          fontSize: '0.6rem',
          marginBottom: '14px',
        }}
      >
        Menu
      </h2>

      <Link className="contents text-inherit" to="/sessions">
        <div
          className="flex bg-hospitaliaGold-dark h-16 lg:h-20 xl:h-24 2xl:h-[100px] text-white font-bold p-3 xl:p-4 2xl:p-5 mb-[2px] items-center cursor-pointer hover:bg-hospitaliaGold-light text-xs xl:text-sm 2xl:text-base"
          style={{
            transition: 'all 0.2s linear',
          }}
        >
          <img
            alt={f({ id: 'teacher:icons.sessions' })}
            className="mr-2 xl:mr-3 2xl:mr-[14px]"
            src={f({ id: 'teacher:icons.sessions' })}
          />
          <span>{f({ id: 'teacher:sessions' })}</span>
        </div>
      </Link>

      <Route path="/sessions/:sessionId/:rest*">
        <div>
          <div className="text-xxs xl:text-xs flex bg-hospitaliaGold-dark text-white font-bold items-center h-[42px] p-3 xl:p-4 2xl:p-5 mb-[2px]">
            <p>{sessionId}</p>
          </div>

          <SideBarButton
            color="#3764c3"
            path={`/sessions/${sessionId}/mission-control/setup`}
            icon={f({
              id: 'teacher:icons.mission-control',
            })}
            activeIcon={f({
              id: 'teacher:icons.mission-control-white',
            })}
            label={f({ id: 'teacher:session.mission-control' })}
            isActive={location.includes('mission-control')}
          />

          <SideBarButton
            color="#3764c3"
            path={`/sessions/${sessionId}/mission-control/setup`}
            label={f({ id: 'teacher:session.mission-control.setup' })}
            isActive={
              location === `/sessions/${sessionId}/mission-control/setup`
            }
          />

          <SideBarButton
            color="#3764c3"
            path={`/sessions/${sessionId}/mission-control/live`}
            label={f({ id: 'teacher:session.mission-control.live' })}
            isActive={
              location === `/sessions/${sessionId}/mission-control/live`
            }
          />

          <SideBarButton
            color=" #8a37c3"
            path={`/sessions/${sessionId}/debriefing/teams-goals`}
            icon={f({
              id: 'teacher:icons.debrief',
            })}
            activeIcon={f({
              id: 'teacher:icons.debrief-white',
            })}
            label={f({ id: 'teacher:session.debriefing' })}
            isActive={location.includes(`/sessions/${sessionId}/debriefing`)}
          />
        </div>
      </Route>

      <div className="flex-grow" />

      <div
        className="grid p-1 space-x-[8px] xl:space-x-[10px] 2xl:space-x-[13px] xl:p-2 2xl:p-3 font-['Open_Sans']"
        style={{
          gridTemplateColumns: '40px 70px',
        }}
      >
        <img
          className="m-auto"
          src={f({ id: 'teacher:icons.teaching-notes' })}
          alt={f({ id: 'teacher:teaching-notes' })}
        />
        <span className="mx-0 my-auto leading-none text-xxs xl:leading-none xl:text-xs 2xl:text-sm 2xl:leading-tight text-slate-600">
          {f({ id: 'teacher:teaching-notes' })}
        </span>
      </div>

      <div
        className="grid p-1 space-x-[8px] xl:space-x-[10px] 2xl:space-x-[13px] xl:p-2 2xl:p-3 font-['Open_Sans'] mb-12"
        style={{
          gridTemplateColumns: '40px 70px',
        }}
      >
        <img
          className="m-auto"
          src={f({ id: 'teacher:icons.help' })}
          alt={f({ id: 'teacher:help' })}
        />
        <span className="mx-0 my-auto leading-none text-xxs xl:leading-none xl:text-xs 2xl:text-sm 2xl:leading-tight text-slate-600">
          {f({ id: 'teacher:help' })}
        </span>
      </div>
    </div>
  );
};

export default NavigationBar;

type SideBarButtonProps = {
  color: string;
  path: string;
  label: string;
  icon?: string;
  activeIcon?: string;
  isActive?: boolean;
  iconStyle?: object;
};

const SideBarButton = (props: SideBarButtonProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Link
      style={{
        display: 'contents',
        textDecoration: 'none',
        color: 'inherit',
      }}
      to={props.path}
    >
      <div
        className={clsx(
          'mb-[2px] gap-3 transition-all duration-500 hover:duration-100 ease-in-out p-3 xl:p-4 2xl:p-[18px] font-semibold h-10 xl:h-12 2xl:h-14 flex items-center cursor-pointer'
        )}
        style={{
          gridTemplateColumns: '20px 70px',
          color: hovered || props.isActive ? '#ffffff' : props.color,
          backgroundColor:
            hovered || props.isActive ? props.color : 'transparent',
        }}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {props.icon && props.activeIcon ? (
          <img
            alt={props.label}
            src={hovered || props.isActive ? props.activeIcon : props.icon}
            style={props.iconStyle}
          />
        ) : (
          <span className="w-6" />
        )}
        <p className="max-w-full leading-none text-xxs xl:leading-none 2xl:leading-none xl:text-xs 2xl:text-sm">
          {props.label}
        </p>
      </div>
    </Link>
  );
};
