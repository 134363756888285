import { useState } from 'react';
import { motion } from 'framer-motion';
import DatePicker from 'react-datepicker';
import SessionsManagementService from '../../services/SessionsManagementService';
import Button from './Button';
import 'react-datepicker/dist/react-datepicker.css';

const SessionCreationModal = ({ setIsCreationModalOpen }) => {
  const [sessionData, setSessionData] = useState({
    name: '',
    date: new Date(),
  });

  const createSession = async () => {
    await SessionsManagementService.createSession(
      sessionData.name,
      sessionData.date
    );

    setIsCreationModalOpen(false);
  };

  return (
    <motion.div
      initial={{ top: -200, opacity: 0, filter: 'blur(16px)' }}
      animate={{ top: 0, opacity: 1, filter: 'blur(0px)' }}
      className="relative w-full p-10 border-2 rounded-lg shadow-xl border-slate-200 bg-white/10 backdrop-blur-md"
    >
      <p className="mx-auto font-bold text-center uppercase text-blue-900/80">
        Crea una nuova sessione
      </p>

      <div className="flex flex-row justify-center mt-10 space-x-8">
        <div className="flex flex-col space-y-1">
          <label className="text-xs font-semibold text-blue-900/70">
            Nome della sessione
          </label>
          <input
            type="text"
            className="p-2 bg-white border-2 rounded border-slate-200"
            onChange={({ target: { value } }) =>
              setSessionData(_ => ({ ..._, name: value }))
            }
          />
        </div>
        <div className="flex flex-col space-y-1">
          <label className="text-xs font-semibold text-blue-900/70">
            Data della sessione
          </label>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            className="p-2 bg-white border-2 rounded border-slate-200"
            selected={sessionData.date}
            onChange={date =>
              setSessionData(_ => ({
                ..._,
                date,
              }))
            }
          />
        </div>
      </div>

      <div className="flex flex-row justify-center mt-20 space-x-10">
        <Button
          label="Annulla"
          handleClick={() => setIsCreationModalOpen(false)}
        />
        <Button label="Crea" handleClick={createSession} />
      </div>
    </motion.div>
  );
};

export default SessionCreationModal;
