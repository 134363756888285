import { useEffect } from 'react';
import { useRoute } from 'wouter';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import PhasesOverview from './PhasesOverview';
import { useMonitoring } from '../../stores/game/factories/monitoring';
import SessionsManagementService from '../../services/SessionsManagementService';
import Spinner from '../Spinner';

const LiveScreen = () => {
  const { formatMessage } = useIntl();
  const { initialize: initializeMonitoring, viewModel } = useMonitoring(
    ({ initialize, viewModel }) => ({ initialize, viewModel })
  );

  const [, parameters] = useRoute<{ sessionId: string }>(
    '/sessions/:sessionId/mission-control/live'
  );

  useEffect(() => {
    if (!parameters?.sessionId) return;

    async function init() {
      initializeMonitoring(parameters.sessionId);
    }

    init();
  }, [parameters?.sessionId]);

  if (!parameters?.sessionId) return <Spinner size="256px" />;

  return (
    <div className="flex flex-col gap-4 select-none">
      <div className="flex flex-row justify-between gap-2">
        <div
          className="w-5/12 p-8 bg-white rounded-lg shadow-xl shadow-black/5"
          id="session-startup"
        >
          <h2 className="mb-10 font-bold uppercase text-slate-700">
            {formatMessage({ id: 'teacher:sections.startup' })}
          </h2>

          <div className="flex">
            <motion.button
              whileHover={{
                scaleX: [1.02, 1],
                scaleY: [0.98, 1],
              }}
              whileTap={{
                scaleX: [0.98, 1],
                scaleY: [1.02, 1],
              }}
              animate={{
                scaleX: 1,
                scaleY: 1,
              }}
              transition={{
                bounce: 0.8,
                stiffness: 0.4,
                duration: 0.2,
                type: 'spring',
              }}
              className="leading-none xl:px-6 px-4 2xl:px-8 py-3 xl:py-4 font-bold text-white uppercase bg-blue-600 disabled:bg-slate-200 disabled:cursor-not-allowed rounded-full saturate-[.7]"
              disabled={viewModel?.games?.some(
                ({ isStarted }) => isStarted === true
              )}
              onClick={() =>
                SessionsManagementService.startAllGamesInSession(
                  parameters.sessionId
                )
              }
            >
              {formatMessage({ id: 'teacher:session.start' })}
            </motion.button>
          </div>
        </div>

        <div
          className="w-7/12 p-8 bg-white rounded-lg shadow-xl shadow-black/5"
          id="chat"
        >
          <h2 className="text-lg font-bold uppercase text-slate-600">
            {formatMessage({ id: 'teacher:sections.chat' })}
          </h2>

          <div className="flex mt-10 space-x-8">
            <motion.button
              whileHover={{
                scaleX: [1.02, 1],
                scaleY: [0.98, 1],
              }}
              whileTap={{
                scaleX: [0.98, 1],
                scaleY: [1.02, 1],
              }}
              animate={{
                scaleX: 1,
                scaleY: 1,
              }}
              transition={{
                bounce: 0.8,
                stiffness: 0.4,
                duration: 0.2,
                type: 'spring',
              }}
              className="leading-none xl:px-6 px-4 2xl:px-8 py-3 xl:py-4 font-bold text-white uppercase bg-green-500 disabled:bg-slate-200 disabled:cursor-not-allowed rounded-full saturate-[.7]"
              disabled={
                viewModel?.games
                  ?.filter(Boolean)
                  .every(({ isChatOpen }) => isChatOpen === true) ||
                viewModel?.games
                  ?.filter(Boolean)
                  .some(({ isStarted }) => isStarted === false)
              }
              onClick={() =>
                SessionsManagementService.openChatForAllGamesInSession(
                  parameters.sessionId
                )
              }
            >
              {formatMessage({ id: 'teacher:session.chat.open' })}
            </motion.button>

            <motion.button
              whileHover={{
                scaleX: [1, 1.02, 1],
                scaleY: [1, 0.98, 1],
              }}
              whileTap={{
                scaleX: [1, 0.98, 1],
                scaleY: [1, 1.02, 1],
              }}
              animate={{
                scaleX: 1,
                scaleY: 1,
              }}
              transition={{
                duration: 0.2,
              }}
              className="leading-none xl:px-6 px-4 2xl:px-8 py-3 xl:py-4 font-bold text-white uppercase bg-red-400 disabled:bg-slate-200 disabled:cursor-not-allowed rounded-full saturate-[.9]"
              disabled={
                viewModel?.games
                  ?.filter(Boolean)
                  .every(({ isChatOpen }) => isChatOpen === false) ||
                viewModel?.games
                  ?.filter(Boolean)
                  .some(({ isStarted }) => isStarted === false)
              }
              onClick={() =>
                SessionsManagementService.closeChatForAllGamesInSession(
                  parameters.sessionId
                )
              }
            >
              {formatMessage({ id: 'teacher:session.chat.close' })}
            </motion.button>
          </div>
        </div>
      </div>

      <div className="w-full p-8 mt-8 bg-white rounded-lg shadow-xl shadow-black/5">
        <h2 className="text-lg font-bold uppercase text-slate-600">
          {formatMessage({ id: 'teacher:sections.live' })}
        </h2>

        <PhasesOverview />
      </div>
    </div>
  );
};

export default LiveScreen;
