import { Text } from '@react-pdf/renderer';
import HtmlToReact, { Parser } from 'html-to-react';
import React from 'react';

const parser = new Parser();
const isValidNode = function () {
  return true;
};

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const processingInstructions = [
  {
    shouldProcessNode: function (node: any) {
      return node.type === 'tag' && node.name === 'b';
    },
    processNode: function (node: any, children: any, index: number) {
      return (
        <Text style={{ fontFamily: 'RubikBold' }} key={index}>
          {children}
        </Text>
      );
    },
  },
  {
    shouldProcessNode: function (node: any) {
      return node.type === 'tag' && node.name === 'i';
    },
    processNode: function (node: any, children: any, index: number) {
      return (
        <Text style={{ fontFamily: 'RubikRegular' }} key={index}>
          {children}
        </Text>
      );
    },
  },

  {
    shouldProcessNode: function (node: any) {
      return node.type === 'tag' && node.name === 'bi';
    },
    processNode: function (node: any, children: any, index: number) {
      return (
        <Text style={{ fontFamily: 'RubikRegular' }} key={index}>
          {children}
        </Text>
      );
    },
  },
  {
    shouldProcessNode: function (node: any) {
      return node.type === 'tag' && node.name === 'br';
    },
    processNode: function (node: any, children: any, index: number) {
      return (
        <Text style={{ fontFamily: 'RubikRegular' }} key={index}>
          {` ${children}`}
        </Text>
      );
    },
  },

  {
    // Anything else
    shouldProcessNode: function (node: any) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

interface Props {
  children: string;
  style?: object;
  id?: string;
  type?: string;
}

const Html: React.FC<Props> = ({
  children,
  style = {},
  id = '',
  type = '',
}) => {
  var textInsideBoldTag =
    children.startsWith('<b>') && children.endsWith('</b>');

  var updatedText = children;
  if (type === 'statement') {
    updatedText = updatedText.replace('<i>', '');
    updatedText = updatedText.replace('</i>', '');
  }

  const element = parser.parseWithInstructions(
    updatedText,
    isValidNode,
    processingInstructions
  );

  return <Text style={style}>{element}</Text>;
};

export default Html;
