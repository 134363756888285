import { Fragment, useEffect } from 'react';
import { useMonitoring } from '../../stores/game/factories/monitoring';
import PlayerPill from './PlayerPill';
import { PILLS_GAP, PILL_SIZE } from '../../constants';
import LOCATIONS from '../../data/locations.data';
import PlayerRole from '../../types/PlayerRole.type';

interface IProps {
  name: string;
  locations: string[];
  meetingRoomId?: 'first-meeting' | 'second-meeting';
  id: string;
  isFirstPopulatedRoom: boolean;
  setPlayerDetailsModalContent: (details: Record<string, string>) => void;
  phaseId: keyof typeof LOCATIONS;
}

const RoomBox = ({
  name,
  locations,
  meetingRoomId,
  id,
  isFirstPopulatedRoom,
  setPlayerDetailsModalContent,
  phaseId,
}: IProps) => {
  const viewModel = useMonitoring(({ viewModel }) => viewModel);

  const filteredGames: string[] = viewModel?.games
    ?.filter(Boolean)
    .map(({ id }) => id)
    .filter(Boolean);

  if (!viewModel || !filteredGames.length) return <p>Loading...</p>;

  useEffect(() => {
    // Auto scroll into view when first loading.
    if (isFirstPopulatedRoom && typeof document !== 'undefined')
      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
  }, [isFirstPopulatedRoom]);

  return (
    <div
      className="flex flex-col h-full mr-4 space-y-1 overflow-visible select-none last-of-type:mr-0"
      id={id}
    >
      <p className="mx-auto text-xs text-center uppercase -top-8 text-slate-600">
        {name}
      </p>
      <div
        className="grid items-center px-4 py-2 border-2 rounded border-slate-200"
        style={{
          gridTemplateRows: `repeat(${filteredGames.length}, ${PILL_SIZE}px)`,
          rowGap: '4px',
          gridTemplateColumns: `repeat(6, ${PILL_SIZE + PILLS_GAP}px)`,
          height: `${
            filteredGames?.length * (PILL_SIZE + PILLS_GAP) + PILL_SIZE / 1.5
          }px`,
        }}
      >
        {filteredGames.map(gameId => (
          <Fragment key={gameId}>
            {Object.keys(viewModel.roles).map((roleId: PlayerRole) => (
              <PlayerPill
                key={roleId}
                roleId={roleId}
                isPlayerInLocation={
                  meetingRoomId
                    ? viewModel.playerIsInMeeting(gameId, roleId, meetingRoomId)
                    : viewModel.playerIsInLocation(
                        gameId,
                        roleId,
                        locations,
                        phaseId
                      )
                }
                pillSize={PILL_SIZE}
                gameId={gameId}
                locations={locations}
                handleClick={setPlayerDetailsModalContent}
              />
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default RoomBox;
