import { Link, Text, View } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import Image from '../blocks/Image';
import Page from '../blocks/Page';
import { BLUE, WHITE } from '../colors';
import ReportContext from '../ReportContext';


const Last = () => {
  const { dictionary } = useContext(ReportContext);


  return (
    <Page style={{ position: 'relative', fontFamily: 'MontserratRegular' }}>
      <View style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: BLUE
      }}>
        <Image name="logo_hospitalia" style={{
          height: "69px",
          width: "263px",
          position: 'absolute',
          top: '162px',
          left: '34px'
        }} />
        <Image name="logoAreaLast" style={{
          height: "54px",
          width: "237px",
          position: 'absolute',
          bottom: '35px',
          left: '36px'
        }} />
        <Image name="Background_Cover" style={{
          width: "901px",
          position: "absolute",
          right: '0px',
          bottom: '0px'
        }} />
        <View style={{
          top: '269px',
          left: '61px',
          position: 'absolute',
          fontSize: 13,
          letterSpacing: 0,
          lineHeight: '17px',
          color: WHITE
        }}>
          <View style={{
            flexDirection: 'row',
            marginBottom: '16px'
          }}>
            <Text style={{
              fontFamily: 'MontserratBold'
            }}>{dictionary['FacultySign']}{" "}</Text>
            <View>
              <Text>{dictionary['FacultyNames1']}</Text>
              <Text>{dictionary['FacultyNames2']}</Text>
            </View>
          </View>
          <Text style={{
            fontFamily: 'MontserratBold'
          }}>{dictionary['ReportBySign']}</Text>
          <Text>{dictionary['LearningLabSign']}</Text>
          <Text style={{
            marginBottom: '16px'
          }}>{dictionary['TeachingSign']}</Text>
          <Link src='http://learninglab.sdabocconi.it/'>
            <Text>{dictionary['LearningLabLinkSign']}</Text>
          </Link>
          <Link src="mailto:learninglab@sdabocconi.it">
            <Text>{dictionary['LearningLabEmailSign']}</Text>
          </Link>
        </View>

      </View>
    </Page>
  );
};

export default Last;
