import clsx from 'clsx';
import SessionRow from './SessionRow';

const SessionsPage = ({ sessions, pageType, page, pages, setPage }) => (
  <div className="select-none">
    <div className="flex flex-row mb-8 space-x-4 text-slate-500">
      <p className="text-lg font-bold uppercase">{pageType}</p>
      <span className="flex-grow block my-auto border-t-2 border-slate-500" />
    </div>

    <div
      className="grid w-full px-4 font-light uppercase text-slate-600"
      style={{
        gridTemplateColumns: '100px 1fr 1fr 240px',
      }}
    >
      <span /> <p>Nome</p> <p>Data</p> <p>Azioni</p>
    </div>

    <ul className="space-y-4">
      {sessions.map(session => (
        <SessionRow key={session.id} {...session}>
          {session.id}
        </SessionRow>
      ))}
    </ul>

    <div className="flex flex-row justify-center w-full mt-8 space-x-4">
      {Array.from({ length: pages }, (_, _page) => (
        <button
          key={_page}
          onClick={() => setPage(_page)}
          className={clsx(
            'w-8 h-8 border-2 rounded-full text-slate-800 shadow-lg shadow-black/5 transition-all duration-500 hover:duration-100 ease-in-out',
            page === _page
              ? 'bg-hospitaliaTeal-light border-teal-700 hover:border-teal-200'
              : 'bg-slate-100 border-slate-300 hover:border-blue-400'
          )}
        >
          {_page + 1}
        </button>
      ))}
    </div>
  </div>
);

export default SessionsPage;
