import React from 'react';
import { Page as PDFPage } from '@react-pdf/renderer';

const dimensions = { height: 612, width: 1088 };

const Page: React.FC<any> = props => {
  return <PDFPage size={dimensions} {...props} />;
};

export default Page;
